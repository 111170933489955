import CIcon from "@coreui/icons-react";
import {
  CCol,
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
  CRow,
  CTextarea,
  CInputCheckbox
} from "@coreui/react";
import { render } from "enzyme";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { checkValue, formatDateForPicker } from "src/utils";
import PersonalPhoto from "./PersonalPhoto";
import PersonalPhotoView from "./PersonalPhotoView";

const PerosnalInfoView = (props) => {
  
  const userRole = useSelector((state) => state.userRole);
  
  const { t } = useTranslation();

  useEffect(() => {
    //  setCurrentPersonalInfo(props.currentPersonalInfo);
    //  setOtherPersonalInfo(props.otherPersonalInfo);
    
    return () => {};
  }, [props.currentPersonalInfo, props.otherPersonalInfo]);

  console.log("render PerosnalInfoView");
 
  const emptyHandler = (event) => {};
  return (
    <>
      <CRow>
        <CCol xs="12" md="12">
          <CRow>
            <CCol xs="12" md="3" className="text-center">
              <PersonalPhotoView
                personalPhoto={props.currentPersonalInfo.personalPhoto}
              />
            </CCol>
            <CCol xs="12" md="9">
              <CFormGroup row>
                <CCol xs="12" md="4">
                <CLabel htmlFor="date-input">
                 {t("Full Name in English")}
                  </CLabel>
                  <CInput
                    disabled
                    id="view-fullName-name"
                    name="text-input"
                    value={checkValue(props.currentPersonalInfo.fullName)}
                    onChange={emptyHandler}
                    placeholder="Full Name"
                  />
                </CCol>
                <CCol xs="12" md="4">
                <CLabel htmlFor="date-input">
                 {t("Summary in English")}
                  </CLabel>
                  <CTextarea
                    disabled
                    name="view-summary"
                    id="textarea-input"
                    rows="6"
                    value={checkValue(props.currentPersonalInfo.summary)}
                    placeholder="Briefly introduce yourself, Who are you? What is your professional background?"
                  />
                </CCol>
                <CCol xs="12" md="4">
                <CLabel htmlFor="date-input">
                {t("City in English")}
                  </CLabel>
                  <CInput
                    disabled
                    name="view-enCity"
                    id="enCity-input"
                    value={checkValue(props.currentPersonalInfo.city)}
                    placeholder={t("City in English")}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row></CFormGroup>
              <CFormGroup row>
                
              </CFormGroup>
            </CCol>
           </CRow>

           <CRow>
           <CCol xs="12" md="3">
              <CLabel htmlFor="email-input">{t("Email Address")}</CLabel>
              <CInput
                disabled
                type="email"
                id="email-addres-view"
                name="email-addres-view"
                placeholder="Enter Email"
                value={ userRole === 'Admin'? checkValue(props.currentPersonalInfo.email) : (props.showContact ? checkValue(props.currentPersonalInfo.email) : "*******")}
                autoComplete="email"
              />
              <CFormText className="help-block"></CFormText>
            </CCol>
           <CCol xs="12" md="9">
         
               <CFormGroup row>
               <CCol xs="12" md="4">
               <CLabel htmlFor="date-input">
                {t("Full Name in Arabic")}
                  </CLabel>
                  <CInput
                    disabled
                    id="text-fullName-input"
                    name="text-input"
                    value={checkValue(props.otherPersonalInfo.fullName)}
                    placeholder="الاسم الكامل*"
                  />
                </CCol>
                <CCol xs="12" md="4">
                <CLabel htmlFor="date-input">
                {t("Summary in Arabic")}
                  </CLabel>
                  <CTextarea
                    disabled
                    name="textarea-input"
                    id="textarea-input"
                    rows="6"
                    value={checkValue(props.otherPersonalInfo.summary)}
                    placeholder="ملخص *"
                  />
                </CCol>
                <CCol xs="12" md="4">
                <CLabel htmlFor="date-input">
                {t("City in Arabic")}
                  </CLabel>
                  <CInput
                    disabled
                    name="view-otherCity"
                    id="otherCity-input"
                    value={checkValue(props.otherPersonalInfo.city)}
                    placeholder="المدينة"
                  />
                </CCol>
               </CFormGroup>
            </CCol>
         
           </CRow>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs="12" md="3">
        <CLabel htmlFor="date-input">{t("Birth Date")}</CLabel>
              <CInput
                disabled
                type="date"
                id="dateOfBirth-view"
                name="dateOfBirth-view"
                placeholder="date"
                value={formatDateForPicker(props.currentPersonalInfo.dateOfBirth)}
              />
              <CFormText></CFormText>
         
           </CCol>
           <CCol xs="12" md="3">
              <CLabel htmlFor="select">{t("Country of residence")}</CLabel>
              <CInput
                disabled
                id="country-view"
                name="country-view"
                placeholder="Text"
                value={
                  checkValue(props.currentPersonalInfo.country) &&
                  props.currentPersonalInfo.country[
                    localStorage.getItem("lang") === "en" ? "nameEn" : "nameAr"
                  ]
                }
              />

              <CFormText></CFormText>
            </CCol>
            <CCol xs="12" md="3">
              <CLabel htmlFor="website-view">{t("Website")}</CLabel>
              <CInput
                disabled
                id="website-view"
                name="website-view"
                placeholder=""
                value={userRole === 'Admin'? checkValue(props.currentPersonalInfo.website) : (props.showContact ? checkValue(props.currentPersonalInfo.website): "*****")}
              />
              <CFormText></CFormText>
            </CCol>
            <CCol xs="12" md="3">
              <CIcon
                name="cib-facebook"
                height="25"
                className="social-media-icon-label"
              />

              <CInput
                disabled
                id="facebook-view"
                name="facebook-view"
                placeholder=""
                value={userRole === 'Admin'? checkValue(props.currentPersonalInfo.facebook): (props.showContact ? checkValue(props.currentPersonalInfo.facebook): "*****")}
              />
              <CFormText></CFormText>
            </CCol>
        </CRow>
      <CFormGroup row>
      <CCol xs="12" md="3">
              <CIcon
                name="cib-twitter"
                height="25"
                className="social-media-icon-label"
              />

              <CInput
                disabled
                id="twitter-view"
                name="twitter-view"
                placeholder=""
                value={userRole === 'Admin'? checkValue(props.currentPersonalInfo.twitter): (props.showContact ? checkValue(props.currentPersonalInfo.twitter): "*****")}
              />
              <CFormText> </CFormText>
            </CCol>
            <CCol xs="12" md="3">
              <CIcon
                name="cib-linkedin"
                height="25"
                className="social-media-icon-label"
              />

              <CInput
                disabled
                id="linkedIn-view"
                name="linkedIn-view"
                placeholder=""
                value={userRole === 'Admin'? checkValue(props.currentPersonalInfo.linkedIn): (props.showContact ? checkValue(props.currentPersonalInfo.linkedIn): "*****")}
                
              />
              <CFormText> </CFormText>
            </CCol>
            <CCol xs="12" md="6">
            <CLabel htmlFor="Mediaappearancepreferences-view">{t("Media appearance preferences")}</CLabel>
                          
             <CFormGroup variant="checkbox" className="checkbox">
                      <CInputCheckbox 
                      disabled
                          id="Mediaappearancepreferences-viewAudio"
                          name="Mediaappearancepreferences-viewAudio"
                          checked={props.currentPersonalInfo.audioMedia ? true : false}
                      />
                      <CLabel variant="checkbox" className="form-check" >{t("Audio media")}</CLabel>
                      <CInputCheckbox 
                      disabled
                          id="Mediaappearancepreferences-viewvisual"
                          name="Mediaappearancepreferences-viewvisual"
                          checked={props.currentPersonalInfo.visualMedia ? true : false}
                         
                      />
                      <CLabel variant="checkbox" className="form-check" >{t("Visual media")}</CLabel>
                      <CInputCheckbox 
                      disabled
                          id="Mediaappearancepreferences-viewwritten"
                          name="Mediaappearancepreferences-viewwritten"
                          checked={props.currentPersonalInfo.writtenMedia ? true : false}
                      />
                      <CLabel variant="checkbox" className="form-check" >{t("Written media")}</CLabel>
                    </CFormGroup>
            </CCol>
      </CFormGroup>
          
    </>
  );
};

export default PerosnalInfoView;
