import CIcon from "@coreui/icons-react";
import {
  CAlert,
  CButton,
  CCol,
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTextarea,
  CSelect,
  CSwitch,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import CountryList from "../../../CountryList/CountryList";
import ProjectsView from "./ProjectsView";

import axios from "../../../../../axios";
import {
  formatDateForPicker,
  validateEmail,
  validateHTTP,
  validatePhoneNumber,
} from "src/utils";
import { cilCalendarCheck, cilDelete, cilPlus } from "@coreui/icons";
import ProjectsViewCompact from "./ProjectsViewCompact";
import { useTranslation } from "react-i18next";

const Projects = (props) => {
  const [modal, setModal] = useState(false);
  const [compactView, setCompactView] = useState(false);

  const [projectList, setProjectList] = useState(
    props.projects ? props.projects : []
  );

  const [name, setName] = useState("");
  const [url, setURL] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentProject, setCurrentProject] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [editProjectId, setEditProjectId] = useState(0);
  // const [phone, setPhone] = useState(currentPersonalInfo.phone);
  // const [title, setTitle] = useState(currentPersonalInfo.currentTitle);
  const [error, setError] = useState(null);

  useEffect(() => {
    setProjectList(props.projects ? props.projects : []);

    if (editProjectId) {
      const project = props.projects.find((item) => item.id === editProjectId);
      console.log("selected project ", project);
      setName(project.name);
      setURL(project.url);
      setDescription(project.description);
      setStartDate(project.startDate);
      setEndDate(project.endDate);
      setCurrentProject(project.endDate ? false : true);
    } else {
      setName("");
      setURL("");
      setDescription("");
      setStartDate(null);
      setEndDate(null);
    }
    return () => {};
  }, [props.projects, editProjectId]);

  const isValidForm = () => {
    return name && 
    name.trim()!=="" && 
    description &&
    description.trim()!=="" && 
     description.length <= 2000;
  };
  // console.log('currentPersonalInfo info',props.currentPersonalInfo);

  const addEditProject = async (allData) => {
    try {
      setError(null);
      console.log(
        "addProject",

        allData
      );
      let res = null;
      if (editProjectId === 0)
        // insert mode
        res = await axios.post(
          "/projects/", //+ currentPersonalInfo.id,
          allData
        );
      // edit mode
      else res = await axios.patch("/projects/" + editProjectId, allData);

      if (res.error) {
        // setStartBuildingCVError(res.error.response.data.error.message);
        console.log(
          "res.error.response.data.error.message",
          res.error.response.data.error.message
        );
        setError(res.error.response.data.error.message);
      } else if (res === undefined) {
        setError("No response from API");
      } else {
        console.log("updated personal info", res.data);

        setModal(false);
        setName("");
        setDescription("");
        setURL("");
        setStartDate(null);
        setEndDate(null);
        setCurrentProject(false);
        setEditProjectId(0);
        setError(null);
        return res.data;
      }
    } catch (error) {
      setError("Custom Error" + error);
    } finally {
      props.refreshCVs();
    }
  };

  const { mutate, status } = useMutation(addEditProject);

  const projectAddEdit = (event) => {
    event.preventDefault();
    try {
      const allData = {
        name,
        url,
        description,
        startDate: startDate ? new Date(startDate) : null,
        endDate: endDate ? new Date(endDate) : null,
        cvId: props.CVId,
      };
      console.log("Project data", allData);
      mutate(allData);
    } catch (err) {
      setError("Unhandled Error" + err);
    }
  };

  const nameHandler = (event) => {
    setName(event.target.value);
  };
  const urlHandler = (event) => {
    setURL(event.target.value);
  };
  const descriptionHandler = (event) => {
    setDescription(event.target.value);
  };
  const startDateHandler = (event) => {
    setStartDate(event.target.value);
  };
  const endDateHandler = (event) => {
    setEndDate(event.target.value);
  };
  const currentProjectHandler = (event) => {
    setCurrentProject(event.target.checked);
    if (event.target.checked) {
      setEndDate("");
    }
  };
  const projectEditHandler = (id) => {
    setEditMode(true);
    setEditProjectId(id);
    setModal(true);
  };
  const compacyViewHandler = (event) => {
    setCompactView(!compactView);
    console.log("compactView", compactView);
  };
  const { t } = useTranslation();
  return (
    <>
      <CRow>
        <CCol xs="12" md="12">
          <div
            className="cv-section-header col-md-12"
            onClick={(event) => {
              if (props.viewOnly) return;

              setName("");
              setDescription("");
              setURL("");
              setStartDate(null);
              setEndDate(null);
              setCurrentProject(false);
              setEditProjectId(0);
              setError(null);
              setModal(true);
              setEditMode(false);
            }}
          >
            <h5>
              {t("Projects and collaborations")} -{" "}
              {!props.viewOnly && (
                <span>
                  {t("Click to add")}{" "}
                  <CIcon content={cilPlus} size="xl"></CIcon>{" "}
                </span>
              )}
            </h5>
          </div>
          <div style={{ textAlign: "end" }}>
            <span style={{ verticalAlign: "top" }}>{t("Compact View")} </span>
            <CSwitch
              color="primary"
              onChange={compacyViewHandler}
              checked={compactView}
            />
          </div>
        </CCol>
      </CRow>
      <CModal show={modal} closeOnBackdrop={false} onClose={setModal}>
        <CModalHeader closeButton className="modal--header-color">
          <CModalTitle>{t("Projects and collaborations")}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CFormGroup row>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t("Project Name")}
                <span className="text-danger"> *</span>
              </CLabel>
              <CInput
                id="project-name-input"
                name="project-name-input"
                placeholder={t(
                  "Provide the title of a project you were/are part of"
                )}
                value={name}
                onChange={nameHandler}
                onFocus={nameHandler}
              />
              <CFormText>
                {" "}
                <span
                  className={
                    name === "" || name.trim() === "" ? "text-danger" : ""
                  }
                >
                  {t("Required")}
                </span>
              </CFormText>
            </CCol>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t("Project URL")}
                <span className="text-danger"> </span>
              </CLabel>
              <CInput
                id="project-url-input"
                name="project-url-input"
                placeholder={t("Project URL")}
                value={url}
                onChange={urlHandler}
                onFocus={urlHandler}
              />
              <CFormText></CFormText>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t("Project Description")}
                <span className="text-danger"> *</span>
              </CLabel>
              <CTextarea
                id="project-description-input"
                name="project-description-input"
                placeholder={t("Briefly describe project topic and objectives")}
                rows="6"
                value={description}
                onChange={descriptionHandler}
                onFocus={descriptionHandler}
              />
              <CFormText>
              <span
                  className={
                   ( description === "" || description.length > 2000|| description.trim() === "") ? "text-danger" : ""
                  }
                >
                 {t("Required")}, {t("Maximum 2000 characters")}
                </span>
               
              </CFormText>
            </CCol>

            <CCol xs="12" md="6">
              <CRow>
                <CCol xs="12" md="6">
                  <CLabel htmlFor="date-input">
                    {t("Start Date")}
                    <span> </span>
                  </CLabel>
                  <CInput
                    type="date"
                    id="startDate-input"
                    name="startDate-input"
                    placeholder="date"
                    value={formatDateForPicker(startDate)}
                    onChange={startDateHandler}
                  />
                  <CFormText>
                    <span>{t("Select from picker")}</span>
                  </CFormText>
                </CCol>
                <CCol xs="12" md="6">
                  {!currentProject && (
                    <>
                      {" "}
                      <CLabel htmlFor="date-input">
                        {t("End Date")} <span> </span>
                      </CLabel>
                      <CInput
                        type="date"
                        id="startDate-input"
                        name="startDate-input"
                        placeholder="date"
                        value={formatDateForPicker(endDate)}
                        onChange={endDateHandler}
                      />
                      <CFormText>
                        <span>{t("Select from picker")}</span>
                      </CFormText>
                    </>
                  )}
                </CCol>
              </CRow>
              <CRow>
                <CCol xs="12" md="6"></CCol>
                <CCol xs="12" md="6">
                  <CLabel htmlFor="date-input">
                    {t("Current project?")} <span> </span>
                  </CLabel>

                  {/* //// */}
                  <div>
                    <CSwitch
                      color="primary"
                      checked={currentProject}
                      onChange={currentProjectHandler}
                    />
                  </div>
                  <CFormText></CFormText>
                </CCol>
              </CRow>
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          {error && <CAlert color="danger">{error}</CAlert>}
          <CButton
            color="success"
            disabled={!isValidForm()}
            onClick={projectAddEdit}
          >
            {editProjectId ? t("Edit") : t("Add")}
          </CButton>{" "}
          <CButton color="secondary" onClick={() => setModal(false)}>
            {t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>

      <CRow>
        <CCol xs="12" md="12">
          {projectList && compactView && (
            <ProjectsViewCompact
              projectEditHandler={projectEditHandler}
              projectList={projectList}
              refreshCVs={props.refreshCVs}
              viewOnly={props.viewOnly}
            />
          )}
          {projectList && !compactView && (
            <ProjectsView
              projectEditHandler={projectEditHandler}
              projectList={projectList}
              refreshCVs={props.refreshCVs}
              viewOnly={props.viewOnly}
            />
          )}
          {projectList.length === 0 && <p>{t("No projects added yet.")}</p>}
        </CCol>
      </CRow>
    </>
  );
};

export default Projects;
