import React, { useEffect, useState } from "react";

import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormGroup,
  CFormText,
  CTextarea,
  CInput,
  CLabel,
  CSelect,
  CRow,
  CSwitch,
  CContainer,
  CAlert,
  CInputCheckbox,
  CLink,
  CToggler,
} from "@coreui/react";
import { TheFooter } from "../../../containers/index";
import CIcon from "@coreui/icons-react";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "../../../axios";
import { validateEmail, validatePhoneNumber, validateHTTP,purposeOptionList } from "src/utils";

import CountryList from "../CountryList/CountryList";
import { Multiselect } from "multiselect-react-dropdown";

const OrgRequest = (props) => {
  const { t, i18n } = useTranslation("translation");
  
  const [optionList, setOptionList] = useState([]);
  const [error, setError] = useState(null);
  const [orgName, setOrgName] = useState("");
  const [orgTypeId, setOrgTypeId] = useState("");
  const [mobile, setMobile] = useState("");
  const [orgEmail, setOrgEmail] = useState("");
  const [officeLocationId, setOfficeLocationId] = useState(0);
  const [hqLocationId, setHQLocationId] = useState(0);
  const [website, setWebsite] = useState("");
  const [officeCity, setOfficeCity] = useState("");
  const [hqCity, setHQCity] = useState("");
  const [repFirstName, setRepFirstName] = useState("");
  const [repLastName, setRepLastName] = useState("");
  const [repGender, setRepGender] = useState(2);
  const [repPosition, setRepPosition] = useState("");
  const [repEmail, setRepEmail] = useState("");
  const [repPhone, setRepPhone] = useState("");
  const [purposes, setPurposes] = useState([]);
  const [interests, setInterests] = useState("");
  const [agreePolicy, setAgreePolicy] = useState(false);

  //Errors
  const [orgNameErr, setOrgNameErr] = useState(null);
  const [orgTypeIdErr, setOrgTypeIdErr] = useState(null);
  const [officeLocationIdErr, setOfficeLocationIdErr] = useState(null);
  const [officeCityErr, setOfficeCityErr] = useState(null);
  const [websiteErr, setWebsiteErr] = useState(null);
  const [mobileIsValid, setMobileIsValid] = useState(null);
  const [locationErr, setLocationErr] = useState(null);
  const [websiteIsValid, setWebsiteIsValid] = useState(null);
  //const [HQErr, setHQErr] = useState(null);
  const [repFirstNameErr, setRepFirstNameErr] = useState(null);
  const [repLastNameErr, setRepLastNameErr] = useState(null);
  // const [repGenderErr, setRepGenderErr] = useState(0);
  const [repPositionErr, setRepPositionErr] = useState(null);
  const [repEmailErr, setRepEmailErr] = useState(null);
  const [orgEmailErr, setOrgEmailErr] = useState(null);
  const [repEmailIsValid, setRepEmailIsValid] = useState(null);
  const [orgEmailIsValid, setOrgEmailIsValid] = useState(null);
  const [repPhoneErr, setRepPhoneErr] = useState(null);
  const [repPhoneIsValid, setRepPhoneIsValid] = useState(null);
  const [purposeErr, setPurposeErr] = useState(null);
  const [done, setDone] = useState(false);

  const fetchOrgTypes = async () => {
    const params = {
      filter: {
        fields: {
          id: true,
          nameAr: true,
          nameEn: true,
        },
        where : {
          "isActive" : true
        }
      },
    };

    const res = await axios.get("/org-types", { params: params });
    //console.log('Organization Data', res.data);
    //Does not take it //setOrgs(res.data);
    return res.data;
  };

  const fetchCountries = async () => {
    const params = {
      filter: {
        fields: {
          id: true,
          nameAr: true,
          nameEn: true,
        },
      },
    };

    const res = await axios.get("/countries", { params: params });
    console.log("countries", res.data);
    //Does not take it //setOrgs(res.data);
    return res.data;
  };
  const { data } = useQuery("get Orgs", fetchOrgTypes);

  const { data: countriesData } = useQuery("getCountries", fetchCountries);

  const isValidForm = () => {
    let isValid =
      orgName && // mandatory
      orgTypeId &&
      orgEmail &&
      orgEmailIsValid &&
      officeLocationId &&
      officeCity &&
      website &&
      websiteIsValid &&
      repFirstName &&
      repLastName &&
      repPosition &&
      repEmail &&
      repEmailIsValid &&
      !purposeErr &&
      agreePolicy; // mandatory

    //TODO check the valid email/site/phone
    return isValid;
  };

  const register = async (allData) => {
    setError(null);
    try {
      const res = await axios.post("/org-requests", allData);
      if (res.error) setError(res.error.response.data.error.message);
      else if (res === undefined) {
        setError("No result returned");
      }
      setDone(true)
    } catch (error) {
      console.log("Custom Error", error);
      setError(error);
    } finally {
      setOrgName("");
      setOrgTypeId("");
      setOrgEmail("");
      setMobile("");
      setOfficeCity("");
      setOfficeLocationId(0);
      setHQLocationId(0);
      setHQCity("");
      setWebsite("");
      setRepFirstName("");
      setRepLastName("");
      setRepGender("");
      setRepPosition("");
      setRepEmail("");
      setRepPhone("");
      setInterests("");
      setPurposes([]);
      setPurposeErr(null);
      setAgreePolicy(false);
    }
  };

  const { mutate, status } = useMutation(register);

  // const orgnamevalid = (event) => {
  //   if (event.target.value == '')
  //   setOrgNameErr('Name is required');
  // else
  //    setOrgNameErr('');
  // };

  const agreePolicyHandler = (event) => {
    console.log(event.target.checked);
    setAgreePolicy(event.target.checked);
  };
  const orgnameHandler = (event) => {
    setOrgName(event.target.value);
    event.target.value
      ? setOrgNameErr(null)
      : setOrgNameErr(t("Organization/ institution/ company Name is required"));
  };
  const orgtypeidHandler = (event) => {
    setOrgTypeId(+event.target.value);
    if (event.target.value === "") setOrgTypeIdErr("Organization is required");
    else setOrgTypeIdErr("");
  };
  const officeLocationHandler = (event) => {
    setOfficeLocationId(+event.target.value);
    if (event.target.value === "")
      setOfficeLocationIdErr("Office Location is required");
    else setOfficeLocationIdErr("");

    if (+event.target.value === 0)
      setOfficeLocationIdErr("Office Location is required");
    else setOfficeLocationIdErr("");
  };

  const hqLocationHandler = (event) => {
    setHQLocationId(+event.target.value);
  };

  const mobileHandler = (event) => {
    setMobile(event.target.value);

    if (event.target.value)
      setMobileIsValid(validatePhoneNumber(event.target.value));
    else setMobileIsValid(true);
  };

  const officeCityHandler = (event) => {
    setOfficeCity(event.target.value);
    event.target.value
      ? setOfficeCityErr(null)
      : setOfficeCityErr("Office city is required");
  };
  const hqCityHandler = (event) => {
    setHQCity(event.target.value);
  };
  const websiteHandler = (event) => {
    setWebsite(event.target.value);
    event.target.value
      ? setWebsiteErr(null)
      : setWebsiteErr("Website is required");

    if (event.target.value.trim() === "") setWebsiteIsValid(true);
    else setWebsiteIsValid(validateHTTP(event.target.value));
    // TODO handle valid URL
  };

  const repFirstNameHandler = (event) => {
    setRepFirstName(event.target.value);
    event.target.value
      ? setRepFirstNameErr(null)
      : setRepFirstNameErr(t("First Name is required"));
  };
  const repLastNameHandler = (event) => {
    setRepLastName(event.target.value);
    event.target.value
      ? setRepLastNameErr(null)
      : setRepLastNameErr(t("Last Name is required"));
  };

  const repgenderHandler = (event) => {
    setRepGender(+event.target.value);
  };

  const repPositionHandler = (event) => {
    setRepPosition(event.target.value);
    event.target.value
      ? setRepPositionErr(null)
      : setRepPositionErr(t("Representitive position is required"));
  };

  const repEmailHandler = (event) => {
    setRepEmail(event.target.value);
    event.target.value
      ? setRepEmailErr(null)
      : setRepEmailErr(t("Email is required"));
    setRepEmailIsValid(validateEmail(event.target.value));
    // handle valid email
  };

  const orgEmailHandler = (event) => {
    setOrgEmail(event.target.value);
    event.target.value
      ? setOrgEmailErr(null)
      : setOrgEmailErr(t("Email is required"));
    setOrgEmailIsValid(validateEmail(event.target.value));
    // handle valid email
  };
  const repPhoneHandler = (event) => {
    setRepPhone(event.target.value);
    // event.target.value ? setRepPhoneErr(null) : setRepPhoneErr('Phone is required');
    //setRepPhoneIsValid(validatePhoneNumber(event.target.value));

    if (event.target.value)
      setRepPhoneIsValid(validatePhoneNumber(event.target.value));
    else setRepPhoneIsValid(true);
  };

  const onSelect = (selectedList, selectedItem) => {
    setPurposes(selectedList);
    if (selectedList.length === 0)
    {
      setPurposeErr(true);
    }
    else setPurposeErr(false);
  };

  const onRemove = (selectedList, removedItem) => {
    setPurposes(selectedList);
    if (selectedList.length === 0)
    {
      setPurposeErr(true);
    }
    else setPurposeErr(false);
  };

  const interestsHandler = (event) => {
    setInterests(event.target.value);
    //event.target.value ? setPurposeErr(null) : setPurposeErr('Purpose is required');
  };

  //handleSubmit
  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = isValidForm();

    if (!isValid) return;
    try {
      const allData = {
        orgName,
        orgEmail,
        mobile,
        officeCity,
        hqCity,
        website,
        repFirstName,
        repLastName,
        repGender,
        repPosition,
        repEmail,
        repPhone,
        purpose: JSON.stringify(purposes),
        interests,
        status: 0,
        orgTypeId,
        officeCountryId: officeLocationId,
        hqCountryId: hqLocationId,
      };
      console.log(allData);
      await mutate(allData);
    } catch {
      console.log("Custom Error", error);
    }
  };
  const lang = useSelector((state) => state.lang);
  const dispatch = useDispatch();

  const toggleLang = () => {
    dispatch({ type: "CHANGE_LANG", lang: lang === "ar" ? "en" : "ar" });
    i18n.changeLanguage(lang === "ar" ? "en" : "ar");
  };

  useEffect(() => {
    if (data && data.length > 0) {
      setOrgTypeId(data[0].id);
      setOfficeLocationId(0);
      setHQLocationId(0);
     
    }
    setOptionList(purposeOptionList(t));
    return () => {};
  }, [data,t,i18n.language]);

  return (
    <div className="c-app c-default-layout flex-row align-items-center mt-5">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="12" className="text-center">
            <img
              src="avatars/logo2.png"
              alt="qalat"
              style={{
                width: "25%",
                marginBottom: "10px"
              }}
            ></img>
          </CCol>
          <CCol md="12" lg="10" xl="8">
            <CCard>
              <CCardBody>
                <CForm onSubmit={handleSubmit}>
                  <h1 className="text-center">
                    {t("Register as an Organization")}
                  </h1>
                  <div className="d-flex justify-content-end">
                    <p className="mr-auto p-2">{t("OrgRegistrationHeader1")} <br/> <br/>
                    {t("OrgRegistrationHeader2")}
                    </p>
                  </div>
                  <div className="d-flex justify-content-end">
                    <CToggler
                      inHeader
                      className="ms-1 p-2"
                      onClick={toggleLang}
                    >
                      {
                        // lang === 'EN' ? <CIcon content={flagSet.cifGb} size="xl" /> :
                        // <CIcon content={flagSet.cifSa} size="xl" />
                        lang === "en" ? "العربية" : "English"
                      }
                    </CToggler>
                  </div>
{!done &&
<>
                  {/* username */}
                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="text-input">
                        {t("Organization Name")}
                        <span className="text-danger in-line"> *</span>
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CInput
                        id="text-input-orgName"
                        name="orgName"
                        value={orgName}
                        onChange={orgnameHandler}
                        onFocus={orgnameHandler}
                        placeholder={t("Insert the name of the institution you represent, or your name if you’re a freelance worker.")}
                      />
                      {orgNameErr && (
                        <CFormText color="danger">{orgNameErr}</CFormText>
                      )}
                    </CCol>
                  </CFormGroup>
                  {/* orgTypeID - select menu */}
                  {/* useMutation LaterOn */}
                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="select">
                        {t("Organization Type")}
                        <span className="text-danger in-line"> *</span>
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CSelect
                        custom
                        name="orgTypeId"
                        value={orgTypeId}
                        onChange={orgtypeidHandler}
                        id="select"
                      >
                        {data &&
                          data.map((item, index) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {localStorage.getItem("lang") === "ar"
                                  ? item.nameAr
                                  : item.nameEn}
                              </option>
                            );
                          })}
                      </CSelect>
                      {orgTypeIdErr && (
                        <CFormText className="text-danger">
                          {orgTypeIdErr}
                        </CFormText>
                      )}
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="email-input">
                        {t("Organization’s email")} <span className="text-danger">*</span>
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CInput
                        type="email"
                        id="org-email-input"
                        name="orgEmail"
                        value={orgEmail}
                        onChange={orgEmailHandler}
                        onFocus={orgEmailHandler}
                        placeholder={t("Type the email address of your organization.")}
                        autoComplete="email"
                      />
                      {orgEmailErr && (
                        <CFormText color="danger">{orgEmailErr}</CFormText>
                      )}
                      {orgEmailIsValid === false && (
                        <CFormText color="danger">
                          {t("Please enter a valid email")}
                        </CFormText>
                      )}
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="text-input">
                        {" "}
                        {t("Organization’s mobile")} <span className="text-danger"> </span>
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CInput
                        id="ccnumber"
                        name="mobile"
                        value={mobile}
                        onChange={mobileHandler}
                        onFocus={mobileHandler}
                        placeholder={t("")}
                      />
                      <CFormText> {t("Example")}: <span dir='ltr'>+33 222 332 2233</span></CFormText>
                      {/* <CFormText color="danger" > {mobileErr}</CFormText> */}
                      <CFormText color="danger">
                        {" "}
                        {mobileIsValid === false &&
                          t("Please enter a valid phone number")}
                      </CFormText>
                    </CCol>
                  </CFormGroup>
                  {/* */}

                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="select">
                        {t("Office Location")}
                        <span className="text-danger in-line">*</span>
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CSelect
                        custom
                        name="officeLocationId"
                        value={officeLocationId}
                        onChange={officeLocationHandler}
                        onFocus={officeLocationHandler}
                        id="select"
                      >
                        <CountryList></CountryList>
                      </CSelect>
                      {officeLocationIdErr && (
                        <CFormText color="danger">
                          {officeLocationIdErr}
                        </CFormText>
                      )}
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="text-input">
                        {t("Office City")}{" "}
                        <span className="text-danger">*</span>
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CInput
                        id="text-input-officeCity"
                        name="officeCity"
                        value={officeCity}
                        onChange={officeCityHandler}
                        onFocus={officeCityHandler}
                        // placeholder={t('Headquarter Address')}
                      />
                      {officeCityErr && (
                        <CFormText color="danger">{officeCityErr}</CFormText>
                      )}

                      {/* <CFormText>This is a help text</CFormText> */}
                    </CCol>
                  </CFormGroup>
                  {/*  */}
                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="select">
                        {t("HQ Location")}
                        <span className="text-danger in-line"></span>
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CSelect
                        custom
                        name="gqLocationId"
                        value={hqLocationId}
                        onChange={hqLocationHandler}
                        id="select"
                      >
                        <CountryList></CountryList>
                      </CSelect>
                      {/* {officeLocationIdErr &&<CFormText color="danger">{officeLocationIdErr}</CFormText>} */}
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="text-input">
                        {t("HQ City")} <span className="text-danger"></span>
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CInput
                        id="text-input-hqCity"
                        name="hqCity"
                        value={hqCity}
                        onChange={hqCityHandler}
                        onFocus={hqCityHandler}
                        placeholder={t("Describe where the headquarters of your organization are located")}
                      />
                      {/* {officeCityErr &&<CFormText color="danger">{officeCityErr}</CFormText>} */}

                      {/* <CFormText>This is a help text</CFormText> */}
                    </CCol>
                  </CFormGroup>

                  {/* Website */}
                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="text-input">
                        {t("Website")} <span className="text-danger">*</span>
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CInput
                        id="text-website-input"
                        name="website"
                        value={website}
                        onChange={websiteHandler}
                        onFocus={websiteHandler}
                        placeholder={t("Insert the URL to your organization’s website")}
                      />
                      <CFormText color="danger">
                        {websiteIsValid === false && t("EmailInvalid")}{" "}
                      </CFormText>
                      <CFormText color="danger">{websiteErr} </CFormText>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="text-input">
                        {t("Purpose")} <span className="text-danger"> *</span>
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <Multiselect
                        options={optionList} // Options to display in the dropdown
                        selectedItem={purposes}
                        onSelect={onSelect} // Function will trigger on select event
                        onRemove={onRemove} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                        placeholder={t("Why are you registering to Qalat? You can select multiple options")}
                      
                      />
                     
                    
                      <CFormText color="danger">{purposeErr && "At least one option is required"} </CFormText>
                    </CCol>
                  </CFormGroup>
                  {/* */}
                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="textarea-input">
                        {t("Interests")} <span className="text-danger"></span>
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CTextarea
                        name="purpose"
                        value={interests}
                        onChange={interestsHandler}
                        onFocus={interestsHandler}
                        id="textarea-input"
                        rows="9"
                        placeholder={t("List the field/s of interest/s in which you wish to contact an expert")}
                      />
                      {/* {purposeErr && <CFormText color="danger">{purposeErr}</CFormText>} */}
                    </CCol>
                  </CFormGroup>
                  {/* repName */}
                  <CFormGroup row>
                    <CCol md="3">
                      <h5>{t("About You")}</h5> 
                    </CCol>
                     <CCol md="9">
                     <h6>{t("Information to be compiled by the person submitting the application.")}</h6>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="text-input">
                        {t("First Name")}{" "}
                        <span className="text-danger"> *</span>
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CInput
                        id="text-input-repFirstName"
                        name="repFirstName"
                        value={repFirstName}
                        onChange={repFirstNameHandler}
                        onFocus={repFirstNameHandler}
                        // placeholder={t('Representative Name')}
                      />

                      <CFormText color="danger">{repFirstNameErr} </CFormText>
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="text-input">
                        {t("Last Name")} <span className="text-danger"> *</span>
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CInput
                        id="text-input-repLastName"
                        name="repLastName"
                        value={repLastName}
                        onChange={repLastNameHandler}
                        onFocus={repLastNameHandler}
                        // placeholder={t('Representative Name')}
                      />

                      <CFormText color="danger">{repLastNameErr} </CFormText>
                    </CCol>
                  </CFormGroup>

                  {/* */}

                  {/* repGender - Selected menu  */}
                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="select">
                        {t("Gender")} <span className="text-danger">*</span>
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CSelect
                        custom
                        name="repGender"
                        id="select"
                        value={repGender}
                        onChange={repgenderHandler}
                      >
                        <option value="4">{t("Prefer not to say")}</option>
                        <option value="1">{t("Male")}</option>
                        <option value="2">{t("Female")}</option>
                        <option value="3">{t("Non-Binary")}</option>
                      </CSelect>
                      {/* {repGenderErr&&
                         <CFormText className="text-danger">{ repGenderErr}</CFormText>
                      } */}
                    </CCol>
                  </CFormGroup>
                  {/* end repGender */}

                  {/* repPosition */}
                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="text-input">
                        {t("Position")} <span className="text-danger"> *</span>
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CInput
                        id="text-input-repPosition"
                        name="repPosition"
                        value={repPosition}
                        onChange={repPositionHandler}
                        onFocus={repPositionHandler}
                        placeholder={t("Write your position inside your organization or if you are freelancer.")}
                      />

                      {repPositionErr && (
                        <CFormText color="danger">{repPositionErr}</CFormText>
                      )}
                    </CCol>
                  </CFormGroup>
                  {/*  */}

                  {/* repEmail */}
                  <CFormGroup row>
                    <CCol md="3">
                      <CLabel htmlFor="email-input">
                        {t("Email")} <span className="text-danger">*</span>
                      </CLabel>
                    </CCol>
                    <CCol xs="12" md="9">
                      <CInput
                        type="email"
                        id="email-input"
                        name="repEmail"
                        value={repEmail}
                        onChange={repEmailHandler}
                        onFocus={repEmailHandler}
                        placeholder={t("Write your personal email address")}
                        autoComplete="email"
                      />
                      {repEmailErr && (
                        <CFormText color="danger">{repEmailErr}</CFormText>
                      )}
                      {repEmailIsValid === false && (
                        <CFormText color="danger">
                          {t("Please enter a valid email")}
                        </CFormText>
                      )}
                    </CCol>
                  </CFormGroup>
                  {/*  */}

                  {/* repPhone */}
                  <CFormGroup row>
                    <CCol md="12">
                      <CFormGroup variant="custom-checkbox" inline>
                        <CInputCheckbox
                          custom
                          id="inline-checkbox1"
                          name="inline-checkbox1"
                          onChange={agreePolicyHandler}
                          checked={agreePolicy}
                        />
                        <CLabel
                          variant="custom-checkbox"
                          htmlFor="inline-checkbox1"
                        >
                          {t("I have read and agree with")}{" "}
                          <CLink target="_blank" href="https://qalat.org/general-terms-and-conditions/">
                        {t("the general terms and conditions")}
                      </CLink> {t("and")} <CLink target="_blank" href="https://qalat.org/privacy-statement/">
                        {t("privacy statement")}
                      </CLink>
                        </CLabel>
                      </CFormGroup>
                    </CCol>
                  </CFormGroup>

                  <CAlert color="info">
                    {t("All mandatory fields are marked with")}{" "}
                    <span className="text-danger">*</span>{" "}
                    {t("and they are required for request submission.")}
                  </CAlert>

                  {error && (
                    <CAlert
                      color="danger"
                      closeButton
                      onClick={(event) => event.preventDefault()}
                    >
                      {t(error)}
                    </CAlert>
                  )}
</> }
                  {status === "success" && !error && (
                    <CAlert
                      color="success"
                      closeButton
                      onClick={(event) => event.preventDefault()}
                    >
                      {t("Your request has been successfully sent")}
                    </CAlert>
                  )}

                  {/* {status ==='success' &&
                      <CAlert color="success"
                    closeButton
                  onClick={event => event.preventDefault()}>
                               You Successfully Registered
                       </CAlert>
                    } */}
                 {!done && <CButton
                    type="submit"
                    color="success"
                    disabled={!isValidForm()}
                  >
                    {t("Submit Request")}
                  </CButton>}
                </CForm>
                <div className="d-flex justify-content-end">
                  <p className="mr-auto p-2"></p>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <TheFooter />
      </CContainer>
    </div>
  );
};

export default OrgRequest;
