import CIcon from "@coreui/icons-react";
import {
  CAlert,
  CButton,
  CCol,
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTextarea,
  CSelect,
  CInputCheckbox
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import CountryList from "../../../CountryList/CountryList";
import PerosnalInfoView from "./PerosnalInfoView";
import PersonalPhoto from "./PersonalPhoto";
import axios from "../../../../../axios";
import {
  checkValue,
  formatDateForPicker,
  validateEmail,
  validateHTTP,
  validatePhoneNumber,
} from "src/utils";
import { cilDelete } from "@coreui/icons";
import { useTranslation } from "react-i18next";

const PersonalInfo = (props) => {
  const [modal, setModal] = useState(false);

  const { t, i18n } = useTranslation();

  const [currentPersonalInfo, setCurrentPersonalInfo] = useState(
    checkValue(props.currentPersonalInfo)
  );
  const [otherPersonalInfo, setOtherPersonalInfo] = useState(
    checkValue(props.otherPersonalInfo)
  );

  const [personalPhoto, setPersonalPhoto] = useState("");
  const [enFullName, setEnFullName] = useState("");
  const [arFullName, setArFullName] = useState("");
  const [enSummary, setEnSummary] = useState("");
  const [arSummary, setArSummary] = useState("");
  const [enCity, setEnCity] = useState("");
  const [arCity, setArCity] = useState("");

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState(currentPersonalInfo.phone);
  const [website, setWebsite] = useState("");
  const [countryId, setCountryId] = useState("");

  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [audioMedia, setAudioMedia] = useState(false);
  const [visualMedia, setVisualMedia] = useState(false);
  const [writtenMedia, setWrittenMedia] = useState(false);

  const [error, setError] = useState("");

  const [enFullNameErr, setEnFullNameErr] = useState(false);
  const [arFullNameErr, setArFullNameErr] = useState(false);
  const [enSummaryErr, setEnSummaryErr] = useState(false);
  const [arSummaryErr, setArSummaryErr] = useState(false);
  //const [dateOfBirthErr, setDateOfBirthErr] = useState(null);
  const [emailErr, setEmailErr] = useState(false);
  //const [phoneErr, setPhoneErr] = useState(null);
  const [countryIdErr, setCountryIdErr] = useState(false);
  //const [cityErr, setCityErr] = useState(null);

  // const [phoneIsValid, setPhoneIsValid] = useState(null);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [websiteIsValid, setWebsiteIsValid] = useState(false);
  const [facebookIsValid, setFacebookIsValid] = useState(false);
  const [twitterIsValid, setTwitterIsValid] = useState(false);
  const [linkedInIsValid, setLinkedInIsValid] = useState(false);

  useEffect(() => {
    setCurrentPersonalInfo(props.currentPersonalInfo);
    setOtherPersonalInfo(props.otherPersonalInfo);

    // if (props.currentPersonalInfo) {
    //   setEnFullName(checkValue(props.currentPersonalInfo.fullName));

    //   props.currentPersonalInfo.fullName
    //     ? setEnFullNameErr(false)
    //     : setEnFullNameErr(true);

    //   setArFullName(checkValue(props.otherPersonalInfo.fullName));
    //   props.otherPersonalInfo.fullName
    //     ? setArFullNameErr(false)
    //     : setArFullNameErr(true);

    //   setEnSummary(checkValue(props.currentPersonalInfo.summary));
    //   props.currentPersonalInfo.summary
    //     ? setEnSummaryErr(false)
    //     : setEnSummaryErr(true);

    //   setArSummary(checkValue(props.otherPersonalInfo.summary));
    //   props.otherPersonalInfo.summary
    //     ? setArSummaryErr(false)
    //     : setArSummaryErr(true);

    //   setDateOfBirth(
    //     formatDateForPicker(props.currentPersonalInfo.dateOfBirth)
    //   );

    //   setEmail(checkValue(props.currentPersonalInfo.email));
    //   props.currentPersonalInfo.email ? setEmailErr(false) : setEmailErr(true);

    //   setEmailIsValid(validateEmail(props.currentPersonalInfo.email));

    //   // const phoneHandler = (event) => {
    //   //   setPhone(props.currentPersonalInfo.value);
    //   //   props.currentPersonalInfo.value ? setPhoneErr(false) : setPhoneErr(true);

    //   //   setPhoneIsValid(validatePhoneNumber(props.currentPersonalInfo.value));
    //   // };

    //   setWebsite(checkValue(props.currentPersonalInfo.website));
    //   setWebsiteIsValid(validateHTTP(props.currentPersonalInfo.website));

    //   const selectedId = +props.currentPersonalInfo.countryId;
    //   setCountryId(selectedId ? selectedId : 0);

    //   if (selectedId) setCountryIdErr(false);
    //   else setCountryIdErr(true);

    //   setEnCity(checkValue(props.currentPersonalInfo.city));

    //   setArCity(checkValue(props.otherPersonalInfo.city));

    //   setFacebook(checkValue(props.currentPersonalInfo.facebook));
    //   setFacebookIsValid(validateHTTP(props.currentPersonalInfo.facebook));
    //   setTwitter(checkValue(props.currentPersonalInfo.twitter));
    //   setTwitterIsValid(validateHTTP(props.currentPersonalInfo.twitter));

    //   setLinkedIn(checkValue(props.currentPersonalInfo.linkedIn));
    //   setLinkedInIsValid(validateHTTP(props.currentPersonalInfo.linkedIn));
    // }
    return () => {};
  }, [props.currentPersonalInfo, props.otherPersonalInfo]);

  const isValidForm = () => {
    return (
      enFullName &&
      enSummary &&
      arFullName &&
      arSummary &&
      email &&
      !emailErr &&
      countryId &&
      !countryIdErr &&
      websiteIsValid &&
      facebookIsValid &&
      twitterIsValid &&
      linkedInIsValid
    );
  };
  // console.log('currentPersonalInfo info',props.currentPersonalInfo);

  const editPersonalInfo = async (allData) => {
    try {
      setError(null);
      console.log(
        "currentPersonalInfo.id, allData",
        currentPersonalInfo.id,
        allData
      );
      const res = await axios.patch(
        "/personal-infos/" + currentPersonalInfo.id,
        allData
      );
      if (res.error) {
        // setStartBuildingCVError(res.error.response.data.error.message);
        console.log(
          "res.error.response.data.error.message",
          res.error.response.data.error.message
        );

        setError(res.error.response.data.error.message);
      } else if (!res) {
        setError("No response from API");
      } else {
        console.log("updated personal info", res.data);

        setModal(false);
        return res.data;
      }
    } catch (error) {
      console.log("Custom Error", error);
    } finally {
      props.refreshCVs();
    }
  };

  const { mutate, status } = useMutation(editPersonalInfo);

  const updatePersonalInfo = (event) => {
    event.preventDefault();
    try {
      const allData = {
        id: currentPersonalInfo.id,
        cvId: currentPersonalInfo.cvId,
        personalPhoto,
        enFullName,
        enSummary,
        arFullName,
        arSummary,
        enCity,
        arCity,
        dateOfBirth: dateOfBirth === "" ? null : dateOfBirth,
        email,
        // phone,
        website,
        countryId,
        facebook,
        twitter,
        linkedIn,
        audioMedia,
        visualMedia,
        writtenMedia
      };
      console.log("allData", allData);
      mutate(allData);
    } catch (err) {}
  };

  const personalPhotoHandler = (personalImage) => {
    console.log("personalPhoto changed", personalImage && "exists");
    setPersonalPhoto(personalImage);
  };

  const enFullNameHandler = (event) => {
    setEnFullName(event.target.value);

    event.target.value ? setEnFullNameErr(false) : setEnFullNameErr(true);
  };
  const arFullNameHandler = (event) => {
    setArFullName(event.target.value);
    event.target.value ? setArFullNameErr(false) : setArFullNameErr(true);
  };

  const enSummaryHandler = (event) => {
    setEnSummary(event.target.value);
    event.target.value ? setEnSummaryErr(false) : setEnSummaryErr(true);
  };
  const arSummaryHandler = (event) => {
    setArSummary(event.target.value);
    event.target.value ? setArSummaryErr(false) : setArSummaryErr(true);
  };
  const dateOfBirthHandler = (event) => {
    setDateOfBirth(formatDateForPicker(event.target.value));
  };

  const emailHandler = (event) => {
    setEmail(event.target.value);
    event.target.value ? setEmailErr(false) : setEmailErr(true);

    setEmailIsValid(validateEmail(event.target.value));
  };

  // const phoneHandler = (event) => {
  //   setPhone(event.target.value);
  //   event.target.value ? setPhoneErr(false) : setPhoneErr(true);

  //   setPhoneIsValid(validatePhoneNumber(event.target.value));
  // };

  const websiteHandler = (event) => {
    setWebsite(event.target.value);
    setWebsiteIsValid(validateHTTP(event.target.value));
  };

  const countryHandler = (event) => {
    const selectedId = +event.target.value;
    setCountryId(selectedId);

    selectedId ? setCountryIdErr(false) : setCountryIdErr(true);
  };
  const enCityHandler = (event) => {
    setEnCity(event.target.value);
  };
  const arCityHandler = (event) => {
    setArCity(event.target.value);
  };
  const facebookHandler = (event) => {
    setFacebook(event.target.value);
    setFacebookIsValid(validateHTTP(event.target.value));
  };
  const twitterHandler = (event) => {
    setTwitter(event.target.value);
    setTwitterIsValid(validateHTTP(event.target.value));
  };
  const linkedinHandler = (event) => {
    setLinkedIn(event.target.value);
    setLinkedInIsValid(validateHTTP(event.target.value));
  };
 const audioMediaHandler = (event) => {
  console.log("audioMediaHandler",event.target.checked)
    setAudioMedia(event.target.checked);
   };
   const visualMediaHandler = (event) => {
    setVisualMedia(event.target.checked);
   };
   const writtenMediaHandler = (event) => {
    setWrittenMedia(event.target.checked);
   };
  return (
    <>
      <CRow>
        <CCol xs="12" md="12">
          <div
            className="cv-section-header col-md-12"
            onClick={(event) => {
              if (props.viewOnly ) return;
              setModal(true);

              setPersonalPhoto(checkValue(props.currentPersonalInfo.personalPhoto));
              setEnFullName(
               checkValue(props.currentPersonalInfo.fullName)
                
              );
              setArFullName(
               
                  checkValue(props.otherPersonalInfo.fullName)
              );
              setEnSummary(
                checkValue(props.currentPersonalInfo.summary)
               
              );
              setArSummary(
                checkValue(props.otherPersonalInfo.summary)
              );
              setEnCity(
                checkValue(props.currentPersonalInfo.city)
               );
              setArCity(
                checkValue(props.otherPersonalInfo.city)
              );

              // check errors
              if (i18n.language === "en")
              {
                setEnFullNameErr(props.currentPersonalInfo.fullName ? false : true)
                setArFullNameErr(props.otherPersonalInfo.fullName ? false : true)

                setEnSummaryErr(props.currentPersonalInfo.summary ? false : true)
                setArSummaryErr(props.otherPersonalInfo.summary ? false : true)
              }
              else {
                setArFullNameErr(props.currentPersonalInfo.fullName ? false : true)
                setEnFullNameErr(otherPersonalInfo.fullName ? false : true)

                setArSummaryErr(props.currentPersonalInfo.summary ? false : true)
                setEnSummaryErr(props.otherPersonalInfo.summary ? false : true)
            
              }

              setDateOfBirth(
                props.currentPersonalInfo.dateOfBirth === undefined
                  ? ""
                  : props.currentPersonalInfo.dateOfBirth
              );

              setEmail(checkValue(props.currentPersonalInfo.email));
              setEmailErr(props.currentPersonalInfo.email ? false : true);
              setEmailIsValid(validateEmail(props.currentPersonalInfo.email))
              // const [phone, setPhone] = useState(currentPersonalInfo.phone);
              setWebsite(checkValue(props.currentPersonalInfo.website));
              setWebsiteIsValid(validateHTTP(props.currentPersonalInfo.website));

              setCountryId(
                props.currentPersonalInfo.countryId
                  ? props.currentPersonalInfo.countryId
                  : 0
              );
              setCountryIdErr(props.currentPersonalInfo.countryId === null || (props.currentPersonalInfo.countryId ===0))

              setFacebook(checkValue(props.currentPersonalInfo.facebook));
                setFacebookIsValid(validateHTTP(props.currentPersonalInfo.facebook))
              setTwitter(checkValue(props.currentPersonalInfo.twitter));
              setTwitterIsValid(validateHTTP(props.currentPersonalInfo.twitter))
           
              setLinkedIn(checkValue(props.currentPersonalInfo.linkedIn));
              setLinkedInIsValid(validateHTTP(props.currentPersonalInfo.linkedIn))

              setAudioMedia(props.currentPersonalInfo.audioMedia ? true: false);

              setVisualMedia(props.currentPersonalInfo.visualMedia ?  true: false);
              setWrittenMedia(props.currentPersonalInfo.writtenMedia ?  true: false);
           
            }}
          >
            <h5>              
              {t("Personal Info")} {!props.viewOnly && <strong><span>{t("Click to edit")}</span></strong>}
            </h5>
          </div>
        </CCol>
      </CRow>
      <CModal show={modal} closeOnBackdrop={false} onClose={setModal}>
        <CModalHeader closeButton className="modal--header-color">
          <CModalTitle>{t("Personal Info")} </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol xs="12" md="12">
              <CFormGroup row>
                <CCol xs="12" md="3" className="text-center">
                  <PersonalPhoto
                    onChange={personalPhotoHandler}
                    personalPhoto={personalPhoto}
                  />
                </CCol>
                <CCol xs="12" md="3">
                  <CLabel htmlFor="date-input">
                    {t("Full Name in English")}<span className="text-danger">*</span>
                  </CLabel>
                  <CInput
                    id="enFullName-input"
                    name="enFullName-input"
                    placeholder="Enter your name in English letters"
                    value={enFullName}
                    onChange={enFullNameHandler}
                    onFocus={enFullNameHandler}
                  />
                  <CFormText>
                    <span className={enFullNameErr ? "text-danger" : ""}>
                      {t("Required")}
                    </span>
                  </CFormText>
                </CCol>
                <CCol xs="12" md="3">
                  <CLabel htmlFor="date-input">
                    {t("Summary in English")}<span className="text-danger">*</span>
                  </CLabel>

                  <CTextarea
                    name="textarea-input"
                    id="textarea-input"
                    rows="6"
                    placeholder={"Briefly introduce yourself, Who are you? What is your professional background?"}
                    value={enSummary}
                    onChange={enSummaryHandler}
                    onFocus={enSummaryHandler}
                  />
                  <CFormText>
                    <span className={enSummaryErr ? "text-danger" : ""}>
                    {t("Required")}
                    </span>
                  </CFormText>
                </CCol>
                <CCol xs="12" md="3">
                  <CLabel htmlFor="text-input">
                   {t("City in English")}<span className="text-danger"> </span>
                  </CLabel>
                  <CInput
                    id="city-input"
                    name="city-input"
                    placeholder="City Name"
                    value={enCity}
                    onChange={enCityHandler}
                    onFocus={enCityHandler}
                  />
                  <CFormText>
                    <span> {t("Enter your address city")}</span>
                  </CFormText>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs="12" md="3">
                  <CLabel htmlFor="email-input">
                    {t("Email Address")}<span className="text-danger"> *</span>
                  </CLabel>
                  <CInput
                    type="email"
                    id="email-input"
                    name="email-input"
                    placeholder="name@qalat.org"
                    autoComplete="email"
                    value={email}
                    onChange={emailHandler}
                    onFocus={emailHandler}
                  />
                  <CFormText className="help-block">
                    <span className={emailErr ? "text-danger" : ""}>
                      {" "}
                      {t("Enter your valid email")}
                    </span>
                    {!emailIsValid && (
                      <span className="text-danger"> {t("e.g.")} name@qalat.org</span>
                    )}
                  </CFormText>
                </CCol>
                <CCol xs="12" md="3">
                  <CLabel htmlFor="date-input">
                    {t("Full Name in Arabic")} <span className="text-danger">*</span>
                  </CLabel>

                  <CInput
                    id="text-input-arFullName"
                    name="text-input"
                    placeholder="الاسم الكامل"
                    value={arFullName}
                    onChange={arFullNameHandler}
                    onFocus={arFullNameHandler}
                  />
                  <CFormText>
                    {" "}
                    <span className={arFullNameErr ? "text-danger" : ""}>
                    {t("Required")}                  
                    </span>
                  </CFormText>
                </CCol>

                <CCol xs="12" md="3">
                  <CLabel htmlFor="date-input">
                    {t("Summary in Arabic")} <span className="text-danger">*</span>
                  </CLabel>

                  <CTextarea
                    name="textarea-input"
                    id="textarea-input"
                    rows="6"
                    placeholder="عرفي عن نفسك باختصار، من أنت، ما هي مهاراتكي التقنية؟"
                    value={arSummary}
                    onChange={arSummaryHandler}
                    onFocus={arSummaryHandler}
                  />
                  <CFormText>
                    <span className={arSummaryErr ? "text-danger" : ""}>
                    {t("Required")}     
                    </span>
                  </CFormText>
                </CCol>
                <CCol xs="12" md="3">
                  <CLabel htmlFor="text-input">
                    {t("City in Arabic")}<span className="text-danger"> </span>
                  </CLabel>
                  <CInput
                    id="arcity-input"
                    name="arcity-input"
                    placeholder="المدينة"
                    value={arCity}
                    onChange={arCityHandler}
                    onFocus={arCityHandler}
                  />
                  <CFormText>
                    <span> {t("Enter your address city in Arabic")}</span>
                  </CFormText>
                </CCol>
              </CFormGroup>
            </CCol>
          </CRow>
          <CFormGroup row>
            <CCol xs="12" md="3">
              <CLabel htmlFor="date-input">
               {t("Birth Date")}
                <span>
                  {" "}
                  <CIcon
                    content={cilDelete}
                    height="15"
                    className="text-danger pointer"
                    alt="Click to remove birth date"
                    onClick={() => setDateOfBirth("")}
                  />
                </span>
              </CLabel>
              <CInput
                type="date"
                id="dateOfBirth-input"
                name="dateOfBirth-input"
                placeholder="date"
                value={dateOfBirth ? dateOfBirth : ""}
                onChange={dateOfBirthHandler}
              />
              <CFormText>
                <span>{t("Select from picker")}</span>
              </CFormText>
            </CCol>
            <CCol xs="12" md="3">
              <CLabel htmlFor="select">
              {t("Country of residence")}<span className="text-danger"> *</span>
              </CLabel>
              <CSelect
                custom
                name="select"
                id="select"
                value={countryId}
                onChange={countryHandler}
                onFocus={countryHandler}
              >
                <CountryList></CountryList>
              </CSelect>
              <CFormText>
                <span className={countryIdErr ? "text-danger" : ""}>
                  {" "}
                  {t("Select from the list")}
                </span>
              </CFormText>
            </CCol>
            <CCol xs="12" md="3">
              <CLabel htmlFor="text-input">{t("Website")}</CLabel>
              <CInput
                id="text-input-website"
                name="text-input-website"
                placeholder="http://"
                value={website}
                onChange={websiteHandler}
                onFocus={websiteHandler}
              />
              <CFormText>
               
                {!websiteIsValid && <span className="text-danger"> {t("Invalid")}</span>}{" "}
               {t("optional")}
              </CFormText>
            </CCol>

            {/*  <CCol xs="12" md="4">
                   <CLabel htmlFor="text-input">
                    Phone Number<span className="text-danger"> *</span>
                  </CLabel>
                  <CInput
                    id="text-input"
                    name="text-input"
                    placeholder="+33 1232255"
                    value={phone}
                    onChange={phoneHandler}
                    onFocus={phoneHandler}
                  />
                  <CFormText>
                    <span className={phoneErr ? "text-danger" : ""}>
                      Enter a valid phone number
                    </span>
                    {!phoneIsValid && (
                      <span className="text-danger"> e.g. +33 1232255</span>
                    )}
                  </CFormText> 
                </CCol>*/}
            <CCol xs="12" md="3">
              <CIcon
                name="cib-facebook"
                height="25"
                className="social-media-icon-label "
              />

              <CInput
                id="facebook-input"
                name="facebook-input"
                placeholder="http://"
                value={facebook}
                onChange={facebookHandler}
                onFocus={facebookHandler}
              />
              <CFormText>
                 {!facebookIsValid && <span className="text-danger">{t("Invalid")}</span>}{" "}
                {t("optional")}
              </CFormText>
            </CCol>
          </CFormGroup>
          <CFormGroup row></CFormGroup>

          <CRow>
            <CCol xs="12" md="12">
              <CFormGroup row>
                <CCol xs="12" md="3">
                  <CIcon
                    name="cib-twitter"
                    height="25"
                    className="social-media-icon-label"
                  />

                  <CInput
                    id="twitter-input"
                    name="twitter-input"
                    placeholder="http://"
                    value={twitter}
                    onChange={twitterHandler}
                    onFocus={twitterHandler}
                  />
                  <CFormText>
                  
                    {!twitterIsValid && (
                      <span className="text-danger">{t("Invalid")}</span>
                    )}{" "}
                   {t("optional")}
                  </CFormText>
                </CCol>
                <CCol xs="12" md="3">
                  <CIcon
                    name="cib-linkedin"
                    height="25"
                    className="social-media-icon-label"
                  />
                  <CInput
                    id="linkedin-input"
                    name="linkedin-input"
                    placeholder="http://"
                    value={linkedIn}
                    onChange={linkedinHandler}
                    onFocus={linkedinHandler}
                  />
                  <CFormText>
                   {!linkedInIsValid && (
                      <span className="text-danger">{t("Invalid")}</span>
                    )}{" "}
                      {t("optional")}                
                  </CFormText>
                </CCol>
                <CCol xs="12" md="6">
            <CLabel htmlFor="Mediaappearancepreferences-view">{t("Media appearance preferences")}</CLabel>
                          
             <CFormGroup variant="checkbox" className="checkbox">
                      <CInputCheckbox 
                   
                          id="Mediaappearancepreferences-viewAudio"
                          name="Mediaappearancepreferences-viewAudio"
                          checked={audioMedia}
                          onChange={audioMediaHandler}
                      />
                      <CLabel variant="checkbox" className="form-check" >{t("Audio media")}</CLabel>
                      <CInputCheckbox 
                    
                          id="Mediaappearancepreferences-viewvisual"
                          name="Mediaappearancepreferences-viewvisual"
                          checked={visualMedia}
                          onChange={visualMediaHandler}
                    
                         
                      />
                      <CLabel variant="checkbox" className="form-check" >{t("Visual media")}</CLabel>
                      <CInputCheckbox 
                   
                          id="Mediaappearancepreferences-viewwritten"
                          name="Mediaappearancepreferences-viewwritten"
                          checked={writtenMedia}
                          onChange={writtenMediaHandler}
                    
                      />
                      <CLabel variant="checkbox" className="form-check" >{t("Written media")}</CLabel>
                    </CFormGroup>
            </CCol>
              </CFormGroup>
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter>
          {error && <CAlert color="danger">{error}</CAlert>}
          <CButton
            color="success"
            disabled={!isValidForm()}
            onClick={updatePersonalInfo}
          >
            {t("Update")}
          </CButton>{" "}
          <CButton color="secondary" onClick={() => setModal(false)}>
          {t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>

      <CRow>
        <CCol xs="12" md="12">
          {props.currentPersonalInfo && props.otherPersonalInfo && (
            <PerosnalInfoView
              currentPersonalInfo={props.currentPersonalInfo}
              otherPersonalInfo={props.otherPersonalInfo}
              showContact={props.showContact}
              viewOnly={props.viewOnly}
            />
          )}
        </CCol>
      </CRow>
    </>
  );
};

export default PersonalInfo;
