import CIcon from "@coreui/icons-react";
import {
  CAlert,
  CButton,
  CCol,
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTextarea,
  CSelect,
  CSwitch,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import CountryList from "../../../CountryList/CountryList";
import LanguagesView from "./CVLanguagesView";

import axios from "../../../../../axios";
import {
  formatDateForPicker,
  validateEmail,
  validateHTTP,
  validatePhoneNumber,
} from "src/utils";
import { cilCalendarCheck, cilDelete, cilPlus } from "@coreui/icons";
import LanguagesViewCompact from "./CVLanguagesViewCompact";
import ProficiencyList from "src/views/pages/ProficiencyList/ProficiencyList";
import LanguagesOption from "src/views/pages/LanguagesOption/LanguagesOption";
import { useTranslation } from "react-i18next";

const CVLanguages = (props) => {
  const [modal, setModal] = useState(false);
  const [compactView, setCompactView] = useState(false);

  const [languageList, setLanguageList] = useState(
    props.languages ? props.languages : []
  );

  const [languageId, setLanguageId] = useState(0);
  const [proficiency, setProficiency] = useState(0);
 
  const [editMode, setEditMode] = useState(false);
  const [editLanguageId, setEditLanguageId] = useState(0);
  // const [phone, setPhone] = useState(currentPersonalInfo.phone);
  // const [title, setTitle] = useState(currentPersonalInfo.currentTitle);
  const [error, setError] = useState(null);

  const {t} = useTranslation()
  useEffect(() => {
    setLanguageList(props.languages ? props.languages : []);

    if (editLanguageId) {
      const language = props.languages.find((item) => item.id === editLanguageId);
      console.log("selected language ", language);
      setLanguageId(language.languageId);
      setProficiency(language.proficiency);
      
    } else {
      setLanguageId(0);
      setProficiency(0);
    
    }
    return () => {};
  }, [props.languages, editLanguageId]);

  const isValidForm = () => {
    return languageId > 0 ;
  };
  // console.log('currentPersonalInfo info',props.currentPersonalInfo);

  const addEditLanguage = async (allData) => {
    try {
      setError(null);
      console.log(
        "addLanguage",

        allData
      );
      let res = null;
      if (editLanguageId === 0) // insert mode
        res = await axios.post(
          "/cv-languages/", //+ currentPersonalInfo.id,
          allData
        );
      else// edit mode
        res = await axios.patch(
          "/cv-languages/" + editLanguageId,
          allData
        );


      if (res.error) {
        // setStartBuildingCVError(res.error.response.data.error.message);
        console.log(
          "res.error.response.data.error.message",
          res.error.response.data.error.message
        );
        setError(res.error.response.data.error.message);
      } else if (res === undefined) {
        setError("No response from API");
      } else {
        console.log("updated personal info", res.data);

        setModal(false);
        setLanguageId(0);
        setProficiency(0);
        setEditLanguageId(0);
        setError(null);
        return res.data;
      }
    } catch (error) {
      setError("Custom Error" + error);
    } finally {
      props.refreshCVs();
    }
  };

  const { mutate, status } = useMutation(addEditLanguage);

  const languageAddEdit = (event) => {
    event.preventDefault();
    try {
      const allData = {
        languageId,
        proficiency,
        cvId: props.CVId,
      };
      console.log("Language data", allData);
      mutate(allData);
    } catch (err) {
      setError("Unhandled Error" + err);
    }
  };

  const languageHandler = (event) => {
    setLanguageId(+event.target.value);
  };
  const proficiencyHandler = (event) => {
    setProficiency(+event.target.value);
  };
 
  const languageEditHandler = (id) => {
    setEditMode(true);
    setEditLanguageId(id);
    setModal(true);
  };
  const compacyViewHandler = (event) => {
    setCompactView(!compactView);
    console.log('compactView',compactView)
  };
 return (
    <>
      <CRow>
        <CCol xs="12" md="12">
          <div
            className="cv-section-header col-md-12"
            onClick={(event) => {
              if (props.viewOnly ) return;
            
              setProficiency(0);
              setLanguageId(0);
              
              setEditLanguageId(0);
              setError(null);
              setModal(true);
              setEditMode(false);
            
            }}
          >
            <h5>
              {t("Languages")}{" "}
              {!props.viewOnly && <span>
                {t("Click to add")} <CIcon content={cilPlus} size="xl"></CIcon>{" "}
              </span>}
            </h5>
           </div>
           <div style={{ textAlign:"end" }}>
            <span style={{verticalAlign: "top"}}>{t("Compact View")} </span>
            <CSwitch color="primary" onChange={compacyViewHandler} checked={compactView} />
           </div>
                  
        </CCol>
      </CRow>
      <CModal show={modal} closeOnBackdrop={false} onClose={setModal}>
        <CModalHeader closeButton className="modal--header-color">
          <CModalTitle>{t("Languages")}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CFormGroup row>
          <CCol xs="12" md="6">
                  <CLabel htmlFor="select">
                  {t("Language")}<span className="text-danger"> *</span>
                  </CLabel>
                  <CSelect
                    custom
                    name="select"
                    id="select"
                    value={languageId}
                    onChange={languageHandler}
                    onFocus={languageHandler}
                  
                  >
                    <LanguagesOption></LanguagesOption>
                  </CSelect>
                  <CFormText>
                  {t("Required")}
                    
                  </CFormText>
                </CCol>
                <CCol xs="12" md="6">
                  <CLabel htmlFor="select">
                  {t("Proficiency")}<span className="text-danger"> *</span>
                  </CLabel>
                  <CSelect
                    custom
                    name="select"
                    id="select"
                    value={proficiency}
                    onChange={proficiencyHandler}
                    onFocus={proficiencyHandler}
                  
                  >
                    <ProficiencyList></ProficiencyList>
                  </CSelect>
                  <CFormText>
                  {t("Required")}
                    
                  </CFormText>
                </CCol>
          </CFormGroup>
         
        </CModalBody>
        <CModalFooter>
          {error && <CAlert color="danger">{error}</CAlert>}
          <CButton
            color="success"
            disabled={!isValidForm()}
            onClick={languageAddEdit}
          >
            {editLanguageId ? t("Edit") : t("Add")}
          </CButton>{" "}
          <CButton color="secondary" onClick={() => setModal(false)}>
            {t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>

      <CRow>
        <CCol xs="12" md="12">

          {
            (languageList && compactView) && 
            <LanguagesViewCompact
              languageEditHandler={languageEditHandler}
              languageList={languageList}
              refreshCVs={props.refreshCVs}
              viewOnly={props.viewOnly}
            />
          }
          {
            (languageList && !compactView) && 
            <LanguagesView
              languageEditHandler={languageEditHandler}
              languageList={languageList}
              refreshCVs={props.refreshCVs}
              viewOnly={props.viewOnly}
            />
          }
          {(languageList.length === 0) &&
            <p>{t("No languages added yet.")}</p>
          }
        </CCol>
      </CRow>
    </>
  );
};

export default CVLanguages;
