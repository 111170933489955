import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react'

import CIcon from '@coreui/icons-react'

// sidebar nav config
import navigation from './_nav'
import { useTranslation } from 'react-i18next'

const TheSidebar = () => {

  const isAuth  = useSelector(state => state.token != null)
  const userRole  = useSelector(state => state.userRole)

  const {t,i18n} = useTranslation();

    // hide Users from nav
    navigation[0].hidden = userRole !== 'Admin';
    navigation[1].hidden = userRole !== 'Admin';// Indexes
    navigation[2].hidden = userRole !== 'Admin';
    navigation[3].hidden = userRole !== 'Admin';
    navigation[4].hidden = userRole !== 'Admin';// CV provacy one
    navigation[5].hidden = userRole !== 'Admin';// Indexes
   
    navigation[6].hidden = userRole === 'Admin'
    navigation[7].hidden = userRole !== 'Admin';// Indexes
    navigation[8].hidden = userRole !== 'Admin';// Indexes
    navigation[9].hidden = userRole !== 'Admin';// Indexes
    navigation[10].hidden = userRole !== 'Admin';// Indexes
    navigation[11].hidden = userRole !== 'Expert'; //My CV
    navigation[12].hidden = userRole !== 'Expert'; //My Settings


    navigation[0].name = t('Dashboard');
    navigation[1].name = t('Expert CV List');
    navigation[2]._children = [t('Settings')];
    navigation[3].name = t('Users');
    navigation[4].name = t('CV Privacy');
    navigation[5].name = t('Indexes');
    navigation[6].name = t('Expert CV View');
    navigation[7].name = t('2FA');
    navigation[8]._children = [t('Requests')];
    navigation[9].name = t('Organization Requests');
    navigation[10].name = t('CV Requests');
    navigation[11].name = t('My CV');
    navigation[12].name = t('My Settings');

  const dispatch = useDispatch()
  const show = useSelector(state => state.sidebarShow)

  return (
    <CSidebar
      show={show}
      onShowChange={(val) => dispatch({type: 'set', sidebarShow: val })}
    >
      <CSidebarBrand style={{
        backgroundColor: '#fff',
        padding: '0 0 3px 0',
        borderRight: '1px solid #ccc',
        paddingTop : '10px',
        paddingBottom : '10px',
        height: "104px"
       
       }} className="d-md-down-none" to="/">
        <img src={`${window.location.origin}/avatars/logo2.png`} alt="QALAT" style={
          {
            width: "80%",
            height: "100%"
          }

        }></img>
        
        {/* <CIcon
          className="c-sidebar-brand-minimized"
          name="sygnet"
          height={35}
        /> */}
      </CSidebarBrand>
      <CSidebarNav>

        <CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none"/>
    </CSidebar>
  )
}

export default React.memo(TheSidebar)
