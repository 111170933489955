import React from 'react'
import { CFooter } from '@coreui/react'
import { useTranslation } from 'react-i18next'

const TheFooter = () => {
  const {t} = useTranslation();
  return (
    <CFooter fixed={true}>
      <div>
        <a href={window.location.origin} target="_blank" rel="noopener noreferrer">{t('Qalat')}</a>
        <span className="ml-1">&copy; {t('2021, Netherland')}</span>
      </div>
      <div className="mfs-auto">
        <span className="mr-1">{t('All rights reserved')} </span>
        <a href="https://media.sfjn.org/" target="_blank" rel="noopener noreferrer">&copy; SFJN</a>
      </div>
    </CFooter>
  )
}

export default React.memo(TheFooter)
