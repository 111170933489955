import { createStore } from "redux";
const initialState = {
  sidebarShow: "responsive",
  lang: localStorage.getItem("lang") || "ar",
  token: null,
  tokenExpiresIn: 0,
  networkOk: true,
  userRole:'',
  requestId:null,
  areaOfExpertises:[]
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, 
        ...rest };

    case "NETWORK_ERROR":
      return { 
        ...state,
         ...rest,
         networkOk: false };

    case "NETWORK_OK":
      return { 
        ...state,
         ...rest, 
         networkOk: true };

    case "CHANGE_LANG":
      // console.log("type", type);
      // console.log("rest", rest);
      if (rest.lang === "en") {
        document.getElementsByTagName("html")[0].removeAttribute("dir");
      } else if (rest.lang === "ar") {
        document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      }
      localStorage.setItem("lang", rest.lang);
      if (rest.i18n) rest.i18n.changeLanguage(rest.lang);
      return {
        ...state,
        ...rest,
      };

    case "LOGIN":
      localStorage.setItem("token", rest.token);
      localStorage.setItem("tokenExpiresIn", rest.tokenExpiresIn);
      localStorage.setItem(
        "tokenExpirationDate",
        new Date(new Date().getTime() + rest.tokenExpiresIn * 1000).getTime()
      );

      return {
        ...state,
        ...rest,
        token: rest.token,
        tokenExpiresIn: rest.tokenExpiresIn,
        userRole: rest.userRole
      };
    case "LOGOUT":
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiresIn");
      localStorage.removeItem("tokenExpirationDate");

      return {
        ...state,
        ...rest,
        token: null,
        tokenExpiresIn: 0,
      };
      case "SET_ROLE":
        return {
          ...state,
          ...rest,
          userRole: rest.userRole
        };
     case "STORE_AOE":
        return {
          ...state,
          ...rest,
          areaOfExpertises: rest.areaOfExpertises
        }; 
        case "CHECK_AUTH":
      const expirationTime = localStorage.getItem("tokenExpirationDate");
      if (expirationTime < new Date().getTime()) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiresIn");
        localStorage.removeItem("tokenExpirationDate");
        return {
          ...state,
          ...rest,
          token: null,
          tokenExpiresIn: 0,
        };
      }
      return {
        ...state,
        ...rest,
        token: localStorage.getItem("token"),
        tokenExpiresIn: +localStorage.getItem("tokenExpiresIn"),
      };
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
