import React, { useEffect, useState } from "react";
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CInput,
  CInputCheckbox,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLabel,
  CLink,
  CRow,
  CToggler,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import { TheFooter } from "src/containers";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail } from "src/utils";
import { useMutation } from "react-query";
import axios from "../../../axios";
import CV from "./CV/CV";
const CVRequest = (props) => {
  const { t, i18n } = useTranslation("translation");
  const dispatch = useDispatch();
  const [getCodeVisible, setGetCodeVisible] = useState(0);
  const [getCodeVisibleContinue, setGetCodeVisibleContinue] = useState(0);
  const [requesterEmail, setRequesterEmail] = useState("");
  const [requesterEmailValid, setRequesterEmailValid] = useState(null);
  const [startBuildingSuccess, setStartBuildingSuccess] = useState(null);
  const [continueBuildingSuccess, setContinueBuildingSuccess] = useState(null);

  const [arCVId, setArCVId] = useState(null);
  const [enCVId, setEnCVId] = useState(null);
  const [arCV, setArCV] = useState(null);
  const [enCV, setEnCV] = useState(null);
 
  const [selectedLang, setSelectedLang] = useState(null);
  const [selectedLangAr, setSelectedLangAr] = useState(false);
  const [selectedLangEn, setSelectedLangEn] = useState(false);
  const [requestCode, setRequestCode] = useState(null);
  const [cvRequestId, setCVRequestId] = useState(-1);
  const [cvSubmitted, setCVSubmitted] = useState(false);

  const [agreePolicy, setAgreePolicy] = useState(false);

  const [requestCodeEntry, setRequestCodeEntry] = useState("");
  const [continueStatus, setContinueStatus] = useState(false);
  // setArCVId(res.data.arCVId);
  //       setEnCVId(res.data.enCVId);
  //       setRequestCode
  const [startBuildingCVError, setStartBuildingCVError] = useState(null);
  const [continueBuildingCVError, setContinueBuildingCVError] = useState(null);
  const [alreadyExistsEmail, setAlreadyExistsEmail] = useState(false);
  const [alreadyExistsEmailAndSubmitted, setAlreadyExistsEmailAndSubmitted] = useState(false);
  const [resetEmailSuccess, setResetEmailSuccess] = useState(false);
  useEffect(() => {

    const lang =  localStorage.getItem('lang');
    dispatch({ type: "CHANGE_LANG", lang: lang ? lang : 'en', i18n: i18n });

    return () => {};
  }, []);
  const resendRequestCodeHandler = async (event) => {

    event.preventDefault();
    try {
      await resendCodeMutation({
        requesterEmail: requesterEmail,
      });
    } catch (err) {
      setStartBuildingCVError(err);
    }

  };
  const requesterEmailHandler = (event) => {
    setRequesterEmail(event.target.value);

    setRequesterEmailValid(validateEmail(event.target.value));
  };
  const [error, setError] = useState(null);
  const submitCVRequest = async () => {
    setError(null);
    setCVSubmitted(false);
    try {
      const res = await axios.patch(`/cv-requests/${cvRequestId}/submit`);

      if (res.error) setError(res.error.response.data.error.message);
      else if (!res) {
        setError("No response");
      } else {
        setCVSubmitted(true);
        setArCVId(null);
        setEnCVId(null);
        setRequestCode("");
        setRequestCodeEntry("");
        return res;
      }
    } catch (error) {
      console.log("Custom Error", error);
    }
  };

  const { mutate } = useMutation(submitCVRequest);

  const submitCVRequestHander = () => {
    try {
      mutate();
    } catch (error) {
      console.log("Custom Error", error);
    }
  };
  const requestCodeEntryHandler = (event) => {
    setRequestCodeEntry(event.target.value);

    //setRequesterEmailValid(validateEmail(event.target.value));
  };
  const agreePolicyHandler = (event) => {
    setAgreePolicy(event.target.checked);
  };
  const selectLangHander = () => {
    if (selectedLangEn) {
      setSelectedLang("en");
      dispatch({ type: "CHANGE_LANG", lang: "en" });
      i18n.changeLanguage("en");
    }
    if (selectedLangAr) {
      setSelectedLang("ar");
      dispatch({ type: "CHANGE_LANG", lang: "ar" });
      i18n.changeLanguage("ar");
    }
  };
  const startBuildingCV = async (allData) => {
    setArCVId(null);
    setEnCVId(null);
    setCVSubmitted(false);
    setContinueStatus(false);

    setGetCodeVisibleContinue(0);
    setGetCodeVisible(0);
    setStartBuildingCVError(null);
    setContinueBuildingCVError(null);
    setStartBuildingSuccess(false);
    setAlreadyExistsEmail(false)
    setAlreadyExistsEmailAndSubmitted(false)
    try {
      

      const res = await axios.post("/cv-requests", allData);
      console.log("res",res);
      if (res.error){
        setStartBuildingCVError(res.error.response.data.error.message);
        if (res.error.response.data.error.message === "Email is already used for building CV as draft")
        {
            setAlreadyExistsEmail(true);
        }
        if (res.error.response.data.error.message === "Email is already used for building CV")
        {
            setAlreadyExistsEmailAndSubmitted(true);
        }
      }
      else if (!res) setStartBuildingCVError("No response");
      else {
        console.log("cv-requests", res.data);
        setArCVId(res.data.arCVId);
        setEnCVId(res.data.enCVId);
        setCVRequestId(res.data.cvRequestId);
        setRequestCode(res.data.requestCode);

        // show the success message for 10 second
        setGetCodeVisible(30);
        setStartBuildingSuccess(true);

        return res.data;
      }
    } catch (error) {
      console.log("Custom Error", error);
      setStartBuildingCVError(error);
    } finally {
      // setRequesterEmail("");
    }
  };
  const resendCode = async (allData) => {
   
    try {
     
      const res = await axios.post("/cv-requests-resend-code", allData);
      if (res.error){
        setStartBuildingCVError(res.error.response.data.error.message);
       
      }
      else if (!res) setStartBuildingCVError("No response");
      else {
       
        setResetEmailSuccess(true);
        setStartBuildingCVError(false)

        return res.data;
      }
    } catch (error) {
      console.log("Custom Error", error);
      setStartBuildingCVError(error);
    } finally {
      // setRequesterEmail("");
    }
  };

  const { mutate: mutateStartBuildingCV, status } = useMutation(
    startBuildingCV
  );
  const startBuildingCVHandler = async (event) => {
    event.preventDefault();
    try {
      await mutateStartBuildingCV({
        requesterEmail: requesterEmail,
      });
    } catch (err) {
      setStartBuildingCVError(err);
    }
  };

  const { mutate: resendCodeMutation } = useMutation(
    resendCode
  );
 

  const continueBuildingCV = async () => {
    setArCVId(null);
    setEnCVId(null);
    setCVSubmitted(false);
    setContinueStatus(false);

    setGetCodeVisibleContinue(0);
    setGetCodeVisible(0);
    setStartBuildingCVError(null);
    setContinueBuildingCVError(null);
    setResetEmailSuccess(false)
    setContinueBuildingSuccess(false);
    setAlreadyExistsEmail(false)
    setAlreadyExistsEmailAndSubmitted(false)
    try {
      console.log("sending ");

      const res = await axios.post("/cv-requests/" + requestCodeEntry);
      if (res.error)
        setContinueBuildingCVError(res.error.response.data.error.message);
      else if (!res) setContinueBuildingCVError("No resposne");
      else {
        console.log("cv-requests", res.data);
        setArCVId(res.data.arCVId);
        setEnCVId(res.data.enCVId);
        setCVRequestId(res.data.cvRequestId);
        // show the success message for 10 second
        setGetCodeVisibleContinue(30);
        setContinueStatus(true);

        return res.data;
      }
    } catch (error) {
      console.log("Custom Error", error);
      setContinueBuildingCVError(error);
    } finally {
      setRequesterEmail("");
    }
  };

  const { mutate: mutateContinueBuildingCV } = useMutation(continueBuildingCV);
  const continueBuildingCVHandler = async (event) => {
    event.preventDefault();
    try {
      await mutateContinueBuildingCV();
    } catch (err) {
      setContinueBuildingCVError(err);
    }
  };

  const [currentCV, setCurrentCV] = useState("");
  const [otherCV, setOtherCV] = useState("");
  const setCV = (CV,otherCV) => {
    setCurrentCV(CV);    
    setOtherCV(otherCV);
    
  };
  const validToSubmitCV = () => {

    if (selectedLangEn && selectedLangAr)
    {
      return (
        currentCV &&
        currentCV.personalInfo &&
        currentCV.personalInfo.fullName &&
        // currentCV.about &&
        // currentCV.about.bio &&
        currentCV.experiences &&
        currentCV.experiences.length > 0 &&    
        otherCV &&
        otherCV.personalInfo &&
        otherCV.personalInfo.fullName &&
        // otherCV.about &&
        // otherCV.about.bio &&
        otherCV.experiences && otherCV.experiences.length > 0 &&     
        agreePolicy
      );
    }
    return (
      currentCV &&
      currentCV.personalInfo &&
      currentCV.personalInfo.fullName &&
      // currentCV.about &&
      // currentCV.about.bio &&
      currentCV.experiences &&
      currentCV.experiences.length > 0 &&     
      agreePolicy
    );
    // {currentCV && !currentCV.personalInfo.fullName && (
    //   <ul>Personal information section is required</ul>
    // )}
    //  {currentCV && !currentCV.about.bio && (
    //   <ul>About section is required</ul>
    // )}
    //  {(currentCV && currentCV.experiences &&  currentCV.experiences.length === 0) && (
    //   <ul>At least 1 experience entry is required</ul>
    // )}
  };
  const lang = useSelector((state) => state.lang);

  const changeLanguage = () => {
    dispatch({ type: "CHANGE_LANG", lang: lang === "ar" ? "en" : "ar" });
    i18n.changeLanguage(lang === "ar" ? "en" : "ar");
  };
  
  return (
    <>
      <CContainer>
        <CRow className="justify-content-center">
          <CCol xl="12" className="text-center">
            <img
              src="avatars/logo2.png"
              alt="qalat"
              style={{
                width: "25%",
                marginBottom: "10px"
              }}
            ></img>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12" lg="12" xl="12">
            <CCard>
              <CCardBody>
                <h1 className="text-center">{t("Submit Expert CV")}</h1>
                <div className="d-flex justify-content-end">
                  <p className="mr-auto p-2 col-sm-12">
                    {t("SubmitExpertCV.Intro")} <CLink target="_blank"  href="https://qalat.org/disclaimer/">
                          {t("SubmitExpertCV.disclaimer")}
                          </CLink>
                  </p>
                </div>
                <div className="d-flex justify-content-end">
                    <CToggler
                      inHeader
                      className="ms-1 p-2"
                      onClick={changeLanguage}
                    >
                      {
                        // lang === 'EN' ? <CIcon content={flagSet.cifGb} size="xl" /> :
                        // <CIcon content={flagSet.cifSa} size="xl" />
                        lang === "en" ? "العربية" : "English"
                      }
                    </CToggler>
                  </div>
                <h3>{t("")}</h3>
                <p className="mr-auto p-2 col-sm-12"></p>
                {arCVId && enCVId && selectedLang ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    {cvSubmitted && (
                      <CAlert color="success">
                        {t("SubmitExpertCV.submitted")}
                      </CAlert>
                    )}
                    {!cvSubmitted && (
                      <CAlert color="info">
                        {t("SubmitExpertCV.options")}
                        <br />
                        {t("SubmitExpertCV.option1")}
                        <br />
                        {t("SubmitExpertCV.option2")}
                        <br />
                        {t("SubmitExpertCV.option3")}
                      </CAlert>
                    )}
                    {!cvSubmitted && (
                      <CForm
                      // onSubmit={handleSubmit}
                      >
                        {continueStatus === false && (
                          <CFormGroup row>
                            <CCol xs="12" md="3">
                              <CInput
                                type="email"
                                id="email-input"
                                name="requesterEmail"
                                value={requesterEmail}
                                onChange={requesterEmailHandler}
                                onFocus={requesterEmailHandler}
                                placeholder="sample@domain.com"
                                autoComplete="email"
                                disabled={alreadyExistsEmail}
                              />
                              <p className="help-block">
                                {t("Enter your email to start building a new CV")}
                              </p>
                            </CCol>
                            <CCol xs="12" md="3">
                              <CButton
                                type="submit"
                                color="success"
                                disabled={
                                  !(requesterEmail && requesterEmailValid)
                                }
                                onClick={startBuildingCVHandler}
                                block
                              >
                                {t("Start Building New CV")}
                              </CButton>
                              {status === "loading" && (
                                <div className="text-center">
                                  <div
                                    className="spinner-border text-primary "
                                    role="status"
                                  >
                                    <span className="sr-only"></span>
                                  </div>
                                </div>
                              )}
                            </CCol>
                          </CFormGroup>
                        )}
                        <CFormGroup row>
                          <CCol xs="12" md="12">
                            {status === "success" && (
                              <CAlert
                                color="success"
                                show={getCodeVisible}
                                closeButton
                                onShowChange={setGetCodeVisible}
                                onClick={(event) => event.preventDefault()}
                              >
                                {t("This code")} (<strong> {requestCode} </strong>{t(") has been sent to your email (")}
                                <strong> {requesterEmail} </strong>{t("). You can start building your CV now and using the code you can continue later")}
                                <br />
                                {t("This section will be closed in")}{" "}
                                <strong> {getCodeVisible} </strong> {t("seconds, you can build your CV in below section")}
                              </CAlert>
                            )}
                            {startBuildingCVError && (
                              <CAlert
                                color="danger"
                                closeButton
                                onClick={(event) => event.preventDefault()}
                              >
                                {alreadyExistsEmail &&
                                <div>{t("Email is already used for building CV, click")} <CLink onClick={resendRequestCodeHandler}>{t("resend code")}</CLink> {t("to get an email with your request code to continue building CV below")}</div>                                
                                }
                               {alreadyExistsEmailAndSubmitted &&
                                <div>{t("Email is already used for building CV")}</div>                                
                               } 
                               </CAlert>
                              
                            )}
                            {resetEmailSuccess && (
                              <CAlert
                                color="success"
                                closeButton
                                onClick={(event) => event.preventDefault()}
                              >
                                <div>{t("Code has been sent to your email, please get the code and continue below")}</div>
                                                            
                              </CAlert>
                            )}
                            
                          </CCol>
                        </CFormGroup>
                        {(startBuildingSuccess === null ||
                          startBuildingSuccess === false) && (
                          <CFormGroup row>
                            <CCol xs="12" md="3">
                              <CInput
                                type="text"
                                id="email-input"
                                name="requesterCondeEntry"
                                value={requestCodeEntry}
                                onChange={requestCodeEntryHandler}
                                onFocus={requestCodeEntryHandler}
                                placeholder="####-###########-########"
                              />
                              <p className="help-block">
                                {t("Enter your code to continue")}
                              </p>
                            </CCol>
                            <CCol xs="12" md="3">
                              <CButton
                                type="submit"
                                color="success"
                                disabled={!requestCodeEntry}
                                onClick={continueBuildingCVHandler}
                                block
                              >
                                {t("Continue Building CV")}
                              </CButton>
                              <span className="help-block"> </span>
                            </CCol>
                            <CCol xs="12" md="3"></CCol>
                          </CFormGroup>
                        )}
                        <CFormGroup row>
                          <CCol xs="12" md="12">
                            {continueStatus && (
                              <CAlert
                                color="success"
                                show={getCodeVisibleContinue}
                                closeButton
                                onShowChange={setGetCodeVisibleContinue}
                                onClick={(event) => event.preventDefault()}
                              >
                                {t("Code Found")}
                                <br />
                                {t("This section will be closed in")} {" "}
                                <strong> {getCodeVisibleContinue} </strong>{" "}
                                {t("seconds, you can continue building your CV in below section")}
                              </CAlert>
                            )}

                            {continueBuildingCVError && (
                              <CAlert
                                color="danger"
                                closeButton
                                onClick={(event) => event.preventDefault()}
                              >
                                {t(continueBuildingCVError)}
                              </CAlert>
                            )}
                          </CCol>
                        </CFormGroup>

                        {arCVId && enCVId && (
                          <CFormGroup row>
                            <CCol xs="12" md="3">
                              <CAlert color="info">
                                {t("Which Language do you want to continue building CV?")}
                                <br />
                                {t("You will have the chance to translate your CV in the other language.")}
                              </CAlert>
                            </CCol>
                            <CCol xs="12" md="3">
                              <CRow>
                                <CCol xs="3" md="3">
                                  <CFormGroup variant="custom-checkbox" inline>
                                    <CInputCheckbox
                                      custom
                                      id="inline-checkbox1"
                                      name="inline-checkbox1"
                                      value="en"
                                      onChange={(event) => {
                                        console.log(event.target.checked);
                                        setSelectedLangEn(event.target.checked);
                                      }}
                                    />
                                    <CLabel
                                      variant="custom-checkbox"
                                      htmlFor="inline-checkbox1"
                                    >
                                      {" "}
                                      {t("English")}
                                    </CLabel>
                                  </CFormGroup>
                                </CCol>
                                <CCol xs="6" md="4"></CCol>
                                <CCol
                                  xs="3"
                                  md="3"
                                  style={{ textAlign: "end" }}
                                >
                                  <CFormGroup variant="custom-checkbox" inline>
                                    <CInputCheckbox
                                      custom
                                      id="inline-checkbox2"
                                      name="inline-checkbox2"
                                      value="ar"
                                      onChange={(event) => {
                                        console.log(event.target.checked);
                                        setSelectedLangAr(event.target.checked);
                                      }}
                                    />
                                    <CLabel
                                      variant="custom-checkbox"
                                      htmlFor="inline-checkbox2"
                                    >
                                      {" "}
                                      {t("العربية")}
                                    </CLabel>
                                  </CFormGroup>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol md="12"></CCol>
                              </CRow>
                              <CRow style={{ marginTop: "20px" }}>
                                <CCol md="12">
                                  <CButton
                                    color="success"
                                    block
                                    disabled={
                                      !(selectedLangAr || selectedLangEn)
                                    }
                                    onClick={() => selectLangHander()}
                                  >
                                    {t("Conrinue")}
                                  </CButton>
                                </CCol>
                              </CRow>
                            </CCol>
                          </CFormGroup>
                        )}
                      </CForm>
                    )}{" "}
                  </>
                )}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow className="justify-content-center">
          <CCol xl="12" className="text-center">
            {arCVId && enCVId && selectedLang && (
              <CV
                selectedLang={selectedLang}
                enCVId={enCVId}
                arCVId={arCVId}
                setCV={setCV}
                showContact={true}
              ></CV>
            )}
          </CCol>
        </CRow>

        {arCVId && enCVId && selectedLang && (
          <CCard>
            <CCardBody>
              <CRow style={{ marginBottom: "15px" }}>
                <CCol md="12">
                  { selectedLangEn && selectedLangAr &&
                    <CButton color="success" onClick={changeLanguage}>
                    {t("Add translation in Arabic")}
                  </CButton>}
                </CCol>
              </CRow>
              <CRow>
                <CCol md="12">
                  <CFormGroup variant="custom-checkbox">
                    <CInputCheckbox
                      custom
                      id="inline-checkbox1"
                      name="inline-checkbox1"
                      onChange={agreePolicyHandler}
                      checked={agreePolicy}
                    />
                    <CLabel
                      variant="custom-checkbox"
                      htmlFor="inline-checkbox1"
                    >
                      {t("I have read and agree with")}{" "}
                      <CLink target="_blank" href="https://qalat.org/general-terms-and-conditions/">
                        {t("the general terms and conditions")}
                      </CLink> {t("and")} <CLink target="_blank" href="https://qalat.org/privacy-statement/">
                        {t("privacy statement")}
                      </CLink>
                    </CLabel>
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow className="addTopBottomMargin">
                <CCol md="3">
                  <CButton
                    type="submit"
                    color="success"
                    block
                    onClick={submitCVRequestHander}
                    disabled={!validToSubmitCV()}
                  >
                    {t("Submit CV request")}
                  </CButton>
                </CCol>
              </CRow>
              <CRow className="addTopBottomMargin">
                <CCol md="12">
                  {!validToSubmitCV() && (
                    <p className="alert alert-info">
                      <ui>
                        {t("The following conditions should be met in order to submit your CV request")}
                        {currentCV && !currentCV.personalInfo.fullName && (
                          <li>{t("Personal information section is required")}</li>
                        )}
                        {/* {currentCV && !currentCV.about.bio && (
                          <li>{t("About section is required")}</li>
                        )} */}
                        {((currentCV && !currentCV.experiences) ||
                          (currentCV &&
                            currentCV.experiences &&
                            currentCV.experiences.length === 0)) && (
                          <li>{t("At least 1 experience entry is required")}</li>
                        )}

                       
                        {selectedLangEn && selectedLangAr &&otherCV && !otherCV.personalInfo.fullName && (
                          <li>{t('Personal information section is required in Arabic')}</li>
                        )}
                        {/* {selectedLangEn && selectedLangAr &&otherCV && !otherCV.about.bio && (
                          <li>{t('About section is required in Arabic')}</li>
                        )} */}
                        {((selectedLangEn && selectedLangAr && otherCV && !otherCV.experiences) ||
                          (selectedLangEn && selectedLangAr && otherCV &&
                            otherCV.experiences &&
                            otherCV.experiences.length === 0)) && (
                          <li>{t("At least 1 experience entry is required in Arabic")}</li>
                        )}
                        {!agreePolicy && (
                          <li>{t("Read and agreed with policy is required")}</li>
                        )}
                      </ui>
                    </p>
                  )}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        )}
      </CContainer>
      <TheFooter />
    </>
  );
};

export default CVRequest;
