import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CDataTable,
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSwitch,
  CTextarea,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { formatDateNoTime, formatDate } from "../../../../../utils";

import axios from "../../../../../axios";
const PublishedWorksViewCompact = (props) => {
  const [publishedWorksList, setPublishedWorksList] = useState(
    props.publishedWorksList
  );
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletePublishedWorks, setDeletePublishedWorks] = useState(false);
  const [error, setError] = useState(null);

  const { t,i18n } = useTranslation();

  useEffect(() => {
    setPublishedWorksList(props.publishedWorksList);

    return () => {};
  }, [props.publishedWorksList]);
  const deletePublishedWorksAction = async (id) => {
    setError(null);

    try {
      console.log("sending to API");

      let res = null;

      res = await axios.delete(`/published-works/${id}`);

      if (res.error) setError(res.error.response.data.error.message);

      if (res === undefined) throw new Error("No result returned");

      setDeleteModal(false);
      setDeletePublishedWorks(null);
      return res.data;
    } catch (err) {
      setError("unexpected Error: " + err);
    } finally {
      props.refreshCVs();
    }
  };

  const { mutate: mutateDelete } = useMutation(deletePublishedWorksAction);

  const publishedWorksDeleteHandler = (id) => {
    setDeleteModal(true);
    const publishedWorks = publishedWorksList.find((item) => item.id === id);
    setDeletePublishedWorks(publishedWorks);
  };
  const publishedWorksDelete = (event) => {
    //setDeleteModal(true)
    event.preventDefault();
    try {
      mutateDelete(deletePublishedWorks.id);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {deletePublishedWorks && (
        <CModal
          show={deleteModal}
          closeOnBackdrop={false}
          onClose={setDeleteModal}
        >
          <CModalHeader closeButton className="modal--header-color">
            <CModalTitle>{t("Published Content")} </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CRow className="cv-section-card">
              <CCol xs="12" md="12">
                <CFormGroup row>
                  <CCol xs="12" md="6">
                    <CLabel htmlFor="text-input">
                      {t("Title")}<span className="text-danger"> *</span>
                    </CLabel>
                    <CInput
                      disabled
                      placeholder={t("Title")}
                      value={deletePublishedWorks.title}
                    />
                    <CFormText></CFormText>
                  </CCol>
                  <CCol xs="12" md="6">
                    <CLabel htmlFor="text-input">
                      {t("Type")}<span className="text-danger"> *</span>
                    </CLabel>
                    <CInput
                      disabled
                      placeholder={t("Type")}
                      value={deletePublishedWorks.publishedWorkType[i18n.language === "ar" ? "nameAr" :"nameEn"]}
                    />
                    <CFormText></CFormText>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol xs="12" md="6">
                    <CLabel htmlFor="text-input">
                      {t("Date of Publication")}
                    </CLabel>
                    <CInput
                      disabled
                      placeholder={t("Date of Publication")}
                      value={formatDateNoTime(deletePublishedWorks.publishDate)}
                    />
                    <CFormText></CFormText>
                  </CCol>
                  <CCol xs="12" md="6">
                    <CLabel htmlFor="text-input">
                      {t("URL")}
                      {/* <span className="text-danger"> </span> */}
                    </CLabel>
                    <CInput
                      disabled
                      placeholder={t("URL")}
                      value={deletePublishedWorks.url}
                    />
                    <CFormText></CFormText>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol xs="12" md="6">
                    <CLabel htmlFor="text-input">
                      {t("Description")}
                      {/* <span className="text-danger"> </span> */}
                    </CLabel>
                    <CTextarea
                      disabled
                      placeholder= {t("Description")}
                      value={deletePublishedWorks.description}
                      rows="6"
                    />
                    <CFormText></CFormText>
                  </CCol>
                  <CCol xs="12" md="6">
                    <CLabel htmlFor="text-input">
                      {t("Language")}
                      {/* <span className="text-danger"> </span> */}
                    </CLabel>
                    <CInput
                      disabled
                      placeholder={t("Language")}
                      value={i18n.language === 'ar' ? deletePublishedWorks.language.nameAr :
                        deletePublishedWorks.language.nameEn
                      }
                    />
                    <CFormText></CFormText>
                  </CCol>
                </CFormGroup>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            {error && <CAlert color="danger">{error}</CAlert>}
            <CButton color="danger" onClick={publishedWorksDelete}>
              {t("Delete")}
            </CButton>{" "}
            <CButton color="secondary" onClick={() => setDeleteModal(false)}>
              {t("Cancel")}
            </CButton>
          </CModalFooter>
        </CModal>
      )}
      <CDataTable
        items={publishedWorksList}
        fields={[
          { key: "title", label: t("Title") },
          { key: "type", label: t("Type") },
          { key: "publishDate", label: t("Date of Publication") },
          { key: "url", label: t("URL") },
          { key: "description", label: t("Description") },
          { key: "actions", label: t("Actions"), _classes: `${props.viewOnly && "hidden"}` },
        ]}
        hover
        striped
        bordered
        size="sm"
        //  onRowClick={(item) => history.push(`/users/${item.id}`)}
        scopedSlots={{
          publishDate: (item) => (
            <td> {formatDateNoTime(item.publishDate)} </td>
          ),
          type: (item) => (
            <td> {item.publishedWorkType.nameEn} </td>
          ),
          actions: (item) => (
            <td className={`actionsAlignEnd ${props.viewOnly && "hidden"}`}>
            <CButton
                color="success"
                onClick={() => props.publishedWorksEditHandler(item.id)}
              >
                {t("Edit")}
              </CButton>{" "}
              <CButton
                color="danger"
                onClick={() => publishedWorksDeleteHandler(item.id)}
              >
                {t("Delete")}
              </CButton>{" "}
            </td>
          ),
        }}
      />
    </>
  );
};

export default PublishedWorksViewCompact;
