import axios from 'axios';
import store from './store/store'



console.log('process.env.REACT_APP_API_BASE',process.env.REACT_APP_API_BASE)

const instance = axios.create(
    {
        baseURL: process.env.REACT_APP_API_BASE
    }
);

// axios.interceptors.request.use(
//   function(successfulReq) {
//     console.log('API request successfulReq',successfulReq)
   
//     return successfulReq;
//   }, 
//   function(error) {
//     console.log('API request error',error)
   
//     return error;
//   }
// );


// instance.interceptors.response.use((response) => {
//   return response;
// }, (error) => {
//   return Promise.resolve({ error });
// });

instance.interceptors.response.use((response) => {
    console.log('response succcess')
    store.dispatch({type:"NETWORK_OK"})
    return response;
  }, (error) => {


    console.log('API response error',error.message)
 
   if (!error.response)
   {
     console.log('set network error')
      store.dispatch({type:"NETWORK_ERROR"})
    }
    else
    {
      store.dispatch({type:"NETWORK_OK"})
    }

   if (error.response.status === 401)
      store.dispatch({type:"LOGOUT"})
   
    return Promise.resolve({ error });
  });

instance.defaults.headers.common = {
    ...instance.defaults.headers.common,
    
    "Content-Type": 'application/json',
    
 };
 instance.defaults.preflightContinue = true;
 
 
export default instance;