import { CCol, CLabel, CRow, CSwitch } from "@coreui/react";
import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import axios from "../../../../../axios";
const AreaOdExpertise = (props) => {
  const { t, i18n } = useTranslation();
  const areaOfExpertises = useSelector((state) => state.areaOfExpertises);
  const [cvExpertises, setCvExpertises] = useState(props.cvExpertises);
  const [cvId, setCvId] = useState(props.cvId);
  const [Error, setError] = useState(null);

  useEffect(() => {
     console.log(
          "props.cvExpertises",
          props.cvExpertises
        );
    setCvExpertises(props.cvExpertises)
    setCvId(props.cvId)
    
  }, [props.cvExpertises,props.cvId,cvExpertises])
  
  const editCvExpertise = async (allData) => {
    try {
      setError(null);

      const res = await axios.post("/cv-expertises/", allData);
      if (res.error) {
        // setStartBuildingCVError(res.error.response.data.error.message);
        console.log(
          "res.error.response.data.error.message",
          res.error.response.data.error.message
        );

        setError(res.error.response.data.error.message);
      } else if (!res) {
        setError("No response from API");
      } else {
        return res.data;
      }
    } catch (error) {
      console.log("Custom Error", error);
    } finally {
      props.refreshCVs();
    }
  };

  const { mutate, status } = useMutation(editCvExpertise);
  return (
    <>
      <CRow>
        <CCol xs="12" md="12">
          <div className="cv-section-header col-md-12">
            <h5>{t("Area of Expertises")}</h5>
          </div>
        </CCol>
      </CRow>
      <CRow>
        {areaOfExpertises &&
          areaOfExpertises.map((item, index) => (
            <CCol xs="12" md="6" lg="4" key={index}>
              <div className="d-flex justify-content-start my-2">
                <CSwitch
                  disabled={props.viewOnly}
                  className={"mx-1"}
                  variant={"3d"}
                  color={"danger"}
                  onChange={(event) => {
                    console.log(
                      "changed",
                      item.id,
                      event.target.checked,
                      "props.cvId",
                      props.cvId
                    );
                    const allData = {
                      cvId: cvId,
                      areaOfExpertiseId: item.id,
                    };
                    mutate(allData);
                  }}
                  defaultChecked={cvExpertises === undefined ? false : cvExpertises.find(i=> i.areaOfExpertiseId === item.id) }
                />
                {i18n.language === "ar" ? item.nameAr : item.nameEn}
              </div>
            </CCol>
          ))}
      </CRow>
    </>
  );
};

export default AreaOdExpertise;
