import { CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router'
import { Redirect } from 'react-router-dom';
import {
  TheContent,
  TheSidebar,
  TheFooter,
  TheHeader
} from './index'

const TheLayout = (props) => {

  const history = useHistory();
 
  console.log('layout props, history',props,history)
  

  return (
    <div className="c-app c-default-layout">
      
      <TheSidebar/>
      <div className="c-wrapper">
        <TheHeader/>
        <div className="c-body">
          <TheContent {...props}/>
         
        </div>
        <TheFooter/>
       </div>
    </div>
  )
}

export default TheLayout
