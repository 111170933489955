import CIcon from "@coreui/icons-react";
import {
  CAlert,
  CButton,
  CCol,
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTextarea,
  CSelect,
  CSwitch,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import CountryList from "../../../CountryList/CountryList";

import axios from "../../../../../axios";
import {
  formatDateForPicker,
  formatDate,
  validateEmail,
  validateHTTP,
  validatePhoneNumber,
} from "src/utils";
import { cilCalendarCheck, cilDelete, cilPlus } from "@coreui/icons";

import PublishedWorksViewCompact from "./PublishedWorksViewCompact";
import PublishedWorksView from "./PublishedWorksView";
import PublishedWorkTypesOption from "src/views/pages/PublishedWorkTypesOption/PublishedWorkTypesOption";
import LanguagesOption from "src/views/pages/LanguagesOption/LanguagesOption";
import { useTranslation } from "react-i18next";

const PublishedWorks = (props) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [compactView, setCompactView] = useState(false);

  const [publishedWorksList, setPublishedWorksList] = useState(
    props.publishedWorks ? props.publishedWorks : []
  );

  const [title, setTitle] = useState("");

  const [publishedWorkTypeId, setPublishedWorkTypeId] = useState(0);
  const [url, setURL] = useState("");
  const [description, setDescription] = useState("");
  const [publishDate, setPublishDate] = useState("");
  const [languageId, setLanguageId] = useState(0);

  const [editMode, setEditMode] = useState(false);
  const [editPublishedWorksId, setEditPublishedWorksId] = useState(0);
  // const [phone, setPhone] = useState(currentPersonalInfo.phone);
  // const [title, setTitle] = useState(currentPersonalInfo.currentTitle);
  const [error, setError] = useState(null);

  useEffect(() => {
    setPublishedWorksList(props.publishedWorks ? props.publishedWorks : []);

    if (editPublishedWorksId > 0) {
      const publishedWorks = props.publishedWorks.find(
        (item) => item.id === editPublishedWorksId
      );

      console.log("selected publishedWorks ", publishedWorks);
      setTitle(publishedWorks.title);
      setDescription(publishedWorks.description);
      setURL(publishedWorks.url);
      setPublishDate(formatDateForPicker(publishedWorks.publishDate));
      setLanguageId(publishedWorks.languageId);
      setPublishedWorkTypeId(publishedWorks.publishedWorkTypeId);
    } else {
      setTitle("");
      setDescription("");
      setURL("");
      setPublishDate("");
      setLanguageId(0);
      setPublishedWorkTypeId(0);
    }
    return () => {};
  }, [props.publishedWorks, editPublishedWorksId]);

  const isValidForm = () => {
    return (
      title &&
      title.trim() !== "" &&
      publishedWorkTypeId > 0 &&
      (description ? description.length <= 2000 : true)
      // description &&
      // description.trim() !== "" &&
      // publishDate &&
      // languageId > 0 &&
      // description.length <= 2000
    );
  };
  // console.log("publishedWorksList", publishedWorksList);

  const addEditPublishedWorks = async (allData) => {
    try {
      setError(null);
      console.log(
        "addPublishedWorks",

        allData
      );
      let res = null;
      if (editPublishedWorksId === 0)
        // insert mode
        res = await axios.post(
          "/published-works/", //+ currentPersonalInfo.id,
          allData
        );
      // edit mode
      else
        res = await axios.patch(
          "/published-works/" + editPublishedWorksId,
          allData
        );

      if (res.error) {
        // setStartBuildingCVError(res.error.response.data.error.message);
        console.log("res.error.response.data.error.message", res);
        setError(res.error.response.data.error.details[0].message);
      } else if (res === undefined) {
        setError("No response from API");
      } else {
        console.log("updated personal info", res.data);

        setModal(false);
        setTitle("");
        setDescription("");
        setURL("");
        setPublishDate("");
        setLanguageId(0);
        setPublishedWorkTypeId(0);

        setEditPublishedWorksId(0);
        setError(null);
        return res.data;
      }
    } catch (error) {
      setError("Custom Error" + error);
    } finally {
      props.refreshCVs();
    }
  };

  const { mutate, status } = useMutation(addEditPublishedWorks);

  const publishedWorksAddEdit = (event) => {
    event.preventDefault();
    try {
      const allData = {
        title,
        description ,
        publishedWorkTypeId,
        url,
        publishDate: publishDate ? new Date(publishDate): null,
        languageId: languageId === 0 ? null : languageId,
        cvId: props.CVId,
      };

      console.log("PublishedWorks data", allData);
      mutate(allData);
    } catch (err) {
      setError("Unhandled Error" + err);
    }
  };

  const titleHandler = (event) => {
    setTitle(event.target.value);
  };

  const descriptionHandler = (event) => {
    setDescription(event.target.value);
  };
  const urlHandler = (event) => {
    setURL(event.target.value);
  };
  const publishedWorkTypeIdHandler = (event) => {
    setPublishedWorkTypeId(+event.target.value);
  };
  const languageIdHandler = (event) => {
    setLanguageId(+event.target.value);
  };

  const publishDateHandler = (event) => {
    setPublishDate(formatDateForPicker(event.target.value));
  };

  const publishedWorksEditHandler = (id) => {
    setEditMode(true);
    setEditPublishedWorksId(id);
    setModal(true);
  };
  const compacyViewHandler = (event) => {
    setCompactView(!compactView);
    console.log("compactView", compactView);
  };
  return (
    <>
      <CRow>
        <CCol xs="12" md="12">
          <div
            className="cv-section-header col-md-12"
            onClick={(event) => {
              if (props.viewOnly) return;

              setModal(false);
              setTitle("");
              setDescription("");
              setURL("");
              setPublishDate("");
              setLanguageId(0);
              setPublishedWorkTypeId(0);

              setEditPublishedWorksId(0);
              setError(null);
              setModal(true);
              setEditMode(false);
            }}
          >
            <h5>
              {t("Published Content")} -{" "}
              {!props.viewOnly && (
                <span>
                  {t("Click to add")}{" "}
                  <CIcon content={cilPlus} size="xl"></CIcon>{" "}
                </span>
              )}
            </h5>
          </div>
          <div style={{ textAlign: "end" }}>
            <span style={{ verticalAlign: "top" }}>{t("Compact View")} </span>
            <CSwitch
              color="primary"
              onChange={compacyViewHandler}
              checked={compactView}
            />
          </div>
        </CCol>
      </CRow>
      <CModal show={modal} closeOnBackdrop={false} onClose={setModal}>
        <CModalHeader closeButton className="modal--header-color">
          <CModalTitle>{t("Published Content")} </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CFormGroup row>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t("Title")}
                <span className="text-danger"> *</span>
              </CLabel>
              <CInput
                id="publishedWorks-title-input"
                name="publishedWorks-title-input"
                placeholder={t("Title")}
                value={title}
                onChange={titleHandler}
                onFocus={titleHandler}
              />
              <CFormText>
                {" "}
                <span
                  className={
                    title === "" || title.trim() === "" ? "text-danger" : ""
                  }
                >
                  {t("Required")}
                </span>
              </CFormText>
            </CCol>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t("Type")}
                <span className="text-danger"> *</span>
              </CLabel>
              <CSelect
                custom
                name="select"
                id="select"
                value={publishedWorkTypeId}
                onChange={publishedWorkTypeIdHandler}
                onFocus={publishedWorkTypeIdHandler}
              >
                <PublishedWorkTypesOption></PublishedWorkTypesOption>
              </CSelect>
              <CFormText>
                <span
                  className={publishedWorkTypeId === 0 ? "text-danger" : ""}
                >
                  {t("Required")}
                </span>
              </CFormText>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t("Date of Publication")}
                {/* <span className="text-danger"> *</span> */}
              </CLabel>
              <CInput
                id="publishDate-input"
                name="publishDate-input"
                type="date"
                value={publishDate}
                onChange={publishDateHandler}
                onFocus={publishDateHandler}
              />
              <CFormText>
                {/* <span className={!publishDate ? "text-danger" : ""}>
                  {t("Required")}
                </span> */}
              </CFormText>
            </CCol>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t("URL")}
                {/* <span className="text-danger"> *</span> */}
              </CLabel>
              <CInput
                id="publishedWorks-url-input"
                name="publishedWorks-url-input"
                placeholder={t("URL")}
                value={url}
                onChange={urlHandler}
                onFocus={urlHandler}
              />
              <CFormText></CFormText>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t("Description")}
                {/* <span className="text-danger"> *</span> */}
              </CLabel>
              <CTextarea
                id="publishedWorks-Description-input"
                name="publishedWorks-Description-input"
                placeholder={t("Briefly describe the material published")}
                value={description}
                onChange={descriptionHandler}
                onFocus={descriptionHandler}
                rows="6"
              />
              <CFormText>
                <span
                  className={
                    (description && description.length > 2000) 
                      ? "text-danger"
                      : "" 
                  }
                >
                  {t("Maximum 2000 characters")}
                </span>
              </CFormText>
            </CCol>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t("Language")}
                {/* <span className="text-danger"> *</span> */}
              </CLabel>
              <CSelect
                custom
                name="select-lang"
                id="select-lang"
                value={languageId}
                onChange={languageIdHandler}
                onFocus={languageIdHandler}
              >
                <option key={"lang0"} value="0">
                  {t("Select language")}
                </option>
                <LanguagesOption></LanguagesOption>
              </CSelect>
              <CFormText>
                {/* <span className={languageId === 0 ? "text-danger" : ""}>
                  {t("Required")}
                </span> */}
              </CFormText>
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          {error && <CAlert color="danger">{error}</CAlert>}
          <CButton
            color="success"
            disabled={!isValidForm()}
            onClick={publishedWorksAddEdit}
          >
            {editPublishedWorksId ? t("Edit") : t("Add")}
          </CButton>{" "}
          <CButton color="secondary" onClick={() => setModal(false)}>
            {t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>

      <CRow>
        <CCol xs="12" md="12">
          {publishedWorksList && compactView && (
            <PublishedWorksViewCompact
              publishedWorksEditHandler={publishedWorksEditHandler}
              publishedWorksList={publishedWorksList}
              refreshCVs={props.refreshCVs}
              viewOnly={props.viewOnly}
            />
          )}
          {publishedWorksList &&
            publishedWorksList.length > 0 &&
            !compactView && (
              <PublishedWorksView
                publishedWorksEditHandler={publishedWorksEditHandler}
                publishedWorksList={publishedWorksList}
                refreshCVs={props.refreshCVs}
                viewOnly={props.viewOnly}
              />
            )}
          {publishedWorksList.length === 0 && (
            <p>{t("No published Works added yet.")}</p>
          )}
        </CCol>
      </CRow>
    </>
  );
};

export default PublishedWorks;
