import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSwitch,
  CTextarea,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { formatDateNoTime } from "../../../../../utils";

import axios from "../../../../../axios";
const InterestsView = (props) => {
  const [interestsList, setInterestsList] = useState(props.interestsList);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteInterests, setDeleteInterests] = useState(false);
  const [error, setError] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    setInterestsList(props.interestsList);
    console.log("props.interestsList", props.interestsList);
    return () => {};
  }, [props.interestsList]);
  const deleteInterestsAction = async (id) => {
    setError(null);

    try {
      console.log("sending to API");

      let res = null;

      res = await axios.delete(`/interests/${id}`);

      if (res.error) setError(res.error.response.data.error.message);

      if (res === undefined) throw new Error("No result returned");

      setDeleteModal(false);
      setDeleteInterests(null);
      return res.data;
    } catch (err) {
      setError("unexpected Error: " + err);
    } finally {
      props.refreshCVs();
    }
  };

  const { mutate: mutateDelete } = useMutation(deleteInterestsAction);

  const interestsDeleteHandler = (id) => {
    setDeleteModal(true);
    const interests = interestsList.find((item) => item.id === id);
    setDeleteInterests(interests);
  };
  const interestsDelete = (event) => {
    //setDeleteModal(true)
    event.preventDefault();
    try {
      mutateDelete(deleteInterests.id);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {deleteInterests && (
        <CModal
          show={deleteModal}
          closeOnBackdrop={false}
          onClose={setDeleteModal}
        >
          <CModalHeader closeButton className="modal--header-color">
            <CModalTitle>{t("Interests")} </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CRow className="cv-section-card">
              <CCol xs="12" md="12">
                <CFormGroup row>
                  <CCol xs="12" md="6">
                    <CLabel htmlFor="text-input">
                      {t("Interest Name")}<span className="text-danger"> *</span>
                    </CLabel>
                    <CInput
                      disabled
                      placeholder= {t("Interest Name")}
                      value={deleteInterests.name}
                    />
                    <CFormText></CFormText>
                  </CCol>
                  <CCol xs="12" md="6">
                    <CLabel htmlFor="text-input">
                      {t("Notes")}<span className="text-danger"> </span>
                    </CLabel>
                    <CInput
                      disabled
                      placeholder={t("Notes")}
                      value={
                        deleteInterests.notes
                      }
                    />
                    <CFormText></CFormText>
                  </CCol>
                </CFormGroup>
               
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            {error && <CAlert color="danger">{error}</CAlert>}
            <CButton color="danger" onClick={interestsDelete}>
              {t("Delete")}
            </CButton>{" "}
            <CButton color="secondary" onClick={() => setDeleteModal(false)}>
              {t("Cancel")}
            </CButton>
          </CModalFooter>
        </CModal>
      )}
      {interestsList &&
        interestsList.map((interest, index) => (
          <CRow key={"interests" + index} className="cv-section-card">
            <CCol xs="12" md="12">
              <CFormGroup row>
                <CCol xs="12" md="6">
                  <CLabel htmlFor="text-input">
                  {t("Interest Name")}<span className="text-danger"> *</span>
                  </CLabel>
                  <CInput
                    disabled
                    placeholder={t("List the field/s of interest in which you consider yourself an expert")}
                    value={interest.name}
                  />
                  <CFormText></CFormText>
                </CCol>
                <CCol xs="12" md="6">
                  <CLabel htmlFor="text-input">
                    {t("Notes")}<span className="text-danger"></span>
                  </CLabel>
                  <CInput
                    disabled
                    placeholder={t("Notes")}
                    value={interest.notes}
                  />
                  <CFormText></CFormText>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs="12" md="6"></CCol>
               {!props.viewOnly && <CCol xs="12" md="6" style={{ textAlign: "end" }}>
                  <CButton
                    color="success"
                    onClick={() => props.interestsEditHandler(interest.id)}
                  >
                    {t("Edit")}
                  </CButton>{" "}
                  <CButton
                    color="danger"
                    onClick={() => interestsDeleteHandler(interest.id)}
                  >
                    {t("Delete")}
                  </CButton>
                </CCol>}
              </CFormGroup>
            </CCol>
          </CRow>
        ))}
    </>
  );
};

export default InterestsView;
