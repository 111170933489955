import {
    CAlert,
    CButton,
  CCol,
  CContainer,
  CDataTable,
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSwitch,
  CTextarea,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { formatDateNoTime, formatDate } from "../../../../../utils";

import axios from "../../../../../axios";
const InterestsViewCompact = (props) => {
  const [interestsList, setInterestsList] = useState(props.interestsList);
  const [deleteModal, setDeleteModal] = useState(false);
   const [deleteInterests, setDeleteInterests] = useState(false);
   const [error, setError] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    setInterestsList(props.interestsList);

     return () => {};
  }, [props.interestsList]);
  const deleteInterestsAction = async (id) =>
    {

      setError(null);
     
      try {
        console.log('sending to API');

        let res = null;

        res = await axios.delete(`/interests/${id}`);

        if (res.error)
            setError(res.error.response.data.error.message);

        if (res === undefined)
            throw new Error('No result returned');

        setDeleteModal(false);
        setDeleteInterests(null);
        return res.data;

      } catch (err){
        setError('unexpected Error: ' + err);

       
      }
      finally {
          
        props.refreshCVs();
      }
    }
   
  const { mutate:mutateDelete} = useMutation(deleteInterestsAction);
    
  const interestsDeleteHandler = id =>
  {
        setDeleteModal(true)
        const interests = interestsList.find(item=> item.id === id)
        setDeleteInterests(interests);
  }
  const interestsDelete = (event) =>
  {
        //setDeleteModal(true)
        event.preventDefault();
        try {

             mutateDelete(deleteInterests.id)
        } catch (error) {
            console.log(error);
        }
  }
  return (
    <>
    { deleteInterests && <CModal show={(deleteModal)} closeOnBackdrop={false} onClose={setDeleteModal}>
        <CModalHeader closeButton className="modal--header-color">
          <CModalTitle>{t("Interests")} </CModalTitle>
        </CModalHeader>
        <CModalBody>
        <CRow className="cv-section-card">
          <CCol xs="12" md="12">
          <CFormGroup row>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t("Interest Name")}<span className="text-danger"> *</span>
              </CLabel>
              <CInput
              disabled
              placeholder={t("List the field/s of interest in which you consider yourself an expert")}
                value={deleteInterests.name}
              />
              <CFormText></CFormText>
            </CCol>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                Notes<span className="text-danger"> </span>
              </CLabel>
              <CInput
              disabled
                placeholder=""
                value={deleteInterests.notes}
              />
              <CFormText></CFormText>
            </CCol>
          </CFormGroup>
      
          </CCol>
        </CRow>      
     
        </CModalBody>
        <CModalFooter>
          {error && <CAlert color="danger">{error}</CAlert>}
          <CButton
            color="danger"
            onClick={interestsDelete}
          >
           Delete
          </CButton>{" "}
          <CButton color="secondary" onClick={() => setDeleteModal(false)}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
}
      <CDataTable
           items={interestsList}
           fields={[
             {key:'name', label:t('Interest Name') }, 
              {key:'notes', label:t('Notes')  }, 
              {key: 'actions', label: t('Actions'), _classes: `${props.viewOnly && "hidden"}` }
           ]}
           hover
           striped
           bordered
           size="sm"
           
          //  onRowClick={(item) => history.push(`/users/${item.id}`)}
           scopedSlots = {{
           
            actions:
               (item)=>(
                <td className={`actionsAlignEnd ${props.viewOnly && "hidden"}`}>
                <CButton color="success" onClick={()=> props.interestsEditHandler(item.id)}>
                    {t("Edit")}
                  </CButton>
                  {" "}
                   <CButton color="danger" onClick={()=> interestsDeleteHandler(item.id)}>
                    {t("Delete")}
                  </CButton>   </td>
               ),
              
           }}
         />
    </>
  );
};

export default InterestsViewCompact;
