import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputCheckbox,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CAlert,
  CCardGroup,
  CFormGroup,
  CLabel,
  CLink,
  CToggler,
} from "@coreui/react";
import { Link } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { useTranslation } from "react-i18next";
import axios from '../../../axios'
import { useMutation } from "react-query";
import {validateEmail} from '../../../utils';
import { useDispatch, useSelector } from "react-redux";
const ForgotPassword = () => {
  const { t, i18n } = useTranslation();
  const [errorSave, setErrorSave] = useState(null);
  const [success,setSuccess] = useState(false);
  const [email, setEmail] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {

    const lang =  localStorage.getItem('lang');
    dispatch({ type: "CHANGE_LANG", lang: lang ? lang : 'en', i18n: i18n });

    return () => {};
  }, []);

  const lang = useSelector((state) => state.lang);

  const changeLanguage = () => {
    dispatch({ type: "CHANGE_LANG", lang: lang === "ar" ? "en" : "ar" });
    i18n.changeLanguage(lang === "ar" ? "en" : "ar");
  };

  const sendHandler = async (event) => {
    event.preventDefault();
    // TODO: call the /forgot-password end point
    const body = {
      email
    }
    await mutate(body)
  };
  const forgotPassword = async (body) =>
  {
    setSuccess(false);
    setErrorSave(null);
    try {
      console.log('sending to API');

      let res = null;

      res = await axios.post(`/forgot-password`,body,null);

      console.log('api res',res);
      if (res.status === 200)
      {
          setSuccess(true);
      }
          
      if (res.error)
          setErrorSave(t(res.error.response.data.error.message));

      return res.data;

    } catch {

      setErrorSave('Unexpected Error');

    }
    finally {
       
        
    }
  }

  const { mutate, isError,status} = useMutation(forgotPassword);
  const emailChangeHandler = (event) =>
  {
    setSuccess(false);
    setErrorSave(null);
    setEmail(event.target.value);
  }
  const disableSend = () =>
  {
    return !validateEmail(email);
  }
  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="9" lg="7" xl="6">
            <CCard className="mx-4">
              <CCardBody className="p-4">

                <div className="d-flex justify-content-end">
                    <CToggler
                      inHeader
                      className="ms-1 p-2"
                      onClick={changeLanguage}
                    >
                      {
                        // lang === 'EN' ? <CIcon content={flagSet.cifGb} size="xl" /> :
                        // <CIcon content={flagSet.cifSa} size="xl" />
                        lang === "en" ? "العربية" : "English"
                      }
                    </CToggler>
                </div>
                <CForm>
                  <h1>{t("Forgot Password")}</h1>
                 
                  <CAlert color="info">
                    {t("Please enter your valid email that is used on Qalat portal to recover your account")}
                
                  </CAlert>
                  <CInputGroup className="mb-3">
                    <CInputGroupPrepend>
                      <CInputGroupText>@</CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput
                      type="text"
                      placeholder={t("Email")}
                      autoComplete="email"
                      value={email}
                      
                      onChange={emailChangeHandler}
                    />
                  </CInputGroup>
                  {/* <CInputGroup className="mb-3">
                    <CInputGroupPrepend>
                      <CInputGroupText>
                        <CIcon name="cil-lock-locked" />
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput type="password" placeholder="Password" autoComplete="new-password" />
                  </CInputGroup>
                  <CInputGroup className="mb-4">
                    <CInputGroupPrepend>
                      <CInputGroupText>
                        <CIcon name="cil-lock-locked" />
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput type="password" placeholder="Repeat password" autoComplete="new-password" />
                  </CInputGroup> */}
                 {errorSave && <CAlert color="danger">{errorSave} </CAlert>}
                 
                 {success &&  <CAlert closeButton onClick={(event) => event.preventDefault()} color="success">{t("An email has been sent to your")} {'"' + email + '"'} {t("with a set password link. Kindly check your email for next step")} </CAlert>}
                 
                 {status ==='loading' && <CAlert color="warning">{t("Checking ...")}</CAlert>}
                 
                 <CButton color="success" onClick={sendHandler} block disabled={disableSend()} >
                    {t("Send set password link")}
                 </CButton>
                </CForm>
              
             
                <Link className="primary" to="/login">{t("Return to login page")}</Link>
                
             
               
              </CCardBody>
              
              {/* <CCardFooter className="p-4">
                <CRow>
                  <CCol xs="12" sm="6">
                    <CButton className="btn-facebook mb-1" block><span>facebook</span></CButton>
                  </CCol>
                  <CCol xs="12" sm="6">
                    <CButton className="btn-twitter mb-1" block><span>twitter</span></CButton>
                  </CCol>
                </CRow>
              </CCardFooter> */}
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ForgotPassword;
