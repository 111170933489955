import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router";
import {
  CAlert,
  CButton,
  CFormText,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
} from "@coreui/react";
import axios from "../../../axios";
import CIcon from "@coreui/icons-react";
import { useTranslation } from "react-i18next";
import { validateEmail } from "src/utils";

const CheckToken = (props) => {
    const {t} = useTranslation();
  const [pageError, setPageError] = useState(null);
  
  const [success, setSuccess] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [newConfirmPassword, setNewConfirmPassword] = useState('');
  const [email, setEmail] = useState('')
  const history = useHistory();
  console.log("re render CheckToken");



  const verifyToken = async () => {
    try {
      //const headers = { Authorization: `Bearer ${token}` };
      setPageError(null);
      //props.setCommingError(null);
      const res = await axios.post(
        `/verify-forgot-password-token/${props.resetToken}`
      );

      console.log("api res", res);

      if (res.status === 200) {
        setSuccess(res.data.email);
      }

      if (res.error) setPageError(t(res.error.response.data.error.message));

      return res.data;
    } catch {
      setPageError("Unexpected Error");
    } finally {
        setEmail('');
        setNewPassword('');
    }
  };

  const { mutate, status } = useMutation(verifyToken);

  const verifyTokenHandler = async () => {
    await mutate();
  };

  useEffect(() => {
    verifyTokenHandler();
    return () => {
      //
    };
  }, []);

  const isValidFrom = ()=>
  {
     
    return (email && 
      validateEmail(email) && 
      newPassword && 
      newPassword.length >= 8 &&
      newConfirmPassword && 
      newPassword === newConfirmPassword ) 
  }

  return (
    <>
      {status === "loading" || status === "idle" ? (
        <CAlert color="warning">
          Verifying the reset link ...
          <div className="text-center">
            <div className="spinner-border text-primary " role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </CAlert>
      ) : null}

      
      {success && <CAlert color="success">{t("Valid Link")}
       <p> 
          {t(`Please insert your email which is used in Qalat portal`)} {'(' + success + ')'} { t(`and a new password`)} 
          {/*this point will be checked*/} 
      </p>
      </CAlert>}

      {success && <>
              <CInputGroup className="mb-3">
                    <CInputGroupPrepend>
                      <CInputGroupText>@</CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput
                      type="text"
                      placeholder={t("Email")}
                      autoComplete="email"
                      value={email}
                      
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CInputGroupPrepend>
                      <CInputGroupText>
                        <CIcon name="cil-lock-locked" />
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput type="password" 
                    placeholder={t('8charachers')}
                    autoComplete="new-password" 
                    onChange={(event) => setNewPassword(event.target.value)} 
                    value={newPassword}
                    
                    />
                    
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CInputGroupPrepend>
                      <CInputGroupText>
                        <CIcon name="cil-lock-locked" />
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput type="password" 
                    placeholder={t('Confirm password')}
                    autoComplete="new-password" 
                    onChange={(event) => setNewConfirmPassword(event.target.value)} 
                    value={newConfirmPassword}
                    
                    />
                    
                  </CInputGroup>
                  
                  {( 
                      (email && !validateEmail(email)) ||
                        newPassword.length < 8 || 
                        newPassword !== newConfirmPassword
                  ) && <CAlert color="danger">
                         
                         {(email && !validateEmail(email)) && <div>{t("Email should be valid")}</div>} 
                         {newPassword.length < 8 && <div>{t("Password should be at least 8 characters")}</div> }
                         {newPassword !== newConfirmPassword && <div>{t("Password doesn't match, please confirm the password")}</div> }
                                            
                  </CAlert>}
              
                  {pageError && <CAlert color="danger">{pageError}</CAlert>}
                  {props.error && <CAlert color="danger">{props.error}</CAlert>}

                 <CButton color="success" 
                  onClick={() => props.submitNewPasword({email,newPassword})}
                  block 
                  disabled={!isValidFrom()} 
                >
                    {t("Submit new password")}
               </CButton>
               </>}

               {(pageError && !success) && <CAlert color="danger">{pageError}</CAlert>}
    </>
  );
};

export default CheckToken;
