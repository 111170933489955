import { CCol, CFormText, CInputFile, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const PersonalPhoto = (props) => {
  const [personalImage, setPersonalImage] = useState(props.personalPhoto);
  const [maxExceeded, setMaxExceeded] = useState(false);

  const {t} = useTranslation()
  useEffect(() => {
   
    setPersonalImage(props.personalPhoto)
    //props.onChange(personalImage);
    return () => {
      
    }
  }, [props.personalPhoto])
  const imageHandler = (event) => {
   event.preventDefault();

    const file = event.target.files[0];
    console.log(file);
    setMaxExceeded(false);
    
    if (!file) {
      setPersonalImage(null);
      return;
    }
    if (file.size > 2000000)
    {
        setMaxExceeded(true);
        setPersonalImage(null);    
        return;
    }
    const reader = new FileReader();

    reader.onloadend = (fil) => {
     
      setPersonalImage(reader.result);
      props.onChange(reader.result)
    };

    reader.readAsDataURL(file);
  };
  // console.log("render peronal image ", personalImage);
  return (
    <>
      {personalImage ? (
        <CRow>
            <CCol>
            <div className="personal-photo-container">
              <small> {t("imageHint")}</small>
            <div className="personal-photo-remove" onClick={()=> { 
              setMaxExceeded(false);
              setPersonalImage(null);
              props.onChange(null);
              }}>
            </div>
          <img
            className="personal-photo"
            src={personalImage}
            alt="personalImage"
          />
        </div>
            </CCol>
        </CRow>
      ) : (
          
        <div className="personal-photo-preview-text col-md-12">
          Click here to select a photo
          <br/>
          Recommended size (180 width x 230 height)
          <br/>
          Maximum file size 2 MB
          <br/>
         {maxExceeded && <p className="text-danger">Selected file is too big</p>}
        </div>
      )}
     <div className="personal-photo-picker">
     <CInputFile
        id="file-multiple-input"
        name="file-multiple-input"
        custom
        onChange={imageHandler}
        accept="image/*"
        className="custom-file-input-ext"
      />
     </div>
    </>
  );
};

export default PersonalPhoto;
