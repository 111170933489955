import {
    CAlert,
    CButton,
  CCol,
  CContainer,
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSwitch,
  CTextarea,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { formatDateNoTime, proficiencyName } from "../../../../../utils";

import axios from "../../../../../axios";
const CVLanguagesView = (props) => {
  const [languageList, setLanguageList] = useState(props.languageList);
  const [deleteModal, setDeleteModal] = useState(false);
   const [deleteLanguage, setDeleteLanguage] = useState(false);
   const [error, setError] = useState(null);

  const { t ,i18n} = useTranslation();

  useEffect(() => {
    setLanguageList(props.languageList);

     return () => {};
  }, [props.languageList]);
  const deleteLanguageAction = async (id) =>
    {

      setError(null);
     
      try {
        console.log('sending to API');

        let res = null;

        res = await axios.delete(`/cv-languages/${id}`);

        if (res.error)
            setError(res.error.response.data.error.message);

        if (res === undefined)
            throw new Error('No result returned');

        setDeleteModal(false);
        setDeleteLanguage(null);
        return res.data;

      } catch (err){
        setError('unexpected Error: ' + err);

       
      }
      finally {
          
        props.refreshCVs();
      }
    }
   
  const { mutate:mutateDelete} = useMutation(deleteLanguageAction);
    
  const languageDeleteHandler = id =>
  {
        setDeleteModal(true)
        const language = languageList.find(item=> item.id === id)
        setDeleteLanguage(language);
  }
  const languageDelete = (event) =>
  {
        //setDeleteModal(true)
        event.preventDefault();
        try {

             mutateDelete(deleteLanguage.id)
        } catch (error) {
            console.log(error);
        }
  }
  return (
    <>
    { deleteLanguage && <CModal show={(deleteModal)} closeOnBackdrop={false} onClose={setDeleteModal}>
        <CModalHeader closeButton className="modal--header-color">
          <CModalTitle>{t("Language")} </CModalTitle>
        </CModalHeader>
        <CModalBody>
        <CRow className="cv-section-card">
          <CCol xs="12" md="12">
          <CFormGroup row>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
              {t("Language")}<span className="text-danger"> *</span>
              </CLabel>
              <CInput
              disabled
                placeholder={t("Language")}
                value={deleteLanguage.language[i18n.language === "ar"? "nameAr": "nameEn"]}
              />
              <CFormText></CFormText>
            </CCol>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
              {t("Proficiency")}<span className="text-danger"> *</span>
              </CLabel>
              <CInput
              disabled
                placeholder="Language URL"
                value={proficiencyName(deleteLanguage.proficiency,t)}
              />
              <CFormText></CFormText>
            </CCol>
          </CFormGroup>
       
          </CCol>
        </CRow>      
     
        </CModalBody>
        <CModalFooter>
          {error && <CAlert color="danger">{error}</CAlert>}
          <CButton
            color="danger"
            onClick={languageDelete}
          >
           {t("Delete")}
          </CButton>{" "}
          <CButton color="secondary" onClick={() => setDeleteModal(false)}>
            {t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>
}
      {languageList.map((language, index) => (
      
        <CRow key={"language"+index} className="cv-section-card">
          <CCol xs="12" md="12">
          <CFormGroup row>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t("Language")}<span className="text-danger"> *</span>
              </CLabel>
              <CInput
              disabled
                placeholder="Language"
                value={language ? language.language[i18n.language === "ar"? "nameAr": "nameEn"] : ""}
              />
              <CFormText></CFormText>
            </CCol>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
              {t("Proficiency")}<span className="text-danger"> *</span>
              </CLabel>
              <CInput
              disabled
                placeholder="Language URL"
                value={proficiencyName(language.proficiency,t)}
              />
              <CFormText></CFormText>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
          <CCol xs="12" md="6"></CCol>
            <CCol xs="12" md="6">
               <CRow>
                <CCol xs="12" md="6"></CCol>
               {!props.viewOnly &&  <CCol xs="12" md="6" style={{ textAlign:"end" }}>
                <CButton color="success" onClick={()=> props.languageEditHandler(language.id)}>
                    {t("Edit")}
                  </CButton>
                  {" "}
                <CButton color="danger" onClick={()=> languageDeleteHandler(language.id)}>
                    {t("Delete")}
                  </CButton>
                </CCol>}
              </CRow>
            </CCol>
          </CFormGroup>
    
          </CCol>
        </CRow>      
      ))}
    </>
  );
};

export default CVLanguagesView;
