import React, { Suspense, useEffect, useState } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory
} from 'react-router-dom'
import { CContainer, CFade } from '@coreui/react'

// routes config
import {routes ,authenticatedRoutes, authenticatedRoutesOrg, authenticatedRoutesExpert} from '../routes'
import { useSelector } from 'react-redux'
  
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const TheContent = (props) => {

  
 const isAuth = useSelector(state => state.token != null);
  const userRole = useSelector(state => state.userRole);

 const history = useHistory();

  console.log('content history',history.location.pathname)
  
 // const found = authenticatedRoutes.find(route => route.path === history.location.pathname)
 // Omran TODO, check the auth routes DONE 10 April
 // console.log('found',found)
 
  
  return (
    <main className="c-main">
      <CContainer fluid>
        <Suspense fallback={loading}>
          <Switch>
            {routes.map((route, idx) => {
              return route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                    <CFade>
                      <route.component {...props} />
                    </CFade>
                  )} />
              )
            })}
            {(isAuth && userRole === 'Admin') &&
            authenticatedRoutes.map((route, idx) => {
              return route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                    <CFade>
                      <route.component {...props} />
                    </CFade>
                  )} />
              )
            })
            
            }
             {(isAuth && userRole === 'Org') &&
            authenticatedRoutesOrg.map((route, idx) => {
              return route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                    <CFade>
                      <route.component {...props} />
                    </CFade>
                  )} />
              )
            })
            
            }
             {(isAuth && userRole === 'Expert') &&
            authenticatedRoutesExpert.map((route, idx) => {
              return route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                    <CFade>
                      <route.component {...props} />
                    </CFade>
                  )} />
              )
            })
            
            }
           {isAuth && userRole === 'Admin' && <Redirect from="/" to="/dashboard" />}
           {isAuth && userRole === 'Expert' && <Redirect from="/" to="/my-cv" />}
          </Switch>
        </Suspense>
      </CContainer>
    </main>
  )
}

export default React.memo(TheContent)
