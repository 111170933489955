import CIcon from "@coreui/icons-react";
import {
  CAlert,
  CButton,
  CCol,
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTextarea,
  CSelect,
  CSwitch,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import CountryList from "../../../CountryList/CountryList";


import axios from "../../../../../axios";
import {
  formatDateForPicker,
  validateEmail,
  validateHTTP,
  validatePhoneNumber,
} from "src/utils";
import { cilCalendarCheck, cilDelete, cilPlus } from "@coreui/icons";

import MembershipsViewCompact from "./MembershipsViewCompact";
import MembershipsView from "./MembershipsView";
import { useTranslation } from "react-i18next";

const Memberships = (props) => {
  const [modal, setModal] = useState(false);
  const [compactView, setCompactView] = useState(false);

  const [membershipsList, setMembershipsList] = useState(
    props.memberships ? props.memberships : []
  );

  const [orgName, setOrgName] = useState("");
  
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const {t} = useTranslation()
  const [editMode, setEditMode] = useState(false);
  const [editMembershipsId, setEditMembershipsId] = useState(0);
  // const [phone, setPhone] = useState(currentPersonalInfo.phone);
  // const [title, setTitle] = useState(currentPersonalInfo.currentTitle);
  const [error, setError] = useState(null);

  useEffect(() => {
    setMembershipsList(props.memberships ? props.memberships : []);

    if (editMembershipsId) {
      const memberships = props.memberships.find((item) => item.id === editMembershipsId);
      // console.log("selected memberships ", memberships);
      setOrgName(memberships.orgName);
      setTitle(memberships.title);
      setDescription(memberships.description);

    } else {
      setOrgName("");
      setTitle("");
      setDescription("");
    
    }
    return () => {};
  }, [props.memberships, editMembershipsId]);

  const isValidForm = () => {
    return orgName &&
    title &&
    description.length <= 2000;
  };
  // console.log('membershipsList',membershipsList);

  const addEditMemberships = async (allData) => {
    try {
      setError(null);
      console.log(
        "addMemberships",

        allData
      );
      let res = null;
      if (editMembershipsId === 0) // insert mode
        res = await axios.post(
          "/memberships/", //+ currentPersonalInfo.id,
          allData
        );
      else// edit mode
        res = await axios.patch(
          "/memberships/" + editMembershipsId,
          allData
        );


      if (res.error) {
        // setStartBuildingCVError(res.error.response.data.error.message);
        console.log(
          "res.error.response.data.error.message",
          res.error.response.data.error.message
        );
        setError(res.error.response.data.error.message);
      } else if (res === undefined) {
        setError("No response from API");
      } else {
        console.log("updated personal info", res.data);

        setModal(false);
        setOrgName("");
        setTitle("");
        setDescription("");
      
        setEditMembershipsId(0);
        setError(null);
        return res.data;
      }
    } catch (error) {
      setError("Custom Error" + error);
    } finally {
      props.refreshCVs();
    }
  };

  const { mutate, status } = useMutation(addEditMemberships);

  const membershipsAddEdit = (event) => {
    event.preventDefault();
    try {
      const allData = {
        orgName,        
        title,
        description,   
        cvId: props.CVId,
       
      };
     
      console.log("Memberships data", allData);
      mutate(allData);
    } catch (err) {
      setError("Unhandled Error" + err);
    }
  };

  const orgNameHandler = (event) => {
    setOrgName(event.target.value);
  };
 
  const titleHandler = (event) => {
    setTitle(event.target.value);
  };
  const descriptionHandler = (event) => {
    setDescription(event.target.value);
  };
 
  const membershipsEditHandler = (id) => {
    setEditMode(true);
    setEditMembershipsId(id);
    setModal(true);
  };
  const compacyViewHandler = (event) => {
    setCompactView(!compactView);
    console.log('compactView',compactView)
  };
 return (
    <>
      <CRow>
        <CCol xs="12" md="12">
          <div
            className="cv-section-header col-md-12"
            onClick={(event) => {
              if (props.viewOnly ) return;
           
              setOrgName("");
              setTitle("");
               setDescription("");
             
              
              setEditMembershipsId(0);
              setError(null);
              setModal(true);
              setEditMode(false);
            
            }}
          >
            <h5>
            {t("Memberships")} - {!props.viewOnly &&  <span>
                {t("Click to add")} <CIcon content={cilPlus} size="xl"></CIcon>{" "}
              </span>}
            </h5>
           </div>
           <div style={{ textAlign:"end" }}>
            <span style={{verticalAlign: "top"}}>{t("Compact View")} </span>
            <CSwitch color="primary" onChange={compacyViewHandler} checked={compactView} />
           </div>
                  
        </CCol>
      </CRow>
      <CModal show={modal} closeOnBackdrop={false} onClose={setModal}>
        <CModalHeader closeButton className="modal--header-color">
          <CModalTitle>{t('Memberships')}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CFormGroup row>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t("Organization Name")}<span className="text-danger"> *</span>
              </CLabel>
              <CInput
                id="memberships-orgName-input-id"
                name="memberships-orgName-input"
                placeholder={t("Organization Name")}
                value={orgName}
                onChange={orgNameHandler}
                onFocus={orgNameHandler}
              />
              <CFormText> <span
                  className={
                   ( orgName === "" || orgName.trim() === "") ? "text-danger" : ""
                  }
                >
                 {t("Required")}
                </span></CFormText>
            </CCol>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t('Membership Title')}<span className="text-danger"> *</span>
              </CLabel>
              <CInput
                id="memberships-title-input-id"
                name="memberships-title-input"
                placeholder={t('Membership Title')}
                value={title}
                onChange={titleHandler}
                onFocus={titleHandler}
              />
              <CFormText> <span
                  className={
                   ( title === "" || title.trim() === "") ? "text-danger" : ""
                  }
                >
                 {t("Required")}
                </span></CFormText>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t("Description")}<span className="text-danger"> </span>
              </CLabel>
              <CTextarea
                id="memberships-Description-input-id"
                name="memberships-Description-input"
                placeholder={t("Description")}
                rows="6"
                value={description}
                onChange={descriptionHandler}
                onFocus={descriptionHandler}
              />
              <CFormText> <span
                  className={
                   ( description.length > 2000) ? "text-danger" : ""
                  }
                >
               {t("Maximum 2000 characters")}
                </span></CFormText>
            </CCol>
            <CCol xs="12" md="6">
             
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          {error && <CAlert color="danger">{error}</CAlert>}
          <CButton
            color="success"
            disabled={!isValidForm()}
            onClick={membershipsAddEdit}
          >
            {editMembershipsId ?t("Edit") : t("Add")}
          </CButton>{" "}
          <CButton color="secondary" onClick={() => setModal(false)}>
            {t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>

      <CRow>
        <CCol xs="12" md="12">

          {
            (membershipsList && compactView) && 
            <MembershipsViewCompact
              membershipsEditHandler={membershipsEditHandler}
              membershipsList={membershipsList}
              refreshCVs={props.refreshCVs}
              viewOnly= {props.viewOnly}
            />
          }
          {
            (membershipsList && membershipsList.length > 0 && !compactView) && 
            <MembershipsView
             membershipsEditHandler={membershipsEditHandler}
              membershipsList={membershipsList}
              refreshCVs={props.refreshCVs}
              viewOnly= {props.viewOnly}
            />
          }
          {(membershipsList.length === 0) &&
            <p>{t("No memberships added yet.")}</p>
          }
        </CCol>
      </CRow>
    </>
  );
};

export default Memberships;
