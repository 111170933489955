import CIcon from "@coreui/icons-react";
import {
  CAlert,
  CButton,
  CCol,
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTextarea,
  CSelect,
  CSwitch,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import CountryList from "../../../CountryList/CountryList";


import axios from "../../../../../axios";
import {
  formatDateForPicker,
  validateEmail,
  validateHTTP,
  validatePhoneNumber,
} from "src/utils";
import { cilCalendarCheck, cilDelete, cilPlus } from "@coreui/icons";

import ExperiencesViewCompact from "./ExperiencesViewCompact";
import ExperiencesView from "./ExperiencesView";
import { useTranslation } from "react-i18next";

const Experiences = (props) => {
  const [modal, setModal] = useState(false);
  const [compactView, setCompactView] = useState(false);
  const {t} = useTranslation()
  const [experienceList, setExperienceList] = useState(
    props.experiences ? props.experiences : []
  );

  const [jobTitle, setJobTitle] = useState("");
  
  const [description, setDescription] = useState("");
  const [countryId, setCountryId] = useState(0);
 
  const [editMode, setEditMode] = useState(false);
  const [editExperienceId, setEditExperienceId] = useState(0);
  // const [phone, setPhone] = useState(currentPersonalInfo.phone);
  // const [title, setTitle] = useState(currentPersonalInfo.currentTitle);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    setExperienceList(props.experiences ? props.experiences : []);

    if (editExperienceId > 0) {
      const experience = props.experiences.find((item) => item.id === editExperienceId);
      console.log("selected experiences ", experience);
      setJobTitle(experience.jobTitle);
      setDescription(experience.description);
      setCountryId(experience.countryId === null ? 0 : experience.countryId);
     } else {
      setJobTitle("");
      setDescription("");
      setCountryId(0);
      
    }
    return () => {};
  }, [props.experiences, editExperienceId]);

  const isValidForm = () => {
    return jobTitle &&
    description &&
    description.length <= 2000;
  };
  console.log('experienceList',experienceList);

  const addEditExperience = async (allData) => {
    try {
      setError(null);
      console.log(
        "addExperience",

        allData
      );
      let res = null;
      if (editExperienceId === 0) // insert mode
        res = await axios.post(
          "/experiences/", //+ currentPersonalInfo.id,
          allData
        );
      else// edit mode
        res = await axios.patch(
          "/experiences/" + editExperienceId,
          allData
        );


      if (res.error) {
        // setStartBuildingCVError(res.error.response.data.error.message);
        console.log(
          "res.error.response.data.error.message",
          res.error.response.data.error.message
        );
        setError(res.error.response.data.error.message);
      } else if (res === undefined) {
        setError("No response from API");
      } else {
        console.log("updated personal info", res.data);

        setModal(false);
        setJobTitle("");
        setDescription("");
        setCountryId(0);
        setEditExperienceId(0);
        setError(null);
        return res.data;
      }
    } catch (error) {
      setError("Custom Error" + error);
    } finally {
      props.refreshCVs();
    }
  };

  const { mutate, status } = useMutation(addEditExperience);

  const experienceAddEdit = (event) => {
    event.preventDefault();
    try {
      const allData = {
        jobTitle,        
        description,        
        cvId: props.CVId,
        countryId: countryId === 0 ? null : countryId
      };
     
      console.log("Experience data", allData);
      mutate(allData);
    } catch (err) {
      setError("Unhandled Error" + err);
    }
  };

  const jobTitleHandler = (event) => {
    setJobTitle(event.target.value);
  };
  const countryHandler = (event) => {
    const selectedId = +event.target.value;
    setCountryId(selectedId);    
  };
  const descriptionHandler = (event) => {
    setDescription(event.target.value);
  };
 
  const experienceEditHandler = (id) => {
    setEditMode(true);
    setEditExperienceId(id);
    setModal(true);
  };
  const compacyViewHandler = (event) => {
    setCompactView(!compactView);
    console.log('compactView',compactView)
  };
 return (
    <>
      <CRow>
        <CCol xs="12" md="12">
          <div
            className="cv-section-header col-md-12"
            onClick={(event) => {
              if (props.viewOnly ) return;
            
              setJobTitle("");
              setDescription("");
              setCountryId(0);

              setEditExperienceId(0);
              setError(null);
              setModal(true);
              setEditMode(false);
            
            }}
          >
            <h5>
            {t("Experiences")} - {!props.viewOnly && <span>
               {t("Click to add")} <CIcon content={cilPlus} size="xl"></CIcon>{" "}
              </span>}
            </h5>
           </div>
           <div  style={{ textAlign:"end" }}>
            <span style={{verticalAlign: "top"}}>{t("Compact View")} </span>
            <CSwitch color="primary" onChange={compacyViewHandler} checked={compactView} />
           </div>
                  
        </CCol>
      </CRow>
      <CModal show={modal} closeOnBackdrop={false} onClose={setModal}>
        <CModalHeader closeButton className="modal--header-color">
          <CModalTitle>{t("Experience")}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CFormGroup row>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
              {t("Job Title")}<span className="text-danger"> *</span>
              </CLabel>
              <CInput
                id="experience-jobTitle-input"
                name="experience-jobTitle-input"
                placeholder={t("Job Title")}
                value={jobTitle}
                onChange={jobTitleHandler}
                onFocus={jobTitleHandler}
              />
              <CFormText>
              <span className={jobTitle === "" ? "text-danger" : ""}>
                      {t("Required")}
                    </span>
               </CFormText>
            </CCol>
            <CCol xs="12" md="6">
                  <CLabel htmlFor="select">
                    {t("Country")}<span className="text-danger"></span>
                  </CLabel>
                  <CSelect
                    custom
                    name="select"
                    id="select"
                    value={countryId}
                    onChange={countryHandler}
                    onFocus={countryHandler}
                  
                  >
                    <CountryList></CountryList>
                  </CSelect>
                  <CFormText>
                  
                    
                  </CFormText>
                </CCol>
           
          </CFormGroup>
          <CFormGroup row>
          <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t("Description")}
                <span className="text-danger"> *</span>
              </CLabel>
              <CTextarea
                id="experience-description-input"
                name="experience-description-input"
                placeholder={t("Describe your previous working experience and your role")}
                rows="6"
                value={description}
                onChange={descriptionHandler}
                onFocus={descriptionHandler}
              />
              <CFormText>
              <span className={(description === "" || description.length > 2000)? "text-danger" : ""}>
              {t("Required")}, {t("Maximum 2000 characters")}
                    </span>
            
                </CFormText>
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          {error && <CAlert color="danger">{error}</CAlert>}
          <CButton
            color="success"
            disabled={!isValidForm()}
            onClick={experienceAddEdit}
          >
            {editExperienceId ? t("Edit") : t("Add")}
          </CButton>{" "}
          <CButton color="secondary" onClick={() => setModal(false)}>
            {t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>

      <CRow>
        <CCol xs="12" md="12">

          {
            (experienceList && compactView) && 
            <ExperiencesViewCompact
              experienceEditHandler={experienceEditHandler}
              experienceList={experienceList}
              refreshCVs={props.refreshCVs}
              viewOnly= {props.viewOnly}
            />
          }
          {
            (experienceList && experienceList.length > 0 && !compactView) && 
            <ExperiencesView
             experienceEditHandler={experienceEditHandler}
              experienceList={experienceList}
              refreshCVs={props.refreshCVs}
              viewOnly= {props.viewOnly}
            />
          }
          {(!experienceList || experienceList.length ===0) &&
            <p>{t("No experiences added yet.")}</p>
          }
        </CCol>
      </CRow>
    </>
  );
};

export default Experiences;
