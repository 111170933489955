import React from 'react';

import { componentLoader } from './utils'
const Toaster = React.lazy(() => import('./views/notifications/toaster/Toaster'));
const Tables = React.lazy(() => import('./views/base/tables/Tables'));

const Breadcrumbs = React.lazy(() => componentLoader(import('./views/base/breadcrumbs/Breadcrumbs'),3));

const Cards = React.lazy(() => import('./views/base/cards/Cards'));
const Carousels = React.lazy(() => import('./views/base/carousels/Carousels'));
const Collapses = React.lazy(() => import('./views/base/collapses/Collapses'));
const BasicForms = React.lazy(() => import('./views/base/forms/BasicForms'));

const Jumbotrons = React.lazy(() => import('./views/base/jumbotrons/Jumbotrons'));
const ListGroups = React.lazy(() => import('./views/base/list-groups/ListGroups'));
const Navbars = React.lazy(() => import('./views/base/navbars/Navbars'));
const Navs = React.lazy(() => import('./views/base/navs/Navs'));
const Paginations = React.lazy(() => import('./views/base/paginations/Pagnations'));
const Popovers = React.lazy(() => import('./views/base/popovers/Popovers'));
const ProgressBar = React.lazy(() => import('./views/base/progress-bar/ProgressBar'));
const Switches = React.lazy(() => import('./views/base/switches/Switches'));

const Tabs = React.lazy(() => import('./views/base/tabs/Tabs'));
const Tooltips = React.lazy(() => import('./views/base/tooltips/Tooltips'));
const BrandButtons = React.lazy(() => import('./views/buttons/brand-buttons/BrandButtons'));
const ButtonDropdowns = React.lazy(() => import('./views/buttons/button-dropdowns/ButtonDropdowns'));
const ButtonGroups = React.lazy(() => import('./views/buttons/button-groups/ButtonGroups'));
const Buttons = React.lazy(() => import('./views/buttons/buttons/Buttons'));
const Charts = React.lazy(() => import('./views/charts/Charts'));
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
const CoreUIIcons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'));
const Flags = React.lazy(() => import('./views/icons/flags/Flags'));
const Brands = React.lazy(() => import('./views/icons/brands/Brands'));
const Alerts = React.lazy(() => import('./views/notifications/alerts/Alerts'));
const Badges = React.lazy(() => import('./views/notifications/badges/Badges'));
const Modals = React.lazy(() => import('./views/notifications/modals/Modals'));
const Colors = React.lazy(() => import('./views/theme/colors/Colors'));
const Typography = React.lazy(() => import('./views/theme/typography/Typography'));
const Widgets = React.lazy(() => import('./views/widgets/Widgets'));
const Users = React.lazy(() => import('./containers/users/Users'));
const User = React.lazy(() => import('./containers/users/User'));
const ChangeLang = React.lazy(() => import('./views/lang/ChangeLang'));
const CVPrivacy = React.lazy(() => import('./containers/CVPrivacy/CVPrivacy'));
const Indexes = React.lazy(() =>  import('./containers/Indexes/Indexes'));
const OrgRequestManagement = React.lazy(() => import('./containers/OrgRequestManagement/OrgRequestManagement'));
const CVRequestManagement = React.lazy(() => import('./containers/CVRequestManagement/CVRequestManagement'));
const CVRequestDetails = React.lazy(() => import('./containers/CVRequestManagement/CVRequestDetails'));
const CVDetails = React.lazy(() => import('./containers/CVDetails/CVDetails'));
const CVDetailsView = React.lazy(() => import('./containers/CVDetailsView/CVDetailsView'));
const ExpertCVList = React.lazy(() => import('./containers/ExpertCVList/ExpertCVList'));
const ExpertCVListView = React.lazy(() => import('./containers/ExpertCVListView/ExpertCVListView'));
const TwoFA = React.lazy(() => import('./containers/users/TwoFA/TwoFA'));
const SysSettings = React.lazy(() => import('./containers/SysSettings/SysSettings'));

const SingleOrgRequest = React.lazy(() =>  import('./containers/OrgRequestManagement/SingleOrgRequest'));

const ChangePassword = React.lazy(() =>  import('./containers/users/ChangePassword'));

const MyCV = React.lazy(() => import('./containers/MyCV/MyCV'));
const MySettings = React.lazy(() => import('./containers/MySettings/MySettings'));
// Omran TODO revist when roles are applied to have Admin routes and Org routes

export const routes = [
  { path: '/', exact: true, name: 'Home' },
  // { path: '/org-request', name: 'Organization Request', component: OrdRequest },
  
  { path: '/changelang', name: 'Change Lang', component: ChangeLang },

  { path: '/theme', name: 'Theme', component: Colors, exact: true },
  { path: '/theme/colors', name: 'Colors', component: Colors },
  { path: '/theme/typography', name: 'Typography', component: Typography },
  { path: '/base', name: 'Base', component: Cards, exact: true },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
  { path: '/base/cards', name: 'Cards', component: Cards },
  { path: '/base/carousels', name: 'Carousel', component: Carousels },
  { path: '/base/collapses', name: 'Collapse', component: Collapses },
  { path: '/base/forms', name: 'Forms', component: BasicForms },
  { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons },
  { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
  { path: '/base/navbars', name: 'Navbars', component: Navbars },
  { path: '/base/navs', name: 'Navs', component: Navs },
  { path: '/base/paginations', name: 'Paginations', component: Paginations },
  { path: '/base/popovers', name: 'Popovers', component: Popovers },
  { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar },
  { path: '/base/switches', name: 'Switches', component: Switches },
  { path: '/base/tables', name: 'Tables', component: Tables },
  { path: '/base/tabs', name: 'Tabs', component: Tabs },
  { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
  { path: '/buttons', name: 'Buttons', component: Buttons, exact: true },
  { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
  { path: '/buttons/button-dropdowns', name: 'Dropdowns', component: ButtonDropdowns },
  { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
  { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons },
  { path: '/charts', name: 'Charts', component: Charts },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', component: Flags },
  { path: '/icons/brands', name: 'Brands', component: Brands },
  { path: '/notifications', name: 'Notifications', component: Alerts, exact: true },
  { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
  { path: '/notifications/badges', name: 'Badges', component: Badges },
  { path: '/notifications/modals', name: 'Modals', component: Modals },
  { path: '/notifications/toaster', name: 'Toaster', component: Toaster },
  { path: '/widgets', name: 'Widgets', component: Widgets }


];
export const authenticatedRoutes = [
  { path: '/users', exact: true,  name: 'Users', component: Users },
  { path: '/users/:id', exact: true, name: 'User Details', component: User },
  { path: '/cv-privacy', exact: true, name: 'CV Privacy', component: CVPrivacy },
  { path: '/indexes', exact: true, name: 'Indexes', component: Indexes },
  { path: '/org-request-management', exact: true, name: 'Organization Requests', component: OrgRequestManagement },
  { path: '/cv-request-management', exact: true, name: 'CV Requests', component: CVRequestManagement },
  { path: '/cv-request-details/:id', exact: true, name: 'CV Requests Details', component: CVRequestDetails },
  {path: '/cv/:id', exact: true, name: 'CV Details', component: CVDetails },
  {path: '/cv-view/:id', exact: true, name: 'CV Details', component: CVDetailsView },
  { path: '/org-request/:id', exact: true, name: 'Org request review', component: SingleOrgRequest },
  { path: '/change-password', exact: true, name: 'Change Password', component: ChangePassword },
  { path: '/expert-cv', exact: true, name: 'Expert CV List', component: ExpertCVList },
  { path: '/expert-cv-view', exact: true, name: 'Expert CV List', component: ExpertCVListView },
  { path: '/2fa', exact: true, name: 'Two Factor Authetication', component: TwoFA },
  { path: '/sys-settings', exact: true, name: '2FA', component: SysSettings },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard }

];

export const authenticatedRoutesOrg = [
  {path: '/cv-view/:id', exact: true, name: 'CV Details', component: CVDetailsView },
  { path: '/change-password', exact: true, name: 'Change Password', component: ChangePassword },
  { path: '/expert-cv-view', exact: true, name: 'Expert CV List', component: ExpertCVListView },
  { path: '/2fa', exact: true, name: 'Two Factor Authetication', component: TwoFA },

];
export const authenticatedRoutesExpert = [
  {path: '/cv-view/:id', exact: true, name: 'CV Details', component: CVDetailsView },
  { path: '/change-password', exact: true, name: 'Change Password', component: ChangePassword },
  { path: '/expert-cv-view', exact: true, name: 'Expert CV List', component: ExpertCVListView },
  { path: '/2fa', exact: true, name: 'Two Factor Authetication', component: TwoFA },
  { path: '/my-cv', exact: true, name: 'Expert CV', component: MyCV },
  { path: '/my-settings', exact: true, name: 'Expert Settings', component: MySettings },

];

