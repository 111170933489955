import CIcon from "@coreui/icons-react";
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLink,
  CRow,
  CToggler,
} from "@coreui/react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { login } from "src/utils";
import axios from "../../../axios";
const Verify2FA = (props) => {
  const { t, i18n } = useTranslation();

  const [enterBackupCode, setEnterBackupCode] = useState(false);
  const [authCode, setAuthCode] = useState("");
  const [error, setError] = useState(null);
  const [contactAdmin, setContactAdmin] = useState(false)
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {

    const lang =  localStorage.getItem('lang');
    dispatch({ type: "CHANGE_LANG", lang: lang ? lang : 'en', i18n: i18n });

    return () => {};
  }, []);

  const lang = useSelector((state) => state.lang);

  const changeLanguage = () => {
    dispatch({ type: "CHANGE_LANG", lang: lang === "ar" ? "en" : "ar" });
    i18n.changeLanguage(lang === "ar" ? "en" : "ar");
  };

  const verify = async (authData) => {
    setError(null);
    try {
      console.log("authData", authData);

      const res = await axios.post("users/auth", authData);
      if (res.error) {
        console.log("res", res);

        setError(res.error.response.data.error.message);
      } else {
        if (res.data.token) {
          login(dispatch, res, history);
        } else {
          setError(t("No Token Returned"));
        }
      }
    } catch (error) {
      setError(error.message);

      console.log(error.message);
    }
    //return res;
  };

  const { mutate, isError, isLoading } = useMutation(verify);

  const VerifyHandler = () => {
    try {
      const authData = {
        email: props.email,
        password: props.password,
        code: authCode,
      };
      mutate(authData);
    } catch (error) {}
  };
  const canSubmit = () => {

    if (authCode && authCode.length === 6 && !enterBackupCode) return true;
    if (authCode && authCode.length === 8 && enterBackupCode) return true;
    return false;
  }
  return (
    <>
      <CCardGroup>
        <CCard className="p-4">
          <CCardBody>
            {!contactAdmin && <>
            <div className="d-flex justify-content-end">
                  <CToggler
                    inHeader
                    className="ms-1 p-2"
                    onClick={changeLanguage}
                  >
                    {
                      // lang === 'EN' ? <CIcon content={flagSet.cifGb} size="xl" /> :
                      // <CIcon content={flagSet.cifSa} size="xl" />
                      lang === "en" ? "العربية" : "English"
                    }
                  </CToggler>
            </div>  
            <CRow className="justify-content-center">
              <CCol md="6" xs="12" className="justify-content-center">
                <CAlert color="info">
                  {t(enterBackupCode ? "Please enter one backup code that was not used earlier": "Please enter the authentication code from your autentication app.")}
                </CAlert>
              </CCol>
            </CRow>
            
            <CRow className="justify-content-center">
              <CCol md="6" xs="12" className="justify-content-center">
                <CInputGroup className="mb-4">
                  <CInputGroupPrepend>
                    <CInputGroupText>
                      <CIcon name="cil-lock-locked" />
                      {t(enterBackupCode? "Backup Code": "Auth Code")}
                    </CInputGroupText>
                  </CInputGroupPrepend>
                  <CInput
                    type="text"
                    placeholder={t(enterBackupCode ? "i.e. 12345678":"i.e. 123456")}
                    value={authCode}
                    onChange={(event) => {
                      setAuthCode(event.target.value);
                    }}
                  />
                </CInputGroup>
              </CCol>
            </CRow>
            <CRow className="justify-content-center mb-4">
              <CCol md="3" xs="6" className="justify-content-center">
                {!enterBackupCode && (
                  <CButton
                    color="secondary"
                    block
                    onClick={() => {
                      setEnterBackupCode(true);
                      setAuthCode("")
                      setError(null)
                    }}
                  >
                    {t("I don't have auth code")}
                  </CButton>
                )}
                {enterBackupCode && (
                  <CButton
                    color="danger"
                    block
                    onClick={() => {
                      setContactAdmin(true);
                      setAuthCode("")
                      setError(null)
                    }}
                  >
                    {t("I don't have backup code")}
                  </CButton>
                )}
                 {enterBackupCode && (
                  <CButton
                    color="secondary"
                    block
                    onClick={() => {
                      setEnterBackupCode(false)
                      setAuthCode("")
                      setError(null)
                    }}
                  >
                    {t("Back")}
                  </CButton>
                )}
              </CCol>
              <CCol md="3" xs="6" className="justify-content-center">
                {
                  <CButton
                    color="success"
                    block
                    disabled={!canSubmit()}
                    onClick={VerifyHandler}
                  >
                    {t("Continue")}
                  </CButton>
                }
              </CCol>
            </CRow>
            {error && <CRow className="justify-content-center">
              <CCol md="6" xs="12" className="justify-content-center">
                <CAlert color="danger">
                  {t("error")}
                </CAlert>
              </CCol>
            </CRow>}
            </>}
            {contactAdmin &&  <>
              <div className="d-flex justify-content-end">
                <CToggler
                  inHeader
                  className="ms-1 p-2"
                  onClick={changeLanguage}
                >
                  {
                    // lang === 'EN' ? <CIcon content={flagSet.cifGb} size="xl" /> :
                    // <CIcon content={flagSet.cifSa} size="xl" />
                    lang === "en" ? "العربية" : "English"
                  }
                </CToggler>
              </div>  
              <CCol md="6" xs="12" className="justify-content-center">
                <CAlert color="info">
                  {t("Please contact a system admin")}
                  <CLink onClick={()=> { window.location.reload(false)}}> {t("Click here to go back to login page")}</CLink>
                </CAlert>
              </CCol>
            </>}
            </CCardBody>
        </CCard>
      </CCardGroup>
    </>
  );
};

export default Verify2FA;
