import {
    CAlert,
    CButton,
  CCol,
  CContainer,
  CDataTable,
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSwitch,
  CTextarea,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { formatDateNoTime, formatDate } from "../../../../../utils";

import axios from "../../../../../axios";
const MembershipsViewCompact = (props) => {
  const [membershipsList, setMembershipsList] = useState(props.membershipsList);
  const [deleteModal, setDeleteModal] = useState(false);
   const [deleteMemberships, setDeleteMemberships] = useState(false);
   const [error, setError] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    setMembershipsList(props.membershipsList);

     return () => {};
  }, [props.membershipsList]);
  const deleteMembershipsAction = async (id) =>
    {

      setError(null);
     
      try {
        console.log('sending to API');

        let res = null;

        res = await axios.delete(`/memberships/${id}`);

        if (res.error)
            setError(res.error.response.data.error.message);

        if (res === undefined)
            throw new Error('No result returned');

        setDeleteModal(false);
        setDeleteMemberships(null);
        return res.data;

      } catch (err){
        setError('unexpected Error: ' + err);

       
      }
      finally {
          
        props.refreshCVs();
      }
    }
   
  const { mutate:mutateDelete} = useMutation(deleteMembershipsAction);
    
  const membershipsDeleteHandler = id =>
  {
        setDeleteModal(true)
        const memberships = membershipsList.find(item=> item.id === id)
        setDeleteMemberships(memberships);
  }
  const membershipsDelete = (event) =>
  {
        //setDeleteModal(true)
        event.preventDefault();
        try {

             mutateDelete(deleteMemberships.id)
        } catch (error) {
            console.log(error);
        }
  }
  return (
    <>
    { deleteMemberships && <CModal show={(deleteModal)} closeOnBackdrop={false} onClose={setDeleteModal}>
        <CModalHeader closeButton className="modal--header-color">
          <CModalTitle>{t("Memberships")} </CModalTitle>
        </CModalHeader>
        <CModalBody>
        <CRow className="cv-section-card">
          <CCol xs="12" md="12">
          <CFormGroup row>
                  <CCol xs="12" md="6">
                    <CLabel htmlFor="text-input">
                      {t("Organization Name")}<span className="text-danger"> *</span>
                    </CLabel>
                    <CInput
                      disabled
                      placeholder={t("Organization Name")}
                      value={deleteMemberships.orgName}
                    />
                    <CFormText></CFormText>
                  </CCol>
                  <CCol xs="12" md="6">
                    <CLabel htmlFor="text-input">
                      {t("Membership Title")}<span className="text-danger"> </span>
                    </CLabel>
                    <CInput
                      disabled
                      placeholder={t("Membership Title")}
                      value={
                        deleteMemberships.title
                      }
                    />
                    <CFormText></CFormText>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol xs="12" md="6">
                    <CLabel htmlFor="text-input">
                      {t("Description")}<span className="text-danger"> *</span>
                    </CLabel>
                    <CTextarea
                      disabled
                      placeholder={t("Description")}
                      rows="6"
                      value={deleteMemberships.description}
                    />
                    <CFormText></CFormText>
                  </CCol>
                  <CCol xs="12" md="6">
                   
                  </CCol>
                </CFormGroup>
              
          </CCol>
        </CRow>      
     
        </CModalBody>
        <CModalFooter>
          {error && <CAlert color="danger">{error}</CAlert>}
          <CButton
            color="danger"
            onClick={membershipsDelete}
          >
           {t("Delete")}
          </CButton>{" "}
          <CButton color="secondary" onClick={() => setDeleteModal(false)}>
            {t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>
}
      <CDataTable
           items={membershipsList}
           fields={[
             {key:'orgName', label:t('Organization Name') }, 
             {key:'title', label:t('Title')  }, 
             {key:'description', label:t('Description')  }, 
             {key: 'actions', label: t('Actions') , _classes: `${props.viewOnly && "hidden"}`}
           ]}
           hover
           striped
           bordered
           size="sm"
           
          //  onRowClick={(item) => history.push(`/users/${item.id}`)}
           scopedSlots = {{           
            actions:
               (item)=>(
                <td className={`actionsAlignEnd ${props.viewOnly && "hidden"}`}>
                    <CButton color="success" onClick={()=> props.membershipsEditHandler(item.id)}>
                    {t("Edit")}
                  </CButton>
                  {" "}
                   <CButton color="danger" onClick={()=> membershipsDeleteHandler(item.id)}>
                    {t("Delete")}
                  </CButton>   </td>
               ),
              
           }}
         />
    </>
  );
};

export default MembershipsViewCompact;
