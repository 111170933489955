import CIcon from "@coreui/icons-react";
import {
  CAlert,
  CButton,
  CCol,
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTextarea,
  CSelect,
  CSwitch,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import CountryList from "../../../CountryList/CountryList";


import axios from "../../../../../axios";
import {
  formatDateForPicker,
  validateEmail,
  validateHTTP,
  validatePhoneNumber,
} from "src/utils";
import { cilCalendarCheck, cilDelete, cilPlus } from "@coreui/icons";

import InterestsViewCompact from "./InterestsViewCompact";
import InterestsView from "./InterestsView";
import { useTranslation } from "react-i18next";

const Interests = (props) => {
  const [modal, setModal] = useState(false);
  const [compactView, setCompactView] = useState(false);

  const [interestsList, setInterestsList] = useState(
    props.interests ? props.interests : []
  );

  const [name, setName] = useState("");
  
  const [notes, setNotes] = useState("");
 
  const [editMode, setEditMode] = useState(false);
  const [editInterestsId, setEditInterestsId] = useState(0);
  // const [phone, setPhone] = useState(currentPersonalInfo.phone);
  // const [title, setTitle] = useState(currentPersonalInfo.currentTitle);
  const [error, setError] = useState(null);

  useEffect(() => {
    setInterestsList(props.interests ? props.interests : []);

    if (editInterestsId) {
      const interests = props.interests.find((item) => item.id === editInterestsId);
      console.log("selected interests ", interests);
      setName(interests.name);
      setNotes(interests.notes);
     } else {
      setName("");
      setNotes("");
      
    }
    return () => {};
  }, [props.interests, editInterestsId]);

  const isValidForm = () => {
    return name &&
    name.trim()!==""
     ;
  };
  console.log('interestsList',interestsList);

  const addEditInterests = async (allData) => {
    try {
      setError(null);
      console.log(
        "addInterests",

        allData
      );
      let res = null;
      if (editInterestsId === 0) // insert mode
        res = await axios.post(
          "/interests/", //+ currentPersonalInfo.id,
          allData
        );
      else// edit mode
        res = await axios.patch(
          "/interests/" + editInterestsId,
          allData
        );


      if (res.error) {
        // setStartBuildingCVError(res.error.response.data.error.message);
        console.log(
          "res.error.response.data.error.message",
          res.error.response.data.error.message
        );
        setError(res.error.response.data.error.message);
      } else if (res === undefined) {
        setError("No response from API");
      } else {
        console.log("updated personal info", res.data);

        setModal(false);
        setName("");
        setNotes("");
      
        setEditInterestsId(0);
        setError(null);
        return res.data;
      }
    } catch (error) {
      setError("Custom Error" + error);
    } finally {
      props.refreshCVs();
    }
  };

  const { mutate, status } = useMutation(addEditInterests);

  const interestsAddEdit = (event) => {
    event.preventDefault();
    try {
      const allData = {
        name,        
        notes,        
        cvId: props.CVId,
       
      };
     
      console.log("Interests data", allData);
      mutate(allData);
    } catch (err) {
      setError("Unhandled Error" + err);
    }
  };

  const nameHandler = (event) => {
    setName(event.target.value);
  };
 
  const notesHandler = (event) => {
    setNotes(event.target.value);
  };
 
  const interestsEditHandler = (id) => {
    setEditMode(true);
    setEditInterestsId(id);
    setModal(true);
  };
  const compacyViewHandler = (event) => {
    setCompactView(!compactView);
    console.log('compactView',compactView)
  };

  const {t} = useTranslation()
 return (
    <>
      <CRow>
        <CCol xs="12" md="12">
          <div
            className="cv-section-header col-md-12"
            onClick={(event) => {
              if (props.viewOnly ) return;
           
              setName("");
              setNotes("");
             
              
              setEditInterestsId(0);
              setError(null);
              setModal(true);
              setEditMode(false);
            
            }}
          >
            <h5>
            {t("Interests")} - {!props.viewOnly &&  <span>
                {t("Click to add")} <CIcon content={cilPlus} size="xl"></CIcon>{" "}
              </span>}
            </h5>
           </div>
           <div style={{ textAlign:"end" }}>
            <span style={{verticalAlign: "top"}}>{t("Compact View")} </span>
            <CSwitch color="primary" onChange={compacyViewHandler} checked={compactView} />
           </div>
                  
        </CCol>
      </CRow>
      <CModal show={modal} closeOnBackdrop={false} onClose={setModal}>
        <CModalHeader closeButton className="modal--header-color">
          <CModalTitle>{t("Interests")}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CFormGroup row>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t("Interest Name")}<span className="text-danger"> *</span>
              </CLabel>
              <CInput
                id="interests-name-input"
                name="interests-name-input"
                placeholder={t("List the field/s of interest in which you consider yourself an expert")}
                value={name}
                onChange={nameHandler}
                onFocus={nameHandler}
              />
              <CFormText>
              <span className={(name === "" || name.trim()==="")? "text-danger" : ""}>
              {t("Required")}
                    </span>
                    </CFormText>
            </CCol>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t("Notes")}<span className="text-danger"> </span>
              </CLabel>
              <CInput
                id="interests-notes-input"
                name="interests-notes-input"
                placeholder={t("Notes")}
                value={notes}
                onChange={notesHandler}
                onFocus={notesHandler}
              />
              <CFormText></CFormText>
            </CCol>
          </CFormGroup>
         
        </CModalBody>
        <CModalFooter>
          {error && <CAlert color="danger">{error}</CAlert>}
          <CButton
            color="success"
            disabled={!isValidForm()}
            onClick={interestsAddEdit}
          >
            {editInterestsId ? t("Edit") :t("Add")}
          </CButton>{" "}
          <CButton color="secondary" onClick={() => setModal(false)}>
            {t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>

      <CRow>
        <CCol xs="12" md="12">

          {
            (interestsList && compactView) && 
            <InterestsViewCompact
              interestsEditHandler={interestsEditHandler}
              interestsList={interestsList}
              refreshCVs={props.refreshCVs}
              viewOnly= {props.viewOnly}
            />
          }
          {
            (interestsList && interestsList.length > 0 && !compactView) && 
            <InterestsView
             interestsEditHandler={interestsEditHandler}
              interestsList={interestsList}
              refreshCVs={props.refreshCVs}
              viewOnly= {props.viewOnly}
            />
          }
          {(interestsList.length === 0) &&
            <p>{t("No interests added yet.")}</p>
          }
        </CCol>
      </CRow>
    </>
  );
};

export default Interests;
