import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import axios from '../../../axios'
const PublishedWorkTypesOption = props =>
{

  const [error, setError] = useState(null);

  const {t,i18n} = useTranslation()
  const fetchPublishedWorkType = async () => {
    setError(null);
    const params = {
      filter: {
        "fields": {
          "id": true,
          "nameAr": true,
          "nameEn": true,
        },
        where : {
          "isActive" : true
        }
      }
    };

    //check api route
    const res = await axios.get('/published-work-types', { params: params })

    if (res.error)
    {
        setError(res.error.response.data.error.message)
    }
    if (!res)
    {
        setError('No Response')
    }
    console.log('published-work-types Data', res.data)
    return res.data;
  };

  const {data } = useQuery('getPublishedWorkType', fetchPublishedWorkType);

    return <>
     <option value="0">{t("Please select")}</option>
    {data && data.map((item,index)=> <option key={"PublishedWorkType" +item.id } value={ item.id}>{i18n.language === 'ar'? item.nameAr : item.nameEn }</option>) }
    
                      
     {error && <option value="-1">{error}</option>}
      
    </>;
}

export default PublishedWorkTypesOption;