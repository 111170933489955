import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSwitch,
  CTextarea,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { formatDateNoTime } from "../../../../../utils";

import axios from "../../../../../axios";
const PublishedWorksView = (props) => {
  const [publishedWorksList, setPublishedWorksList] = useState(props.publishedWorksList);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletePublishedWorks, setDeletePublishedWorks] = useState(false);
  const [error, setError] = useState(null);

  const { t,i18n } = useTranslation();

  useEffect(() => {
    setPublishedWorksList(props.publishedWorksList);
    console.log("props.publishedWorksList", props.publishedWorksList);
    return () => {};
  }, [props.publishedWorksList]);
  const deletePublishedWorksAction = async (id) => {
    setError(null);

    try {
      console.log("sending to API");

      let res = null;

      res = await axios.delete(`/published-works/${id}`);

      if (res.error) 
      {
        setError(res.error.response.data.error.details[0].message);
        new Error(res.error.response.data.error.details[0].message);
      }

      if (res === undefined) throw new Error("No result returned");

      setDeleteModal(false);
      setDeletePublishedWorks(null);
      return res.data;

    } catch (err) {
      setError("unexpected Error: " + err);
    } finally {
      props.refreshCVs();
    }
  };

  const { mutate: mutateDelete } = useMutation(deletePublishedWorksAction);

  const publishedWorksDeleteHandler = (id) => {
    setDeleteModal(true);
    const publishedWorks = publishedWorksList.find((item) => item.id === id);
    setDeletePublishedWorks(publishedWorks);
  };
  const publishedWorksDelete = (event) => {
    //setDeleteModal(true)
    event.preventDefault();
    try {
      mutateDelete(deletePublishedWorks.id);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {deletePublishedWorks && (
        <CModal
          show={deleteModal}
          closeOnBackdrop={false}
          onClose={setDeleteModal}
        >
          <CModalHeader closeButton className="modal--header-color">
            <CModalTitle>{t('Published Content')} - {t('Delete')}</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CRow className="cv-section-card">
              <CCol xs="12" md="12">
                <CFormGroup row>
                  <CCol xs="12" md="6">
                    <CLabel htmlFor="text-input">
                      {t('Title')}<span className="text-danger"> *</span>
                    </CLabel>
                    <CInput
                      disabled
                      placeholder={t("Title")}
                      value={deletePublishedWorks.title}
                    />
                    <CFormText></CFormText>
                  </CCol>
                  <CCol xs="12" md="6">
                    <CLabel htmlFor="text-input">
                      {t("Type")}<span className="text-danger"> *</span>
                    </CLabel>
                    <CInput
                      disabled
                      placeholder=""
                      value={
                        deletePublishedWorks.publishedWorkType[i18n.language === "ar" ? "nameAr" :"nameEn"]
                      }
                    />
                    <CFormText></CFormText>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol xs="12" md="6">
                    <CLabel htmlFor="text-input">
                     {t("Date of Publication")}
                     {/* <span className="text-danger"> *</span> */}
                    </CLabel>
                    <CInput
                      disabled
                      
                      value={formatDateNoTime(deletePublishedWorks.publishDate)}
                    />
                    <CFormText></CFormText>
                  </CCol>
                  <CCol xs="12" md="6">
                    <CLabel htmlFor="text-input">
                      {t("URL")}
                      {/* <span className="text-danger"> </span> */}
                    </CLabel>
                    <CInput
                      disabled
                      placeholder= {t("URL")}
                      value={
                        deletePublishedWorks.url
                      }
                    />
                    <CFormText></CFormText>
                  </CCol>
                 
                </CFormGroup>
                <CFormGroup row>
                  <CCol xs="12" md="6">
                    <CLabel htmlFor="text-input">
                      {t("Description")}
                      {/* <span className="text-danger"> *</span> */}
                    </CLabel>
                    <CTextarea
                      disabled
                      placeholder={t("Description")}
                      value={deletePublishedWorks.description}
                      rows="6"
                    />
                    <CFormText></CFormText>
                  </CCol>
                  <CCol xs="12" md="6">
                    <CLabel htmlFor="text-input">
                      {t("Language")}
                      {/* <span className="text-danger"> *</span> */}
                    </CLabel>
                    <CInput
                      disabled
                      placeholder="Empty"
                      
                     value={deletePublishedWorks.language ? deletePublishedWorks.language[i18n.language === "en"? "nameEn" : "nameAr"] : ""}
                    />
                    <CFormText></CFormText>
                  </CCol>
                </CFormGroup>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            {error && <CAlert color="danger">{error}</CAlert>}
            <CButton color="danger" onClick={publishedWorksDelete}>
              {t("Delete")}
            </CButton>{" "}
            <CButton color="secondary" onClick={() => setDeleteModal(false)}>
              {t("Cancel")}
            </CButton>
          </CModalFooter>
        </CModal>
      )}
      {publishedWorksList &&
        publishedWorksList.map((publishedWork, index) => (
          <CRow key={"publishedWorks" + index} className="cv-section-card">
            <CCol xs="12" md="12">
              <CFormGroup row>
                <CCol xs="12" md="6">
                  <CLabel htmlFor="text-input">
                    {t("Title")}<span className="text-danger"> *</span>
                  </CLabel>
                  <CInput
                    disabled
                    placeholder={t("Title")}
                    value={publishedWork.title}
                  />
                  <CFormText></CFormText>
                </CCol>
                <CCol xs="12" md="6">
                  <CLabel htmlFor="text-input">
                   {t("Type")}<span className="text-danger"> *</span>
                  </CLabel>
                  <CInput
                    disabled
                    placeholder=""
                    value={publishedWork.publishedWorkType[i18n.language === "ar" ? "nameAr" :"nameEn"]}
                  />
                  <CFormText></CFormText>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs="12" md="6">
                  <CLabel htmlFor="text-input">
                  {t("Date of Publication")}
                  {/* <span className="text-danger"> *</span> */}
                  </CLabel>
                  <CInput
                    disabled
                    
                    value={formatDateNoTime(publishedWork.publishDate)}
                  />
                  <CFormText></CFormText>
                </CCol>
                <CCol xs="12" md="6">
                  <CLabel htmlFor="text-input">
                    {t("URL")}
                    {/* <span className="text-danger"></span> */}
                  </CLabel>
                  <CInput
                    disabled
                    placeholder={t("URL")}
                    value={publishedWork.url}
                  />
                  <CFormText></CFormText>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs="12" md="6">
                  <CLabel htmlFor="text-input">
                    {t("Description")}
                    {/* <span className="text-danger"> *</span> */}
                  </CLabel>
                  <CTextarea
                    disabled
                    placeholder={t("Description")}
                    value={publishedWork.description}
                    rows="6"
                  />
                  <CFormText></CFormText>
                </CCol>
                <CCol xs="12" md="6">
                  <CLabel htmlFor="text-input">
                   {t("Language")}
                   {/* <span className="text-danger"> *</span> */}
                  </CLabel>
                  <CInput
                    disabled
                    placeholder="Empty"
                    value={publishedWork.language ? publishedWork.language[i18n.language === "en"? "nameEn" : "nameAr"] : ""}
                  />
                  <CFormText></CFormText>
                </CCol>
               
              </CFormGroup>
              <CFormGroup row>
                <CCol xs="12" md="6"></CCol>
               { !props.viewOnly &&  <CCol xs="12" md="6" style={{ textAlign: "end" }}>
                <CButton
                    color="success"
                    onClick={() => props.publishedWorksEditHandler(publishedWork.id)}
                  >
                    {t("Edit")}
                  </CButton>{" "}
                  <CButton
                    color="danger"
                    onClick={() => publishedWorksDeleteHandler(publishedWork.id)}
                  >
                    {t("Delete")}
                  </CButton>
               
                </CCol>}
              </CFormGroup>
            </CCol>
          </CRow>
        ))}
    </>
  );
};

export default PublishedWorksView;
