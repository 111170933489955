import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import axios from '../../../axios'
const LanguagesOption = props =>
{

    const [error, setError] = useState(null);
    const {t,i18n} = useTranslation();
  const fetchLanguages = async () => {
    setError(null);
    const params = {
      filter: {
        "fields": {
          "id": true,
          "nameAr": true,
          "nameEn": true,
        },
        where : {
          "isActive" : true
        }
      }
    };
   
    //check api route
    const res = await axios.get('/languages', { params: params })

    if (res.error)
    {
        setError(res.error.response.data.error.message)
    }
    console.log('languages Data', res.data)
    return res.data;
  };

  const {data } = useQuery('getLanguages', fetchLanguages);

    return <>
     {/* <option value="0">Please select</option> */}
    {data && data.map((item,index)=> <option key={item.id } value={ item.id}>{i18n.language === 'ar'? item.nameAr : item.nameEn }</option>) }
    
                      
     {error && <option value="-1">{error}</option>}
      
    </>;
}

export default LanguagesOption;