import { CCol, CFormText, CInputFile, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";

const PersonalPhotoView = (props) => {
  const [personalImageSrc,setPersonalImageSrc] = useState(props.personalPhoto);
 
  useEffect(() => {
    setPersonalImageSrc(props.personalPhoto);
    console.log('useEffect Image PersonalPhotoView');
    return () => {
      
    }
  }, [props.personalPhoto])

  console.log('render Image PersonalPhotoView');
  return (
    <>
        <CRow>
            <CCol>
            <div className="personal-photo-container">            
          <img
            className="personal-photo"
            src={personalImageSrc}
            alt="PersonalPhoto"
          />
        </div>
            </CCol>
        </CRow>
    </>
  );
};

export default PersonalPhotoView;
