import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CSubheader,
  CBreadcrumbRouter,
  CLink,
  CBadge
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

// routes config
import {routes, authenticatedRoutes, authenticatedRoutesOrg, authenticatedRoutesExpert} from '../routes'

import { 
  TheHeaderDropdown,
  TheHeaderDropdownMssg,
  TheHeaderDropdownNotif,
  TheHeaderDropdownTasks
}  from './index'
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import axios from '../axios';
import { useHistory } from 'react-router-dom'

const TheHeader = () => {
 
  const history = useHistory();
  const {t} = useTranslation();
  const isAuth = useSelector(state => state.token != null)
  const dispatch = useDispatch();
  const sidebarShow = useSelector(state => state.sidebarShow);

  const toggleSidebar = () => {
    console.log('toggleSidebar')
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
    dispatch({type: 'set', sidebarShow: val})
  }

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    dispatch({ type: "set", sidebarShow: val });
  };

  const translatedRoutes = routes.map(rt => {
    const route = {...rt}
    route.name = t(route.name);
    return route;
  });
  const translatedAuthenticatedRoutes = authenticatedRoutes.map(rt => {
    const route = {...rt}
    route.name = t(route.name);
    return route;
  });

const translatedAuthenticatedRoutesOrg = authenticatedRoutesOrg.map(rt => {
    const route = {...rt}
    route.name = t(route.name);
    return route;
  });

  const translatedAuthenticatedRoutesExpert = authenticatedRoutesExpert.map(rt => {
    const route = {...rt}
    route.name = t(route.name);
    return route;
  });

  console.log('window.location.origin',window.location.origin);
  const token = useSelector((state) => state.token);
  const userRole = useSelector((state) => state.userRole);
  const [error, setError] = useState(null);

  const get2FAStatus = async () => {
    setError(null);

    const headers = { Authorization: `Bearer ${token}` };

    const res = await axios.get("/user/get-2fa-status", { headers });

    if (res.error) {
      setError(res.error.response.data.error.message);
    } else {
     
      return res.data;
    }
  };
  const { data: mfaStatus } = useQuery("get-2fa-status", get2FAStatus);

  return (

    
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />
    
    <CHeaderBrand className="mx-auto d-lg-none" to="/">
          <img src={(`${window.location.origin}/avatars/logo2.png`)} height="48" alt="JCIC"></img>
        {/* <CIcon name="logo" height="48" alt="Logo"/> */}
      </CHeaderBrand>
      <CHeaderNav className="d-md-down-none mr-auto">
        
        {/* <CHeaderNavItem className="px-3" >
          <CHeaderNavLink to="/dashboard">{t('Dashboard')}</CHeaderNavLink>
        </CHeaderNavItem>
        {isAuth &&   <CHeaderNavItem  className="px-3">
          <CHeaderNavLink to="/users">{t('Users')}</CHeaderNavLink>
        </CHeaderNavItem>}
      
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink>{t('CV Privacy')}</CHeaderNavLink>
        </CHeaderNavItem>
         */}
       
      </CHeaderNav>

      <CHeaderNav className="px-3" >
        {/* <TheHeaderDropdownNotif/> */}
        {/* <TheHeaderDropdownTasks/>
        <TheHeaderDropdownMssg/> */}
        <TheHeaderDropdown/>
        
      </CHeaderNav>

      <CSubheader className="px-3 justify-content-between">
        <CBreadcrumbRouter 
          className="border-0 c-subheader-nav m-0 px-0 px-md-3" 
          routes={[...translatedRoutes, userRole ==='Admin' ? {...translatedAuthenticatedRoutes} :{ ...translatedAuthenticatedRoutesOrg}]} 
        />
         {(mfaStatus && mfaStatus.enforced2FA) ?
          <div className="d-md-down-none mfe-2 c-subheader-nav">
            {(mfaStatus && mfaStatus.enforced2FA && !mfaStatus.enabled2FA) &&
              <CLink   onClick={()=> history.push("/2fa")}>
                  <CIcon name="cil-settings" alt="Settings2" /> {t("2FA is enforced, click here to enable 2FA")}
              </CLink>}

              {(mfaStatus && mfaStatus.enabled2FA) ?
              <CBadge color="success" >
                  <CIcon  name="cil-settings" alt="Settings22" /> {t("2FA is enabled")}
              </CBadge>: ""}
          </div>: ""}
      </CSubheader>
    </CHeader>
  )
}

export default TheHeader
