import React, { useEffect, useState } from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCol,
    CContainer,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow,
    CAlert,
    CInputCheckbox,
    CToggler,
  } from "@coreui/react";
  import { Link } from "react-router-dom";
  import CIcon from "@coreui/icons-react";
  import { useTranslation } from "react-i18next";
  import { useDispatch, useSelector } from "react-redux";
  import axios from '../../../axios'
  import CheckToken  from './CheckToken'
  import { useMutation } from 'react-query';
const ResetPassword = ({match}) =>
{

    const {t, i18n} = useTranslation();
    // TODO: check the URL token and its validity with DB
    // if good to go, send the token, email and new password to public API
    console.log('ResetPassword match',match)
    
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)

    const dispatch = useDispatch();

  useEffect(() => {

    const lang =  localStorage.getItem('lang');
    dispatch({ type: "CHANGE_LANG", lang: lang ? lang : 'en', i18n: i18n });

    return () => {};
  }, []);

  const lang = useSelector((state) => state.lang);

  const changeLanguage = () => {
    dispatch({ type: "CHANGE_LANG", lang: lang === "ar" ? "en" : "ar" });
    i18n.changeLanguage(lang === "ar" ? "en" : "ar");
  };
    
    const submitNewPasword = async (data) =>
    {
        setError(null);
        try {
            console.log('sending to API');
            const body = {
                email:data.email,
                password: data.newPassword,
                forgotToken: match.params.token
            }
            let res = null;
      
            res = await axios.post(`/token-reset-password`,body);
      
            console.log('api res',res);
            if (res.status === 200)
            {
                 setSuccess(true);
            }
                
            if (res.error){
                setError(t(res.error.response.data.error.message));
                
            }
           
            return res.data;
      
          } catch {
      
             setError('Unexpected Error');
      
          }
          finally {
             
              
          }

    }    
    const {mutate} = useMutation('afterVerifyResetPassword', submitNewPasword);


    const submitNewPaswordHandler = async (data) =>
    {
        try {
            await mutate(data);

        } catch (error) {
            console.log('Unexpected Error');
        }
    }
    //console.log('resetError',resetError)
    return (
        <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="9" lg="7" xl="6">
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <CForm>
                  <div className="d-flex justify-content-end">
                      <CToggler
                        inHeader
                        className="ms-1 p-2"
                        onClick={changeLanguage}
                      >
                        {
                          // lang === 'EN' ? <CIcon content={flagSet.cifGb} size="xl" /> :
                          // <CIcon content={flagSet.cifSa} size="xl" />
                          lang === "en" ? "العربية" : "English"
                        }
                      </CToggler>
                  </div>
                  <h1>{t("Set Your Password")}</h1>
                
                  <CheckToken 
                  resetToken={match.params.token} 
                  submitNewPasword={submitNewPaswordHandler}
                  error={error}
                  >

                  </CheckToken>

               
                </CForm>
                
             
               
              </CCardBody>
              <CCardBody className="p-4">
              {success && <CAlert color="success">
                  {t("Your password has been changed successfully, please go to")} {
                      <Link className="primary" to="/login">{t("login")}</Link>
                  } {t("page to proceed")}
                    </CAlert>}
             
                <Link className="primary" to="/login">{t("Return to login page")}</Link>
                
                </CCardBody>
              {/* <CCardFooter className="p-4">
                <CRow>
                  <CCol xs="12" sm="6">
                    <CButton className="btn-facebook mb-1" block><span>facebook</span></CButton>
                  </CCol>
                  <CCol xs="12" sm="6">
                    <CButton className="btn-twitter mb-1" block><span>twitter</span></CButton>
                  </CCol>
                </CRow>
              </CCardFooter> */}

            </CCard>

          </CCol>
        </CRow>
      </CContainer>
    </div>
    );
}
export default ResetPassword;