import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSwitch,
  CTextarea,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { formatDateNoTime } from "../../../../../utils";

import axios from "../../../../../axios";
const MembershipsView = (props) => {
  const [membershipsList, setMembershipsList] = useState(props.membershipsList);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteMemberships, setDeleteMemberships] = useState(false);
  const [error, setError] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    setMembershipsList(props.membershipsList);
    console.log("props.membershipsList", props.membershipsList);
    return () => {};
  }, [props.membershipsList]);
  const deleteMembershipsAction = async (id) => {
    setError(null);

    try {
      console.log("sending to API");

      let res = null;

      res = await axios.delete(`/memberships/${id}`);

      if (res.error) setError(res.error.response.data.error.message);

      if (res === undefined) throw new Error("No result returned");

      setDeleteModal(false);
      setDeleteMemberships(null);
      return res.data;
    } catch (err) {
      setError("unexpected Error: " + err);
    } finally {
      props.refreshCVs();
    }
  };

  const { mutate: mutateDelete } = useMutation(deleteMembershipsAction);

  const membershipsDeleteHandler = (id) => {
    setDeleteModal(true);
    const memberships = membershipsList.find((item) => item.id === id);
    setDeleteMemberships(memberships);
  };
  const membershipsDelete = (event) => {
    //setDeleteModal(true)
    event.preventDefault();
    try {
      mutateDelete(deleteMemberships.id);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {deleteMemberships && (
        <CModal
          show={deleteModal}
          closeOnBackdrop={false}
          onClose={setDeleteModal}
        >
          <CModalHeader closeButton className="modal--header-color">
            <CModalTitle>{t("Memberships")} </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CRow className="cv-section-card">
              <CCol xs="12" md="12">
                <CFormGroup row>
                  <CCol xs="12" md="6">
                    <CLabel htmlFor="text-input">
                      {t("Organization Name")}<span className="text-danger"> *</span>
                    </CLabel>
                    <CInput
                      disabled
                      placeholder={t("Organization Name")}
                      value={deleteMemberships.orgName}
                    />
                    <CFormText></CFormText>
                  </CCol>
                  <CCol xs="12" md="6">
                    <CLabel htmlFor="text-input">
                      {t("Membership Title")}<span className="text-danger"> </span>
                    </CLabel>
                    <CInput
                      disabled
                      placeholder={t("Membership Title")}
                      value={
                        deleteMemberships.title
                      }
                    />
                    <CFormText></CFormText>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol xs="12" md="6">
                    <CLabel htmlFor="text-input">
                      {t("Description")}<span className="text-danger"> *</span>
                    </CLabel>
                    <CTextarea
                      disabled
                      placeholder= {t("Description")}
                      rows="6"
                      value={deleteMemberships.description}
                    />
                    <CFormText></CFormText>
                  </CCol>
                  <CCol xs="12" md="6">
                   
                  </CCol>
                </CFormGroup>
               
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            {error && <CAlert color="danger">{error}</CAlert>}
            <CButton color="danger" onClick={membershipsDelete}>
              {t("Delete")}
            </CButton>{" "}
            <CButton color="secondary" onClick={() => setDeleteModal(false)}>
              {t("Cancel")}
            </CButton>
          </CModalFooter>
        </CModal>
      )}
      {membershipsList &&
        membershipsList.map((membership, index) => (
          <CRow key={"memberships" + index} className="cv-section-card">
            <CCol xs="12" md="12">
              <CFormGroup row>
                <CCol xs="12" md="6">
                  <CLabel htmlFor="text-input">
                    {t("Organization Name")}<span className="text-danger"> *</span>
                  </CLabel>
                  <CInput
                    disabled
                    placeholder={t("Organization Name")}
                    value={membership.orgName}
                  />
                  <CFormText></CFormText>
                </CCol>
                <CCol xs="12" md="6">
                  <CLabel htmlFor="text-input">
                  {t("Membership Title")}<span className="text-danger"> *</span>
                  </CLabel>
                  <CInput
                    disabled
                    placeholder={t("Membership Title")}
                    value={membership.title}
                  />
                  <CFormText></CFormText>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs="12" md="6">
                  <CLabel htmlFor="text-input">
                    {t("Description")}<span className="text-danger"> </span>
                  </CLabel>
                  <CTextarea
                    disabled
                    placeholder={t("Description")}
                    value={membership.description}
                    rows="6"
                  />
                  <CFormText></CFormText>
                </CCol>
                <CCol xs="12" md="6">
                  
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs="12" md="6"></CCol>
               {!props.viewOnly && <CCol xs="12" md="6" style={{ textAlign: "end" }}>
                  <CButton
                    color="success"
                    onClick={() => props.membershipsEditHandler(membership.id)}
                  >
                    {t("Edit")}
                  </CButton>{" "}
                  <CButton
                    color="danger"
                    onClick={() => membershipsDeleteHandler(membership.id)}
                  >
                    {t("Delete")}
                  </CButton>
                </CCol>}
              </CFormGroup>
            </CCol>
          </CRow>
        ))}
    </>
  );
};

export default MembershipsView;
