import React, { useState } from "react";
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
  CToggler,
} from "@coreui/react";

import { cilUser, cilAccountLogout } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import "../scss/style.scss"

const TheHeaderDropdown = (props) => {
  const { t, i18n } = useTranslation();
  const lang = useSelector((state) => state.lang);
  const isAuth = useSelector((state) => state.token != null);
  const dispatch = useDispatch();
  const history = useHistory();

  const toggleLang = () => {
    dispatch({ type: "CHANGE_LANG", lang: lang === "ar" ? "en" : "ar" });
    i18n.changeLanguage(lang === "ar" ? "en" : "ar");
  };

  console.log("props", props);
  const loginHandler = (event) => {
    history.push("/login");
  };
  const logoutHandler = (event) => {
    dispatch({ type: "LOGOUT" });
    history.push("/login");
  }; // Omran get the current language from local storage
  dispatch({ type: "CHANGE_LANG", lang: localStorage.getItem("lang") });

  return (
    <>
      <CToggler inHeader className="mx-1" onClick={toggleLang}>
        {
          // lang === 'EN' ? <CIcon content={flagSet.cifGb} size="xl" /> :
          // <CIcon content={flagSet.cifSa} size="xl" />
          lang === "en" ? (
           "العربية"
          ) : (
            "English"
          )
        }
      </CToggler>
      <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
        <CDropdownToggle className="c-header-nav-link" caret={false}>
          <div className="top">
            {/* <CImg src={`${window.location.origin}/avatars/6.jpg`} className="c-avatar-img" alt="" /> */}
            <h1 className="mainhead">{t("Account")}</h1> 
          </div>
        </CDropdownToggle>
        <CDropdownMenu className="pt-0" placement="bottom-end">
          {/* <CDropdownItem header tag="div" color="light" className="text-center">
            <strong>{t("Account")}</strong>
          </CDropdownItem> */}

          {isAuth ? (
            <CDropdownItem onClick={logoutHandler}>
              <CIcon name="cil-user" className="mfe-2" />
              {t("Logout")}
            </CDropdownItem>
          ) : (
            <CDropdownItem onClick={loginHandler}>
              <CIcon name="cil-user" className="mfe-2" />
              {t("Login")}
            </CDropdownItem>
          )}

            <CDropdownItem onClick={()=> history.push('change-password')}>
              <CIcon name="cil-user" className="mfe-2" />
              {t("Change password")}
            </CDropdownItem>
            <CDropdownItem onClick={()=> history.push('2fa')}>
              <CIcon name="cil-user" className="mfe-2" />
              {t("Two Factor Authentication")}
            </CDropdownItem>
          {/* <CDropdownItem>
          <CIcon name="cil-bell" className="mfe-2" />
          Updates
          <CBadge color="info" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-envelope-open" className="mfe-2" />
          Messages
          <CBadge color="success" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-task" className="mfe-2" />
          Tasks
          <CBadge color="danger" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-comment-square" className="mfe-2" />
          Comments
          <CBadge color="warning" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem
          header
          tag="div"
          color="light"
          className="text-center"
        >
          <strong>Settings</strong>
        </CDropdownItem>
       
        <CDropdownItem>
          <CIcon name="cil-settings" className="mfe-2" />
          Settings
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-credit-card" className="mfe-2" />
          Payments
          <CBadge color="secondary" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-file" className="mfe-2" />
          Projects
          <CBadge color="primary" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem divider />
        <CDropdownItem>
          <CIcon name="cil-lock-locked" className="mfe-2" />
          Lock Account
        </CDropdownItem> */}
        </CDropdownMenu>
      </CDropdown>
    </>
  );
};

export default withRouter(TheHeaderDropdown);
