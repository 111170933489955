import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import "./scss/style.scss";

import i18n from "./i18n";

import { authenticatedRoutes, authenticatedRoutesExpert, authenticatedRoutesOrg } from "./routes";

import Login from "./views/pages/login/Login";
import ForgotPassword from "./views/pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./views/pages/ForgotPassword/ResetPassword";
import OrgRequest from "./views/pages/OrgRequest/OrgRequest";
import CVRequest from "./views/pages/CVRequest/CVRequest";
import {
  CCol,
  CHeaderNavLink,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = (props) => {
  
  if (false) {
    console.log = function () {};
  } 
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.token != null);
  const history = useHistory();
  // console.log('isAuth',isAuth)
  const networkOk = useSelector((state) => state.networkOk);
  const userRole = useSelector((state) => state.userRole);

  const [ready, setReady] = useState(false);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE + "/area-of-expertises", {
      method: "get",
      
    })
      .then((response) => response.json())
      .then((result) => { 
        console.log('area-of-expertises',result)
        if (result)
        {
          dispatch({ type: "STORE_AOE", areaOfExpertises: result });
        }
      }
        )
      .catch((e) => {
        console.log(e);
      });
  
    return () => {
      
    }
  }, [])
  

  useEffect(() => {
    
    const expirationTime = localStorage.getItem("tokenExpirationDate");
    if (expirationTime && expirationTime < new Date().getTime()) {
      //
      dispatch({ type: "LOGOUT" });
    } else if (expirationTime && expirationTime > new Date().getTime()) {
      dispatch({ type: "CHECK_AUTH" });
      console.log("CHECK_AUTH");
      fetch(process.env.REACT_APP_API_BASE + "/whoAmI", {
        method: "get",
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }),
      })
        .then((response) => response.json())
        .then((result) => { 
          console.log('whoAmI',result)
          if (result)
          {
            dispatch({type:"SET_ROLE",userRole: result.role,requestId:result.requestId})
          }
        }
          )
        .catch((e) => {
          console.log(e);
        });
      setTimeout(() => {
        dispatch({ type: "LOGOUT" });
      }, expirationTime - new Date().getTime());
    }
    setReady(true);
    //Omran TODO:  check language to fix the refresh
    const lang = localStorage.getItem("lang") || "ar";
    dispatch({ type: "CHANGE_LANG", lang: lang, i18n: i18n });
  }, []);
  return (
    <>
      {!networkOk && (
        <CModal show={true} closeOnBackdrop={false}>
          <CModalHeader closeButton={false}>
            <CModalTitle>Network Error</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CRow>
              <CCol>
                <p>Qalat DB site has network errors, please contract admins</p>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter></CModalFooter>
        </CModal>
      )}
      <React.Suspense fallback={loading}>
        <Switch>
          {!isAuth && (
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
          )}
          <Route
            exact
            path="/forgot-password"
            name="Forgot Password Page"
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route
            exact
            path="/reset-password/:token"
            name="Forgot Password Page"
            render={(props) => <ResetPassword {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <Route
            exact
            path="/org-request"
            name="OrgRequest"
            render={(props) => <OrgRequest {...props} />}
          />
          <Route
            exact
            path="/cv-request"
            name="CVequest"
            render={(props) => <CVRequest {...props} />}
          />

          {isAuth &&
            userRole === "Admin" &&
            authenticatedRoutes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => <TheLayout {...props} />}
                  />
                )
              );
            })}
          {isAuth &&
            userRole === "Org" &&
            authenticatedRoutesOrg.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => <TheLayout {...props} />}
                  />
                )
              );
            })}
             {isAuth &&
            userRole === "Expert" &&
            authenticatedRoutesExpert.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => <TheLayout {...props} />}
                  />
                )
              );
            })}
          {isAuth && 
          (
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
            
          )
          
          }

          {!isAuth && ready && <Redirect from="/" to="/login"></Redirect>}
        </Switch>
      </React.Suspense>
    </>
  );
};

export default App;
