import * as OTPAuth from 'otpauth';
import i18n from './i18n';

export const validateEmail = (email) => {
  if (!email) return true;
  const re = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
  return re.test(String(email).toLowerCase());
};
export const validatePhoneNumber = (number) => {
  const re = /^(00|\+)[1-9]{1}([0-9][\s]*){9,16}$/;
  return re.test(String(number).toLowerCase());
};

export const validateHTTP = (url) => {
  console.log("validateHTTP url=", url);
  if (!url || url === undefined || url.trim() === "") return true;
  const re = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gi;
  //
  return re.test(String(url).toLowerCase());
};
export const formatDate = (date) => {
  // TODO change to local time of client
  if (!date || date === null) return " ";

  const lcoalDate = new Date(date);

  return (
    lcoalDate.getFullYear() +
    "-" +
    ("0" + (lcoalDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + lcoalDate.getDate()).slice(-2) +
    " " +
    ("0" + lcoalDate.getHours()).slice(-2) +
    ":" +
    ("0" + lcoalDate.getMinutes()).slice(-2) +
    ":" +
    ("0" + lcoalDate.getSeconds()).slice(-2)
  );
};

export const purposeOptionList = (t) =>
{
  return [
    {
      name: t("Establish contact with expert for media content production"), id: 1,    },
    { name:  t("Just curious"), id: 2 }, // : "فقط مهتم/ة", id: 2 },
    { name:  t("To conduct a research"), id: 3 },// : "من أجل إجراء بحث", id: 3 },
    { name: t("For networking") , id: 4 },//: "من أجل بناء العلاقات"
    { name:  t("Other"), id: 5 },// : "أخرى"
  ]
} ;
export const formatDateNoTime = (date) => {
  // TODO change to local time of client
  if (!date || date === null) return " ";

  const lcoalDate = new Date(date);

  return (
    lcoalDate.getFullYear() +
    "-" +
    ("0" + (lcoalDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + lcoalDate.getDate()).slice(-2)
  );
};

export const proficiencyName = (val, lang) => {
  const proficiency = [];
  proficiency[0] = lang("Elementary");
  proficiency[1] = lang("Limited Working");
  proficiency[2] = lang("Professional Working");
  proficiency[3] = lang("Full Professional");
  proficiency[4] = lang("Native");

  return proficiency[val];
  // TODO change to local time of client
};

export const getBadgeStatus = (status) => {
  switch (status) {
    case true:
      return "success";
    case false:
      return "danger";
    
    default:
      return "danger";
  }
};

export const getStatusStringStatus = (status) => {
  switch (status) {
    case true:
      return "Active";
    case false:
      return "Inactive";      
    default:
      return "Inactive";
  }
};

export const returnCVRequestStatus = (status) => {
  // status (Draft = 0, Pending = 1, Approved = 2, Rejected = 3)
  switch (status) {
    case 0:
      return "Draft";
    case 1:
      return "Pending";
    case 2:
      return "Approved";
    case 3:
      return "Rejected";
    default:
      break;
  }
  return;
};

export const getCVRequestBadge = (status) => {
  //  Pending = 1, Approved = 2, Rejected = 3)
  switch (status) {
    case 1:
      return "warning";
    case 2:
      return "success";
    case 3:
      return "danger";
    default:
      return "transperant";
  }
};
export const returnCVStatus = (status) => {
  //status (Draft = -1,Pending 0, Approved=1, Published=2, Archieved=3)
  switch (status) {
    case -1:
      return "Draft";
    case 0:
      return "Pending";
    case 1:
      return "Approved";
    case 2:
      return "Published";
    case 3:
      return "Rejected";
    default:
      break;
  }
  return;
};

export const getCVBadge = (status) => {
  //status (Draft = -1,Pending 0, Approved=1, Published=2, Archieved=3)
  switch (status) {
  
    case 1:
      return "warning";
    case 2:
      return "success";
    case 3:
      return "danger";
    default:
      break;
  }
  return;
};
export const formatDateForPicker = (date) => {
  // TODO change to local time of client
  console.log("formatDatePicker date", date);

  if (!date || date === null || date === undefined) return "";

  // if ( === 'date') return
  const lcoalDate = new Date(date);

  return (
    lcoalDate.getFullYear() +
    "-" +
    ("0" + (lcoalDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + lcoalDate.getDate()).slice(-2)
  );
};

export const componentLoader = (lazyComponent, attemptsLeft) => {
  return new Promise((resolve, reject) => {
    lazyComponent.then(resolve).catch((error) => {
      // let us retry after 1500 ms
      setTimeout(() => {
        if (attemptsLeft === 1) {
          reject(error);
          return;
        }
        componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
      }, 5000);
    });
  });
};

export const checkValue = (val) => {
  if (val) return val;
  else return "";
};
export const login = (dispatch,res,history) => {
  dispatch({
    type: "LOGIN",
    token: res.data.token,
    tokenExpiresIn: res.data.expiresIn,
    userRole: res.data.role,    
    requestId: res.data.requestId,    
  });
  // dispatch({type:"SET_ROLE",userRole: res.data.role})
  console.log( 'res.data.role', res.data.role);

  setTimeout(() => {
    dispatch({ type: "LOGOUT" });
    history.push("/login");
  }, res.data.expiresIn * 1000);

  if (res.data.role === 'Admin')
    history.push("/expert-cv");
  else
    history.push("/expert-cv-view")
};

export const getOTP = (secret) => {
  //To generate a secret key use the following
  //const code = OTPAuth.Secret.fromUTF8(Math.random()).base32
  let totp = new OTPAuth.TOTP({
    algorithm: 'SHA1',
    digits: 6,
    period: 30,
    secret: secret // or "OTPAuth.Secret.fromBase32('NB2W45DFOIZA')"
  });
  
  // Generate a token.
  return totp.generate();
};

export const filterCVs = (searchQuery, allCVList,i18n) => {
  //To generate a secret key use the following
  //const code = OTPAuth.Secret.fromUTF8(Math.random()).base32
  const query =searchQuery.trim().toLowerCase().split(" ");
  
  const filteredCVs = allCVList.filter((cv) => {
    let match = false;
    query.forEach((str) => {
      if (cv.personalInfo.fullName.toLowerCase().includes(str)) match = true;
    });

    query.forEach((str) => {
      if (
        cv.personalInfo.email &&
        cv.personalInfo.email.toLowerCase().includes(str)
      )
        match = true;
    });

    query.forEach((str) => {
      if (
        cv.personalInfo.country &&
        cv.personalInfo.country[i18n.language === 'ar' ? "nameAr":"nameEn"].toLowerCase().includes(str)
      )
        match = true;
    });

    query.forEach((str) => {
      if (cv.languages )
        cv.languages.forEach(item => {

          if (item.language[i18n.language === 'ar' ? "nameAr":"nameEn"].toLowerCase().includes(str))
           match = true;
      })          
    });

     query.forEach((str) => {
      if (cv.cvExpertises )
        cv.cvExpertises.forEach(item => {
          
          if (item.areaOfExpertise)
            if (item.areaOfExpertise[i18n.language === 'ar' ? "nameAr":"nameEn"].toLowerCase().includes(str))
              match = true;
      })          
    });

    query.forEach((str) => {
      if (cv.interests )
        cv.interests.forEach(item => {

          if (item.name.toLowerCase().includes(str))
           match = true;
      })
      
    });

    return match;
  });

  return filteredCVs;
};
