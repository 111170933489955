import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CToggler,
} from "@coreui/react";
import {cifJo, cifUs} from '@coreui/icons'
import CIcon from "@coreui/icons-react";

import { useMutation } from "react-query";

import axios from "../../../axios";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Verify2FA from "./Verify2FA";
import { login } from "src/utils";

const Login = (props) => {
  //const token = useSelector(state => state.token);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("translation");
  // console.log("t", i18n);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [enabled2FA, setEnabled2FA] = useState(false)
  //const lang = localStorage.getItem("lang") || "ar";

  const lang = useSelector(state => state.lang)

  const toggleLang = () => {
    dispatch({ type: "CHANGE_LANG", lang: lang === "ar" ? "en" : "ar" });
    i18n.changeLanguage(lang === "ar" ? "en" : "ar");
  };
  const loginPost = async (authData) => {
    setError(null);
    try {
      console.log("calling users/login");
      const res = await axios.post("users/login", authData);
      if (res.error) {
        console.log("res", res);

        setError(res.error.response.data.error.message);
      }
      else {

        console.log("res", res);
        if (res.data.enabled2FA)
        {
            setEnabled2FA(true);
        }
        else if (res.data.token)
        {
          login(dispatch,res,props.history);         
        }
       
      }
    } catch (error) {

      setError(error.message);
     
      console.log( error.message);
    }
    //return res;
  };

  const { mutate, isError, isLoading } = useMutation(loginPost);

  const emailHandler = (event) => {
    setEmail(event.target.value);
  };
  const passwordHandler = (event) => {
    setPassword(event.target.value);
  };

  const submitLoginHandler = () => {
    try {
      const authData = {
        email,
        password,
      };
      // Type error is gone
      mutate(authData);
    } catch (error) {
     console.log("Custom Error mutate", error);
    }
  };

  console.log('render login ')
  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow>
          <CCol md="12" className="text-center">
            <img
              src="avatars/logo2.png"
              alt="qalat"
              style={{
                width: "25%",
                marginBottom: "10px"
              }}
            ></img>
          </CCol>
        </CRow>
      {!enabled2FA && 
      <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>{t("Login")}</h1>
                    <p className="text-muted">{t("You can login if you are registered as expert or organization.")}</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="text"
                        placeholder={t("Email Address")}
                        autoComplete="username"
                        value={email}
                        onChange={emailHandler}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="password"
                        placeholder={t("Password")}
                        autoComplete="current-password"
                        value={password}
                        onChange={passwordHandler}
                      />
                    </CInputGroup>

                    {error && (
                      <CRow>
                        <CCol xs="12">
                          <p className="danger">
                           
                            {/* Omran TODO: add language */}
                            {isLoading && t("Logging in ...")}
                          </p>
                          <CAlert color="danger">
                          {error}
                          </CAlert>
                        </CCol>
                      </CRow>
                    )}

                    <CRow>
                      <CCol xs="6">
                        <CButton
                          color="primary"
                          className="px-4"
                          onClick={submitLoginHandler}
                        >
                          {t("Login")}
                        </CButton>
                      </CCol>
                      <CCol xs="6" className="text-center">
                        <CButton color="link" to="/forgot-password" className="px-0">
                          {t("Forgot password?")}
                        </CButton>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol xs="12">
                        {/* <Link to="/">
                          <CButton
                            color="link"
                            className="mt-3"
                            active
                            tabIndex={-1}
                          >
                            {t("Return to Qalat")}
                          </CButton>
                        </Link> */}
                      </CCol>
                    </CRow>
                    <CRow  >
                      <CCol  xs="12" className="text-center">
                        <CToggler
                          inHeader
                          className="ms-1"
                          onClick={toggleLang}
                        >
                          {
                            // lang === 'EN' ? <CIcon content={flagSet.cifGb} size="xl" /> :
                            // <CIcon content={flagSet.cifSa} size="xl" />
                            lang === "en" ? "العربية" : "English"
                          }
                        </CToggler>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard
                className="text-white bg-primary py-5 "
               
              >
                <CCardBody className="text-center">
                  <div>
                    <h2>{t("Welcome")}</h2>
                    <p>
                      {t(
                        "Welcome Qalat experts website, you can submit a request as an Organization if you don't have an account"
                      )}
                    </p>
                    <Link to="/org-request">
                      <CButton
                        color="primary"
                        className="mt-3"
                        active
                        tabIndex={-1}
                      >
                        {t("Register as Organization!")}
                      </CButton>
                    </Link>
                    <Link to="/cv-request">
                      <CButton
                        color="primary"
                        className="mt-3"
                        active
                        tabIndex={-1}
                      >
                        {t("Register as expert")}
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
     } 

      {enabled2FA && <> <Verify2FA email={email} password={password}></Verify2FA>
      </>}
     </CContainer>
    </div>
  );
};

export default withRouter(Login);
