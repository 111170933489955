import {
    CAlert,
    CButton,
  CCol,
  CContainer,
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSwitch,
  CTextarea,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { formatDateNoTime } from "../../../../../utils";

import axios from "../../../../../axios";
const ProjectsView = (props) => {
  const [projectList, setProjectList] = useState(props.projectList);
  const [deleteModal, setDeleteModal] = useState(false);
   const [deleteProject, setDeleteProject] = useState(false);
   const [error, setError] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    setProjectList(props.projectList);

     return () => {};
  }, [props.projectList]);
  const deleteProjectAction = async (id) =>
    {

      setError(null);
     
      try {
        console.log('sending to API');

        let res = null;

        res = await axios.delete(`/projects/${id}`);

        if (res.error)
            setError(res.error.response.data.error.message);

        if (res === undefined)
            throw new Error('No result returned');

        setDeleteModal(false);
        setDeleteProject(null);
        return res.data;

      } catch (err){
        setError('unexpected Error: ' + err);

       
      }
      finally {
          
        props.refreshCVs();
      }
    }
   
  const { mutate:mutateDelete} = useMutation(deleteProjectAction);
    
  const projectDeleteHandler = id =>
  {
        setDeleteModal(true)
        const project = projectList.find(item=> item.id === id)
        setDeleteProject(project);
  }
  const projectDelete = (event) =>
  {
        //setDeleteModal(true)
        event.preventDefault();
        try {

             mutateDelete(deleteProject.id)
        } catch (error) {
            console.log(error);
        }
  }
  return (
    <>
    { deleteProject && <CModal show={(deleteModal)} closeOnBackdrop={false} onClose={setDeleteModal}>
        <CModalHeader closeButton className="modal--header-color">
          <CModalTitle>{t("Projects and collaborations")} </CModalTitle>
        </CModalHeader>
        <CModalBody>
        <CRow className="cv-section-card">
          <CCol xs="12" md="12">
          <CFormGroup row>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t("Project Name")}<span className="text-danger"> *</span>
              </CLabel>
              <CInput
              disabled
                placeholder={t("Project Name")}
                value={deleteProject.name}
              />
              <CFormText></CFormText>
            </CCol>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t("Project URL")}<span className="text-danger"> </span>
              </CLabel>
              <CInput
              disabled
                placeholder={t("Project URL")}
                value={deleteProject.url}
              />
              <CFormText></CFormText>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t("Project Description")}<span className="text-danger"> </span>
              </CLabel>
              <CTextarea
              disabled
               placeholder={t("Project Description")}
                rows="6"
                value={deleteProject.description}
               
              />
              <CFormText></CFormText>
            </CCol>

            <CCol xs="12" md="6">
              <CRow>
                <CCol xs="12" md="6">
                  <CLabel htmlFor="date-input">
                    {t("Start Date")}<span> </span>
                  </CLabel>
                  <CInput
                    type="text"
                    disabled
                    placeholder="date"
                    value={ formatDateNoTime(deleteProject.startDate)}
                    
                  />
                  <CFormText>
                    <span></span>
                  </CFormText>
                </CCol>
                <CCol xs="12" md="6">
                {deleteProject.endDate && <> <CLabel htmlFor="date-input">
                    {t("End Date")} <span> </span>
                  </CLabel>
                  <CInput
                    type="text"
                    disabled
                    placeholder="date"
                    value={formatDateNoTime(deleteProject.endDate)}
                  />
                  <CFormText>
                    <span></span>
                  </CFormText></>} 
                </CCol>
              </CRow>
              <CRow>
                <CCol xs="12" md="6"></CCol>
                <CCol xs="12" md="6">
                  <CLabel htmlFor="date-input">
                    {t("Current project?")} <span> </span>
                  </CLabel>

                   <div>
                    <CSwitch disabled color="primary" checked={deleteProject.endDate ? false : true} />
                  </div>
                  <CFormText></CFormText>
                </CCol>
              </CRow>
             
            </CCol>
          </CFormGroup>
    
          </CCol>
        </CRow>      
     
        </CModalBody>
        <CModalFooter>
          {error && <CAlert color="danger">{error}</CAlert>}
          <CButton
            color="danger"
            onClick={projectDelete}
          >
           {t("Delete")}
          </CButton>{" "}
          <CButton color="secondary" onClick={() => setDeleteModal(false)}>
            {t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>
}
      {projectList.map((project, index) => (
      
        <CRow key={"project"+index} className="cv-section-card">
          <CCol xs="12" md="12">
          <CFormGroup row>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
                {t("Project Name")}<span className="text-danger"> *</span>
              </CLabel>
              <CInput
              disabled
                placeholder={t("Project Name")}
                value={project.name}
              />
              <CFormText></CFormText>
            </CCol>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
              {t("Project URL")}<span className="text-danger"> </span>
              </CLabel>
              <CInput
              disabled
                placeholder={t("Project URL")}
                value={project.url}
              />
              <CFormText></CFormText>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs="12" md="6">
              <CLabel htmlFor="text-input">
              {t("Project Description")}<span className="text-danger"> </span>
              </CLabel>
              <CTextarea
              disabled
               placeholder={t("Project Description")}
                rows="6"
                value={project.description}
               
              />
              <CFormText></CFormText>
            </CCol>

            <CCol xs="12" md="6">
              <CRow>
                <CCol xs="12" md="6">
                  <CLabel htmlFor="date-input">
                    {t("Start Date")}<span> </span>
                  </CLabel>
                  <CInput
                    type="text"
                    disabled
                    placeholder="date"
                    value={formatDateNoTime(project.startDate)}
                    
                  />
                  <CFormText>
                    <span></span>
                  </CFormText>
                </CCol>
                <CCol xs="12" md="6">
                {project.endDate && <> <CLabel htmlFor="date-input">
                    {t("End Date")} <span> </span>
                  </CLabel>
                  <CInput
                    type="text"
                    disabled
                    placeholder="date"
                    value={formatDateNoTime(project.endDate)}
                  />
                  <CFormText>
                    <span></span>
                  </CFormText></>} 
                </CCol>
              </CRow>
              <CRow>
                <CCol xs="12" md="6"></CCol>
                <CCol xs="12" md="6">
                  <CLabel htmlFor="date-input">
                    {t("Current project?")} <span> </span>
                  </CLabel>

                   <div>
                    <CSwitch disabled color="primary" checked={project.endDate ? false : true} />
                  </div>
                  <CFormText></CFormText>
                </CCol>
              </CRow>
              <CRow>
                <CCol xs="12" md="6"></CCol>
               { !props.viewOnly &&  <CCol xs="12" md="6" style={{ textAlign:"end" }}>
                <CButton color="success" onClick={()=> props.projectEditHandler(project.id)}>
                    {t("Edit")}
                  </CButton>
                  {" "}
                <CButton color="danger" onClick={()=> projectDeleteHandler(project.id)}>
                    {t("Delete")}
                  </CButton>
                </CCol>}
              </CRow>
            </CCol>
          </CFormGroup>
    
          </CCol>
        </CRow>      
      ))}
    </>
  );
};

export default ProjectsView;
