import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import axios from '../../../axios'
const ProficiencyList = props =>
{

  const {t} = useTranslation();
    

    return <>
     <option value="0">{t("Elementary")}</option>
     <option value="1">{t("Limited Working")}</option>
     <option value="2">{t("Professional Working")}</option>
     <option value="3">{t("Full Professional")}</option>
     <option value="4">{t("Native")}</option>
    </>;
}

export default ProficiencyList;