import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CFormGroup,
  CInputCheckbox,
  CLabel,
  CLink,
  CRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import axios from "../../../../axios";
import { useQuery } from "react-query";
import About from "./About/About";
import Projects from "./Projects/Projects";
import { useTranslation } from "react-i18next";
import Experiences from "./Experiences/Experiences";
import CVLanguages from "./CVLanguages/CVLanguages";
import Interests from "./Interests/Interests";
import PublishedWorks from "./PublishedWorks/PublishedWorks";
import Memberships from "./Memberships/Memberships";
import { useSelector } from "react-redux";
import AreaOdExpertise from "./AreaOdExpertise/AreaOdExpertise";

const CV = (props) => {
  // console.log("CV rendered, props", props);
  const { t, i18n } = useTranslation();

  const [error, setError] = useState(null);
  const [currentCV, setCurrenctCV] = useState(null);
  const [enCV, setEnCV] = useState(null);
  const [arCV, setArCV] = useState(null);
  const [currentCVId, setCurrentCVId] = useState(
    i18n.language === "ar" ? props.arCVId : props.enCVId
  );
  const areaOfExpertises = useSelector((state) => state.areaOfExpertises );
  
  const fetchCVs = async () => {
    try {
      //check api route
      const res = await axios.get("/2cvs/" + currentCVId);

      if (res.error) {
        setError(res.error.response.data.error.message);
        return;
      }

      if (!res) {
        setError("No response");
        return;
      }
      setCurrenctCV(
        res.data && i18n.language === "ar" ? res.data.arCV : res.data.enCV
      );
      props.setCV(
        res.data && i18n.language === "ar" ? res.data.arCV : res.data.enCV,
        res.data && i18n.language === "ar" ? res.data.enCV : res.data.arCV
      );
      setEnCV(res.data.enCV);
      setArCV(res.data.arCV);
      console.log("2cvs", res.data);
        console.log('areaOfExpertises',areaOfExpertises)
      // TODO: handle CVs error
      return res.data;
    } catch (err) {
      console.log("Unexpected Error", error);
    }
  };
  const { data, refetch: refreshCVs, status } = useQuery("getCVs", fetchCVs);

  // const [setCV] = useState(props.setCV ? props.setCV : null)

  useEffect(() => {
    setCurrentCVId(i18n.language === "ar" ? props.arCVId : props.enCVId);
    refreshCVs();
    return () => {};
  }, [i18n.language, refreshCVs, props]);

  // if (currentCV)
  // {
  //   if (props.setCV)
  //     props.setCV(currentCV);
  // }
  return (
    <CRow className="text-start">
      <CCol xl="12">
        <CCard>
          <CCardBody>
            {!currentCV && <p>Loading ....</p>}
            {currentCV && enCV && arCV && (
              <PersonalInfo
                currentPersonalInfo={enCV && enCV.personalInfo}
                otherPersonalInfo={arCV && arCV.personalInfo}
                refreshCVs={refreshCVs}
                viewOnly={props.viewOnly}
                showContact={props.showContact}
              />
            )}
            {currentCV && (
              <AreaOdExpertise
                cvExpertises={currentCV && currentCV.cvExpertises}
                cvId={currentCV && currentCV.id}
                refreshCVs={refreshCVs}
                viewOnly={props.viewOnly}
               
              />
            )}
            {/* {currentCV && (
              <About
                currentAbout={currentCV && currentCV.about}
                refreshCVs={refreshCVs}
                viewOnly={props.viewOnly}
              />
            )} */}
            {currentCV && (
              <Experiences
                CVId={currentCVId}
                experiences={currentCV && currentCV.experiences}
                refreshCVs={refreshCVs}
                viewOnly={props.viewOnly}
               
              />
            )}
            {currentCV && (
              <Interests
                CVId={currentCVId}
                interests={currentCV && currentCV.interests}
                refreshCVs={refreshCVs}
                viewOnly={props.viewOnly}
              />
            )}
            {currentCV && (
              <CVLanguages
                CVId={currentCVId}
                languages={currentCV && currentCV.languages}
                refreshCVs={refreshCVs}
                viewOnly={props.viewOnly}
              />
            )}
            {currentCV && (
              <Projects
                CVId={currentCVId}
                projects={currentCV && currentCV.projects}
                refreshCVs={refreshCVs}
                viewOnly={props.viewOnly}
              />
            )}

            {currentCV && (
              <PublishedWorks
                CVId={currentCVId}
                publishedWorks={currentCV && currentCV.publishedWorks}
                refreshCVs={refreshCVs}
                viewOnly={props.viewOnly}
              />
            )}
            {currentCV && (
              <Memberships
                CVId={currentCVId}
                memberships={currentCV && currentCV.memberships}
                refreshCVs={refreshCVs}
                viewOnly={props.viewOnly}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default CV;
